import { InjectionKey, defineComponent } from 'vue';
import { RawLocation, Route, RouteConfigSingleView } from 'vue-router/types/router';

interface HeaderMenuRouteItem {
  label: string;
  icon: string;
  route?: RawLocation;
  separated?: boolean;
  permissions?: string[] | string[][];
}

interface HeaderMenuSeparator {
  isDivider: boolean;
}

type HeaderMenuItem = HeaderMenuRouteItem | HeaderMenuSeparator;

interface RouteMetaHeaderBase {
  label: string | ((a: any) => string);
  menu?: (route: Route, data?: any) => HeaderMenuItem[];
}
interface RouteMetaHeaderParent extends RouteMetaHeaderBase {
  parentRoute?: RawLocation;
}
interface RouteMetaHeader extends RouteMetaHeaderBase {
  route?: RawLocation;
}

export type UvRouteHeader = RouteMetaHeader | RouteMetaHeaderParent;

type RouteMetaBreadcrumbsBase =
  | {
      route?: RawLocation | false;
    }
  | {
      parentRoute?: RawLocation | false;
    };

export type RouteMetaBreadcrumbsLabel = RouteMetaBreadcrumbsBase & {
  label: string | ((a: { [k: string]: any }) => string);
  icon?: string;
};

export type RouteMetaBreadcrumbsIcon = RouteMetaBreadcrumbsBase & {
  icon: string;
};

export type UvRouteBreadcrumbs = RouteMetaBreadcrumbsIcon | RouteMetaBreadcrumbsLabel;

export interface UvRoute extends RouteConfigSingleView {
  meta?: {
    auth?: boolean;
    breadcrumb?: UvRouteBreadcrumbs;
    header?: UvRouteHeader;
    permissions?: string[] | string[][];
    wide?: boolean | ((a: { [k: string]: any }) => boolean);
    [k: string]: any;
  };
  children?: UvRoute[];
}

type InjectionProps = string | [string | InjectionKey<any>, string];

export const createInjectionComponent = (props: Array<InjectionProps> = [], merge = {}) => {
  return defineComponent({
    provide() {
      return Object.fromEntries(
        props.map((prop) => (Array.isArray(prop) ? [prop[0], this[prop[1]]] : [prop, this[prop]])),
      );
    },
    inheritAttrs: false,
    props: props.map((prop) => (Array.isArray(prop) ? prop[1] : prop)),
    render(h) {
      return h('router-view', {
        props: this.$attrs,
        on: this.$listeners,
      });
    },
    ...merge,
  });
};
