import { createInjectionComponent, UvRoute } from '@/lib/router';
import imp from '@/lib/imp';
import categories from '@/pages/incidents/category/routes';
import citizenReports from '@/pages/incidents/citizen-report/routes';
import incidents from '@/pages/incidents/incidents/routes';
import information from '@/pages/incidents/information/routes';

const routes: UvRoute = {
  path: 'incidents',
  props: true,
  component: createInjectionComponent(),
  meta: {
    header: {
      label: 'Incidente',
      route: 'incidents',
    },
    breadcrumb: {
      label: 'Incidente',
      route: 'incidents',
    },
  },
  children: [
    {
      path: '',
      component: imp(() => import('@/pages/incidents/Actions.vue')),
      name: 'incidents',
      props: true,
    },
    categories,
    citizenReports,
    incidents,
    information,
  ],
};

export default routes;
