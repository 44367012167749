import { InjectionKey } from 'vue';
import { UvField } from '@/modules/fields';
import { IItemFile, IItemPhoto } from '@/components/definitions';
import { Duration } from 'iso8601-duration';

interface UvProductField {
  fieldId: ObjectId;
  label: string;
  value: UvField['defaultValue'];
  formattedValue: string;
}

export interface UvProduct {
  id: ObjectId;
  moduleId: ObjectId;
  categoryId: ObjectId;
  institutionId: ObjectId;
  supplierId: ObjectId;
  name: string;
  description: string;
  unitValue: number;
  qty: number;
  um: string;
  createdAt: Date;
  receptionDate: Date;
  warrantyStartDateField: 'receptionDate' | 'installationDate' | 'fabricationDate';
  warrantyDuration: string;
  installationDate: Date;
  fabricationDate: Date;
  manager: string;
  warehouse: string;
  fields: Array<UvProductField>;
}

export interface Product extends UvProduct {
  _id: ObjectId;
}
export interface UvProductService {
  id: ObjectId;
  name: string;
}

export interface UvProductSupplier {
  name: string;
}

export interface UvProductInventoryDetails {
  items: Array<{
    areaId: ObjectId | null;
  }>;
}

export interface UvProductFlowChecklistItem {
  id: ObjectId;
  itemId: ObjectId;
  name: string;
  description: string;
  finished?: {
    success: boolean;
    date: Date;
  };
}

export interface UvProductFlow {
  id: ObjectId;
  flowId: ObjectId;
  name: string;
  type: string;
  checklist: Array<UvProductFlowChecklistItem>;
  finished: {
    success: boolean;
  };
}

export interface UvProductMaintenance {
  id: ObjectId;
  flowId: ObjectId;
  flowFinished: boolean;
  startDate: Date;
  stopDate?: Date;
  duration?: Duration;
  name: string;
  archived?: {
    date: Date;
    reason: string;
  };
}

export type UvProductMaintenanceSummary = UvProductMaintenance & {
  flow: UvProductFlow;
};

export interface UvService {
  id: ObjectId;
  institutionId: ObjectId;
  supplierId: ObjectId;
  moduleId: ObjectId;
  name: string;
  description: string;
  value: number;
  contract: {
    number: string;
    date: Date;
  };
  startDate: Date;
  receptionDate: Date;
  warranty: number;
  warrantyExpirationDate: Date;
  createdAt: Date;
}

export interface UvSupplier {
  _id: ObjectId;
  name: string;
  cui: string;
}

export interface UvDetailedProduct extends UvProduct {
  inventory: UvProductInventoryDetails;
  services: Array<UvProductService>;
  supplier: UvProductSupplier;
  location: any;
  flows: UvProductFlow[];
  maintenances: {
    active: UvProductMaintenanceSummary[];
    list: UvProductMaintenanceSummary[];
  };
  files: IItemFile[];
  photos: IItemPhoto[];
  fieldGroups: Array<{
    _id: ObjectId;
    label: string;
    fields: Array<UvProductField>;
  }>;
}

export const UvProductKey: InjectionKey<UvProduct> = Symbol('UvProduct');

export type UvMaintenanceDateField = 'custom' | 'receptionDate' | 'installationDate' | 'fabricationDate';

export interface UvMaintenance {
  id: ObjectId;
  moduleId: ObjectId;
  institutionId: ObjectId;
  flowId: ObjectId;
  name: string;
  description: string;
  startDateField: UvMaintenanceDateField;
  startDateOffset: string;
  duration: string;
  endDateField: UvMaintenanceDateField;
  endDateOffset: string;
  deletedAt: Date;
  archived?: {
    date: Date;
    reason: string;
  };
}

export interface UvListMaintenance {
  id: ObjectId;
  moduleId: ObjectId;
  flowId: ObjectId;
  name: string;
  description: string;
  archived?: {
    reason: string;
    date: Date;
  };
}

export interface UvFlowChecklistItem {
  itemId: ObjectId;
  name: string;
  description: string;
}

export enum UvFlowType {
  Maintenance = 'product-maintenance',
}

export const flowTypeLabels: Record<UvFlowType, string> = {
  [UvFlowType.Maintenance]: 'Mentenanta',
};

export interface UvFlow {
  id: ObjectId;
  moduleId: ObjectId;
  institutionId: ObjectId;
  type: UvFlowType;
  name: string;
  description: string;
  checklist: Array<UvFlowChecklistItem>;
  draft?: true;
}

export enum UvNotificationReport {
  ExpiringProductMaintenances = 'ExpiringProductMaintenances',
  ProgressingProductMaintenances = 'ProgressingProductMaintenances',
  PendingProductMaintenances = 'PendingProductMaintenances',
}

export enum UvNotificationFrequency {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

interface UvNotificationFrequencyConfig {
  period: UvNotificationFrequency;
  details?: Record<string, unknown>;
}

interface UvNotificationDailyFrequencyConfig extends UvNotificationFrequencyConfig {
  period: UvNotificationFrequency.Daily;
  details: {
    includingWeekend: boolean;
  };
}

interface UvNotificationMonthlyFrequencyConfig extends UvNotificationFrequencyConfig {
  period: UvNotificationFrequency.Weekly;
  details: {
    day: number;
  };
}

interface UvNotificationWeeklyFrequencyConfig extends UvNotificationFrequencyConfig {
  period: UvNotificationFrequency.Monthly;
  details: {
    date: number;
  };
}

export interface UvNotificationConfig {
  _id: ObjectId;
  userId: ObjectId;
  report: UvNotificationReport;
  frequency:
    | UvNotificationDailyFrequencyConfig
    | UvNotificationWeeklyFrequencyConfig
    | UvNotificationMonthlyFrequencyConfig;
  createdAt: Date;
}

export interface UvExpiringProductMaintenanceNotificationConfig extends UvNotificationConfig {
  report: UvNotificationReport.ExpiringProductMaintenances;
  maintenanceId: ObjectId;
  maintenanceLabel: string;
  interval: string;
}

export interface UvProgressingProductMaintenanceNotificationConfig extends UvNotificationConfig {
  report: UvNotificationReport.ProgressingProductMaintenances;
  maintenanceId: ObjectId;
  maintenanceLabel: string;
  finishedChecklistItemId?: ObjectId;
  finishedChecklistItemLabel?: string;
  remainingChecklistItemId?: ObjectId;
  remainingChecklistItemLabel?: string;
}

export interface UvPendingProductMaintenanceNotificationConfig extends UvNotificationConfig {
  report: UvNotificationReport.PendingProductMaintenances;
  maintenanceId?: ObjectId;
  maintenanceLabel?: string;
}

export type UvAllNotificationConfig =
  | UvExpiringProductMaintenanceNotificationConfig
  | UvPendingProductMaintenanceNotificationConfig
  | UvProgressingProductMaintenanceNotificationConfig;

export const notificationFrequecyPeriodLabels: Record<UvNotificationFrequency, string> = {
  [UvNotificationFrequency.Daily]: 'Zilnic',
  [UvNotificationFrequency.Weekly]: 'Saptamanal',
  [UvNotificationFrequency.Monthly]: 'Lunar',
};

export const notificationConfigLabels: Record<UvNotificationReport, string> = {
  [UvNotificationReport.ExpiringProductMaintenances]: 'Mentenante care expira',
  [UvNotificationReport.PendingProductMaintenances]: 'Mentenante cu flux complet dar neincepute',
  [UvNotificationReport.ProgressingProductMaintenances]: 'Mentenante in diferite etape',
};
