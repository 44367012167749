import imp from '@/lib/imp';
import { UvRoute, UvRouteHeader } from '@/lib/router';

const header: UvRouteHeader = {
  label: 'Fluxuri',
  parentRoute: 'assets.products.show',
  menu: () => [
    {
      label: 'Adauga flux',
      icon: 'add',
      route: {
        name: 'assets.products.flows.create',
      },
    },
  ],
};

const route: UvRoute = {
  path: '',
  component: imp(
    () =>
      import(
        '@/pages/assets/modules/products/view/flows/index/Main.vue' /* webpackChunkName: 'assets.products.flows' */
      ),
  ),
  children: [
    {
      path: '',
      meta: {
        header,
      },
      props: true,
      name: 'assets.products.flows.index',
      component: imp(
        () =>
          import(
            '@/pages/assets/modules/products/view/flows/index/List.vue' /* webpackChunkName: 'assets.products.flows' */
          ),
      ),
      children: [
        {
          props: true,
          path: 'create',
          name: 'assets.products.flows.create',
          component: imp(
            () =>
              import(
                '@/pages/assets/modules/products/view/flows/index/Create.vue' /* webpackChunkName: 'assets.products.flows.create' */
              ),
          ),
        },
      ],
    },
  ],
};

export default route;
