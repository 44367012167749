import config from '@/config';
import { isString } from '@/lib/tools';
import { Module } from 'vuex';
import { RootState } from '.';

const props = ['title', 'color'] as const;

const fns: ((msg: RawLogMessage) => RawLogMessage | LogMessage)[] = [
  (e) =>
    e instanceof SyntaxError
      ? {
          color: 'error',
          title: 'Ceva nu functioneaza...',
          timeout: 600000,
          body: e.message,
        }
      : e,
  (e) =>
    e instanceof Error
      ? {
          color: 'error',
          title: e.message,
          timeout: 6000,
        }
      : e,
  (title) =>
    isString(title)
      ? {
          color: 'info',
          title,
        }
      : title,
];

export interface MessagesState {
  list: Array<LogMessage>;
}

const meh = (msg: RawLogMessage): msg is LogMessage => props.every((prop) => msg.hasOwnProperty(prop));

export const module: Module<MessagesState, RootState> = {
  namespaced: true,
  state: {
    list: [],
  },
  getters: {
    count: (state) => state.list.length,
    head: (state) => state.list[0],
  },
  mutations: {
    push: (state, message: LogMessage) => {
      state.list.push(message);
    },
    shift: (state) => {
      state.list.shift();
    },
  },
  actions: {
    shift: ({ commit }) => {
      commit('shift');
    },
    push: ({ commit }, msg: RawLogMessage) => {
      if (config.debug) {
        console.log(msg);
      }

      const tmpMsg: RawLogMessage = fns.reduce((prev, fn) => fn(prev), msg);

      if (meh(tmpMsg)) {
        commit('push', {
          ...tmpMsg,
          timestamp: new Date().getTime(),
        });
      } else {
        commit('push', {
          color: 'error',
          title: `Message must contain props ${props.join(', ')}.`,
          body: JSON.stringify(msg),
        });
      }

      return Promise.resolve();
    },
  },
};
