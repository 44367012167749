import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import confirm from '@/pages/inventory/active/scan/confirm/routes';

const route: UvRoute = {
  path: 'scan',
  component: imp(
    () => import('@/pages/inventory/active/scan/Main.vue' /* webpackChunkName: 'inventory.active.scan' */),
  ),
  meta: {
    header: {
      label: 'Scanare',
      route: 'inventory.active',
    },
    breadcrumb: {
      label: 'Scanare',
      route: 'inventory.active.scan.select-node',
    },
  },
  children: [
    {
      path: '',
      name: 'inventory.active.scan.select-node',
      meta: {
        permissions: ['update.scriptic-items'],
        header: {
          route: 'inventory.active.scan',
          label: 'Scanare',
        },
      },
      component: imp(
        () => import('@/pages/inventory/active/scan/SelectNode.vue' /* webpackChunkName: 'inventory.active.scan' */),
      ),
    },
    {
      path: ':nodeId',
      props: true,
      component: imp(
        () => import('@/pages/inventory/active/scan/Node.vue' /* webpackChunkName: 'inventory.active.scan' */),
      ),
      meta: {
        permissions: ['update.scriptic-items'],
        header: {
          label: ({ node }) => (node ? `Scaneaza in ${node.label}` : 'Scaneaza'),
          parentRoute: 'inventory.active.scan.select-node',
          route: 'inventory.active.scan',
        },
      },
      children: [
        {
          path: '',
          name: 'inventory.active.scan',
          component: imp(
            () => import('@/pages/inventory/active/scan/ScanCode.vue' /* webpackChunkName: 'inventory.active.scan' */),
          ),
          children: [
            {
              path: 'create',
              name: 'inventory.active.scan.create',
              props: true,
              component: imp(
                () =>
                  import(
                    '@/pages/inventory/active/scriptic/actions/CreateItem.vue' /* webpackChunkName: 'inventory.active.scan' */
                  ),
              ),
            },
          ],
        },
        confirm,
      ],
    },
  ],
};

export default route;
