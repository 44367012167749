import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: 'registry',
  name: 'registry.index',
  meta: {
    permissions: ['registry.crud.read'],
    breadcrumb: { label: 'Registratura' },
    wide: true,
  },
  component: imp(() => import('@/pages/registry/Main.vue' /* webpackChunkName: 'registry' */)),
};

export default route;
