<template>
  <v-tooltip :[tooltipPosition]="true">
    <template #activator="{ on }">
      <v-btn
        v-on="{ ...on, ...$listeners }"
        v-bind="$attrs"
        :class="buttonClass"
      >
        <slot />
      </v-btn>
    </template>
    <template #default>{{ tooltipText }}</template>
  </v-tooltip>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'uv-tooltip-btn',
  props: {
    tooltipPosition: {
      type: String,
      default: 'left',
    },
    tooltipText: {
      type: String,
      required: true,
    },
    buttonClass: {
      type: String,
      default: null,
    },
  },
});
</script>
