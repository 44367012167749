import { UvRouteHeader } from '@/lib/router';

const header: UvRouteHeader = {
  route: 'institution.users.index',
  label: ({ user }) => (user ? `Vizualizare utilizator "${user}"` : 'Vizualizare utilizator'),
  menu: (route) => [
    {
      route: {
        name: 'institution.users.edit.email',
        params: route.params,
      },
      icon: 'email',
      label: 'Modifica e-mail',
    },
    {
      route: {
        name: 'institution.users.edit.password',
        params: route.params,
      },
      icon: 'lock',
      label: 'Modifica parola',
    },
    {
      route: {
        name: 'institution.users.edit.name',
        params: route.params,
      },
      icon: 'edit',
      label: 'Modifica nume',
    },
  ],
};

export default header;
