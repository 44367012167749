import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: '',
  props: true,
  name: 'assets.modules.show',
  component: imp(() => import('@/pages/assets/modules/view/Show.vue' /* webpackChunkName: 'assets.main' */)),
};

export default route;
