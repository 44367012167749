import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: 'dynamic-fields',
  props: true,
  name: 'assets.products.import.dynamic-fields',
  component: imp(
    () =>
      import(
        '@/pages/assets/modules/products/import/dynamic-fields/Main.vue' /* webpackChunkName: 'assets.products.import.dynamic-fields' */
      ),
  ),
  meta: {
    header: {
      label: 'Importa campuri dinamice de produs din fisier',
      route: 'assets.products.import.dynamic-fields',
      menu: (route) => [
        {
          label: 'Importa',
          icon: 'arrow_upward',
          separated: true,
          route: {
            name: 'assets.products.import.dynamic-fields.upload',
            params: route.params,
          },
        },
      ],
    },
    breadcrumb: {
      label: 'Importa campuri dinamice de produs din fisier',
      route: 'assets.products.import.dynamic-fields',
    },
  },
  children: [
    {
      props: true,
      path: 'upload',
      name: 'assets.products.import.dynamic-fields.upload',
      component: imp(
        () =>
          import(
            '@/pages/assets/modules/products/import/dynamic-fields/Upload.vue' /* webpackChunkName: 'inventory.active.import.initial' */
          ),
      ),
    },
  ],
};

export default route;
