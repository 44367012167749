import api from '@/api';
import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import { InventoryImportSource } from '@/pages/inventory/Inventory';
import view from '@/pages/inventory/active/import/common/update/view/routes';
import mf from '@/pages/inventory/active/import/reception/lists/mf/routes';
import oi from '@/pages/inventory/active/import/reception/lists/oi/routes';

const baseRouteName = `inventory.active.import.${InventoryImportSource.RECEPTION}`;

const route: UvRoute = {
  path: `import/${InventoryImportSource.RECEPTION}`,
  props: (route) => ({
    ...route.params,
    baseRouteName,
  }),
  component: imp(
    () =>
      import(
        '@/pages/inventory/active/import/reception/Main.vue' /* webpackChunkName: 'inventory.active.import.reception' */
      ),
  ),
  meta: {
    header: {
      label: 'Receptii ulterioare',
      route: baseRouteName,
      menu: (route) => [
        {
          label: 'Descarca etichete',
          icon: 'cloud_download',
          route: {
            name: 'inventory.active.import.reception.codes',
            params: route.params,
          },
        },
      ],
    },
    breadcrumb: {
      label: 'Receptii ulterioare',
      route: baseRouteName,
    },
  },
  children: [
    {
      path: '',
      props: (route) => ({
        ...route.params,
        baseRouteName,
        source: InventoryImportSource.RECEPTION,
        loadApi: api.inventory.import.confirmed,
        deleteApi: api.inventory.import.items.delete,
        spaced: true,
      }),
      name: baseRouteName,
      component: imp(
        () =>
          import(
            '@/pages/inventory/active/import/common/update/ConfirmedList.vue' /* webpackChunkName: 'inventory.active.import.reception' */
          ),
      ),
      children: [
        {
          path: 'codes',
          name: `${baseRouteName}.codes`,
          props: (route) => ({
            ...route.params,
            source: InventoryImportSource.RECEPTION,
            codesApi: api.inventory.import.codes,
            baseRouteName,
          }),
          component: imp(
            () =>
              import(
                '@/pages/inventory/active/import/reception/Codes.vue' /* webpackChunkName: 'inventory.active.import.reception' */
              ),
          ),
        },
        view({
          baseRouteName,
          source: InventoryImportSource.RECEPTION,
          loadApi: api.inventory.import.items.show,
          createApi: api.inventory.import.items.create,
          editApi: api.inventory.import.items.edit,
          quantitiesApi: api.inventory.import.items.quantities,
        }),
      ],
    },
    oi,
    mf,
  ],
};

export default route;
