export const userFormatter = {
  fullName: (user: any) =>
    user.fullName ||
    [user.lastName, user.firstName]
      .map((s) => s.trim())
      .filter((s) => s.length > 0)
      .join(' '),
  initials: (user: any) =>
    (user.fullName || userFormatter.fullName(user))
      .split(' ')
      .map((s: any) => s[0])
      .join(''),
};

export const replace = (str: any, ...replacers: any) =>
  replacers.length ? replacers.reduce((acc: any, curr: any, i: any) => acc.replace(`{${i}}`, curr), str) : str;
