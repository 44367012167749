import inventory from '@/pages/assets/modules/products/import/inventory/routes';
import file from '@/pages/assets/modules/products/import/file/routes';
import dynamicFields from '@/pages/assets/modules/products/import/dynamic-fields/routes';
import { createInjectionComponent, UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: 'import',
  props: true,
  component: createInjectionComponent(),
  children: [inventory, file, dynamicFields],
};

export default route;
