import imp from '@/lib/imp';
import { UvRoute, createInjectionComponent } from '@/lib/router';
import view from '@/pages/inventory/active/scriptic/view/routes';

const route: UvRoute = {
  path: 'scriptic',
  component: createInjectionComponent(),
  children: [
    {
      path: 'create',
      name: 'inventory.scriptic.create',
      component: imp(
        () =>
          import('@/pages/inventory/active/scriptic/actions/CreateItem.vue' /* webpackChunkName: 'inventory.active' */),
      ),
      props: (route) => ({
        ...route.params,
        parentRoute: {
          name: 'inventory.active',
        },
      }),
      meta: {
        permissions: ['create.scriptic-items'],
      },
    },
    {
      props: true,
      path: 'print',
      name: 'inventory.active.print',
      meta: {
        permissions: ['read.scriptic-items'],
      },
      component: imp(
        () => import('@/pages/inventory/active/scriptic/actions/Print.vue' /* webpackChunkName: 'inventory.active' */),
      ),
    },
    view,
  ],
};

export default route;
