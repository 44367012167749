import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const routes: UvRoute[] = [
  {
    path: ':productId/location/preview',
    name: 'assets.products.location.preview',
    meta: {
      permissions: ['read.products'],
    },
    props: true,
    component: imp(
      () =>
        import(
          '@/pages/assets/modules/products/actions/ShowLocation.vue' /* webpackChunkName: 'assets.products.main' */
        ),
    ),
  },
];

export default routes;
