import { UvRoute, UvRouteHeader } from '@/lib/router';
import { CreateElement } from 'vue';
import index from '@/pages/admin/field-groups/index/routes';
import view from '@/pages/admin/field-groups/view/routes';

const header: UvRouteHeader = {
  label: 'Grupuri de campuri',
  route: 'admin.field-groups.index',
  menu: (route) => [
    {
      label: 'Adauga grup de campuri',
      icon: 'add',
      separated: true,
      route: {
        name: 'admin.field-groups.create',
        params: route.params,
      },
      permissions: ['manage.field-groups'],
    },
  ],
};

const routes: UvRoute = {
  path: 'field-groups',
  props: true,
  component: {
    render: (h: CreateElement) => h('router-view'),
  },
  meta: {
    permissions: ['manage.field-groups'],
    header,
    breadcrumb: {
      label: 'Grupuri de campuri',
      route: 'admin.field-groups.index',
    },
  },
  children: [index, view],
};

export default routes;
