import api from '@/api';
import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import { InventoryImportSource } from '@/pages/inventory/Inventory';
import view from '@/pages/inventory/active/import/common/update/view/routes';
import mf from '@/pages/inventory/active/import/quash/lists/mf/routes';
import oi from '@/pages/inventory/active/import/quash/lists/oi/routes';

const baseRouteName = `inventory.active.import.${InventoryImportSource.QUASH}`;

const route: UvRoute = {
  path: `import/${InventoryImportSource.QUASH}`,
  props: true,
  component: imp(
    () =>
      import('@/pages/inventory/active/import/quash/Main.vue' /* webpackChunkName: 'inventory.active.import.quash' */),
  ),
  meta: {
    header: {
      label: 'Iesiri',
      route: baseRouteName,
      menu: (route, { downloadQrCodes }: { downloadQrCodes: () => Promise<string> }) => [
        {
          label: 'Descarca etichete',
          icon: 'cloud_download',
          on: {
            ...(downloadQrCodes ? { click: downloadQrCodes } : {}),
          },
        },
      ],
    },
    breadcrumb: {
      label: 'Iesiri',
      route: baseRouteName,
    },
  },
  children: [
    {
      path: '',
      props: (route) => ({
        ...route.params,
        baseRouteName,
        source: InventoryImportSource.QUASH,
        loadApi: api.inventory.import.confirmed,
        codesApi: api.inventory.import.codes,
        deleteApi: api.inventory.import.items.delete,
        spaced: true,
      }),
      name: baseRouteName,
      component: imp(
        () =>
          import(
            '@/pages/inventory/active/import/common/update/ConfirmedList.vue' /* webpackChunkName: 'inventory.active.import.quash' */
          ),
      ),
      children: [
        view({
          baseRouteName,
          source: InventoryImportSource.QUASH,
          loadApi: api.inventory.import.items.show,
          createApi: api.inventory.import.items.create,
          editApi: api.inventory.import.items.edit,
          quantitiesApi: api.inventory.import.items.quantities,
        }),
      ],
    },
    oi,
    mf,
  ],
};

export default route;
