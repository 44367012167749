<template>
  <v-alert
    v-bind="{ ...$attrs, ...$props }"
    :outlined="myOutlined"
    border="left"
    :icon="false"
    class="mb-2 mt-2"
    data-cy="alert"
  >
    <div class="d-flex">
      <v-icon class="mr-2">{{ icon }}</v-icon>
      <h3 :class="`font-weight-medium text-left align-center ${defaultClass}`">
        <slot />
      </h3>
      <v-spacer />
      <slot name="action" />
    </div>
  </v-alert>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UvAlertIcon',
  props: {
    type: {
      type: String,
      default: 'info',
    },
    defaultClass: {
      type: String,
      default: 'd-flex',
    },
  },
  computed: {
    myOutlined() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.outlined || this.$store.state.darkTheme;
    },
    icon() {
      if (this.type === 'warning') {
        return 'warning';
      } else if (this.type === 'error') {
        return 'error';
      } else if (this.type === 'success') {
        return 'check_circle';
      }

      return 'info';
    },
  },
});
</script>
