import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import { InventoryImportSource, ScripticItemType } from '@/pages/inventory/Inventory';

const baseRoute = `inventory.active.import.${InventoryImportSource.INITIAL}.${ScripticItemType.OI}`;

const route: UvRoute = {
  path: ScripticItemType.OI,
  name: baseRoute,
  props: true,
  meta: {
    header: {
      label: 'Lista cu obiecte de inventar importate',
      parentRoute: 'inventory.active',
      menu: (route, { inventory }) => [
        {
          label: 'Importa',
          icon: 'arrow_upward',
          disabled: inventory ? !inventory.config.types.oi.import.enabled : false,
          separated: true,
          route: {
            name: `${baseRoute}.upload`,
            params: route.params,
          },
        },
      ],
    },
    breadcrumb: {
      label: 'Lista cu obiecte de inventar importate',
      route: baseRoute,
    },
  },
  component: imp(
    () =>
      import(
        '@/pages/inventory/active/import/initial/lists/oi/Main.vue' /* webpackChunkName: 'inventory.active.import.initial' */
      ),
  ),
  children: [
    {
      path: 'upload',
      name: `${baseRoute}.upload`,
      props: {
        source: InventoryImportSource.INITIAL,
        type: ScripticItemType.OI,
        parentRoute: { name: baseRoute },
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      parentRoute: { name: `${baseRoute}.upload` },
      component: imp(
        () =>
          import(
            '@/pages/inventory/active/import/common/Upload.vue' /* webpackChunkName: 'inventory.active.import.initial' */
          ),
      ),
    },
  ],
};

export default route;
