import imp from '@/lib/imp';
import view from '@/pages/incidents/category/view/routes';
import { createInjectionComponent, UvRoute } from '@/lib/router';

const routes: UvRoute = {
  path: 'categories',
  props: true,
  component: createInjectionComponent(),
  meta: {
    header: {
      label: 'Categorii',
      route: 'incidents.categories.index',
      menu: () => [
        {
          label: 'Adauga categorie',
          icon: 'add',
          separated: true,
          route: {
            name: 'incidents.categories.create',
          },
          permissions: ['create.incident-categories'],
        },
      ],
    },
    breadcrumb: {
      label: 'Categorii',
      route: 'incidents.categories.index',
    },
  },
  children: [
    {
      path: '/',
      props: true,
      component: imp(
        () => import('@/pages/incidents/category/List.vue' /* webpackChunkName: 'incidents.categories' */),
      ),
      name: 'incidents.categories.index',
    },
    {
      path: 'create',
      props: true,
      component: imp(
        () => import('@/pages/incidents/category/actions/Create.vue' /* webpackChunkName: 'incidents.categories' */),
      ),
      name: 'incidents.categories.create',
      meta: {
        header: {
          label: 'Categorie noua',
        },
        breadcrumb: {
          label: 'Categorie noua',
          route: false,
        },
      },
    },
    view,
  ],
};

export default routes;
