import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const routes: UvRoute = {
  path: ':streetId',
  props: true,
  component: imp(() => import('@/pages/maps/streets/view/Main.vue' /* webpackChunkName: 'maps.streets.index' */)),
  meta: {
    header: {
      label: ({ street }: any) => street?.fullName || 'Strada',
      parentRoute: 'maps.streets.index',
      menu: () => [
        {
          label: 'Actualizare strada',
          icon: 'edit',
          route: {
            name: 'maps.streets.edit',
          },
          permissions: ['update.maps'],
        },
        {
          label: 'Actualizare numere si geometrie',
          icon: 'map',
          route: {
            name: 'maps.streets.editor',
          },
          permissions: ['update.maps'],
        },
      ],
    },
    breadcrumb: {
      label: ({ street }: any) => street?.fullName || 'Strada',
    },
  },
  children: [
    {
      path: '',
      props: true,
      name: 'maps.streets.show',
      component: imp(() => import('@/pages/maps/streets/view/Show.vue' /* webpackChunkName: 'maps.streets.index' */)),
      children: [
        {
          path: 'edit',
          props: true,
          name: 'maps.streets.edit',
          component: imp(
            () => import('@/pages/maps/streets/view/EditName.vue' /* webpackChunkName: 'maps.streets.index' */),
          ),
        },
      ],
    },
    {
      path: 'editor',
      props: true,
      name: 'maps.streets.editor',
      meta: {
        header: {
          label: ({ street }: any) => 'Actualizare numere si geometrie - ' + (street?.fullName || 'Strada'),
          parentRoute: 'maps.streets.show',
          menu: () => [],
        },
        wide: true,
      },
      component: imp(
        () => import('@/pages/maps/streets/view/editor/Main.vue' /* webpackChunkName: 'maps.streets.editor' */),
      ),
    },
  ],
};

export default routes;
