import { UvRouteHeader } from '@/lib/router';

const header: UvRouteHeader = {
  route: 'institution.suppliers.index',
  label: ({ supplier }) => (supplier ? `Vizualizare furnizor "${supplier}"` : 'Vizualizare furnizor'),
  menu: (route) => [
    {
      route: {
        name: 'institution.suppliers.edit',
        params: route.params,
      },
      icon: 'edit',
      label: 'Editeaza',
    },
  ],
};

export default header;
