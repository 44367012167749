import { createInjectionComponent, UvRoute } from '@/lib/router';
import notifications from '@/pages/me/notifications/routes';
import apiTokens from '@/pages/me/api-tokens/routes';

const route: UvRoute = {
  path: 'me',
  props: true,
  component: createInjectionComponent(),
  children: [notifications, apiTokens],
};

export default route;
