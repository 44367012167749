import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: 'inventory',
  props: true,
  component: imp(
    () =>
      import(
        '@/pages/assets/modules/products/import/inventory/Main.vue' /* webpackChunkName: 'assets.products.inventory.import' */
      ),
  ),
  meta: {
    header: {
      label: 'Importa produse din inventar',
      route: 'assets.products.index',
    },
    breadcrumb: {
      label: 'Importa produse din inventar',
      route: 'assets.products.index',
    },
  },
  children: [
    {
      path: '/',
      props: true,
      name: 'assets.products.import.inventory',
      component: imp(
        () =>
          import(
            '@/pages/assets/modules/products/import/inventory/ScripticItems.vue' /* webpackChunkName: 'assets.products.inventory.import' */
          ),
      ),
      children: [
        {
          path: 'supplier',
          props: true,
          name: 'assets.products.import.inventory.supplier',
          component: imp(
            () =>
              import(
                '@/pages/assets/modules/products/import/inventory/SupplierDialog.vue' /* webpackChunkName: 'assets.products.inventory.import' */
              ),
          ),
        },
      ],
    },
  ],
};

export default route;
