import { UvRoute } from '@/lib/router';
import modules from '@/pages/assets/modules/routes';
import { CreateElement } from 'vue';

const route: UvRoute = {
  path: 'assets',
  props: true,
  component: {
    render: (h: CreateElement) => h('router-view'),
  },
  children: [modules],
};

export default route;
