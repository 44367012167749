import VueRouter from 'vue-router';
import { InjectionKey } from 'vue';
import { Store } from 'vuex';
import { RootState } from '@/store';
import { ShowConfirmationDialog } from '@/plugins/vuetify-confirm';

import * as filters from '@/lib/filters';
import { DownloadMaker } from '@/plugins/download';

export const RouterKey: InjectionKey<VueRouter> = Symbol('VueRouter');
export const StoreKey: InjectionKey<Store<RootState>> = Symbol('Store');
export const MsgKey: InjectionKey<MessageLogger> = Symbol('Msg');

export const ConfirmDialogKey: InjectionKey<ShowConfirmationDialog> = Symbol('ConfirmDialog');

export const PageDepsKey: InjectionKey<PageDeps> = Symbol('PageDeps');

export const DateFormatterKey: InjectionKey<typeof filters.formatDate> = Symbol('FormatDate');
export const DateFormatterAltKey: InjectionKey<typeof filters.formatDateAlt> = Symbol('FormatDateAlt');
export const DateTimeFormatterKey: InjectionKey<typeof filters.formatDateTime> = Symbol('FormatDateTime');
export const TimestampFormatterKey: InjectionKey<typeof filters.formatTimestamp> = Symbol('FormatTimestamp');
export const DurationHumanizerKey: InjectionKey<typeof filters.humanizeDuration> = Symbol('DurationHumanizer');

export const DateParserKey: InjectionKey<typeof filters.humanizeDuration> = Symbol('DurationHumanizer');

export const QuantityFormatterKey: InjectionKey<typeof filters.formatQuantity> = Symbol('QuantityFormatter');

export const CurrencyFormatterKey: InjectionKey<typeof filters.formatCurrency> = Symbol('CurrencyFormatter');

export const BooleanFormatterKey: InjectionKey<typeof filters.boolean> = Symbol('BooleanFormatter');

export const NullableFormatterKey: InjectionKey<typeof filters.nullable> = Symbol('NullableFormatter');

export const TruncateFormatterKey: InjectionKey<typeof filters.truncate> = Symbol('TruncateFormatter');

export const DownloadKey: InjectionKey<DownloadMaker> = Symbol('Download');
