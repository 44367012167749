import { ActionTree, Module, MutationTree } from 'vuex';
import { RootState } from '.';

export interface SystemState {
  now: Date;
  darkTheme: boolean;
}

const state: SystemState = {
  now: new Date(),
  darkTheme: false,
};

const mutations: MutationTree<SystemState> = {
  updateTime: (state) => {
    state.now = new Date();
  },
  setDarkTheme: (state, v) => {
    state.darkTheme = v;
  },
};

const actions: ActionTree<SystemState, any> = {
  updateDarkTheme: ({ commit }, v) => {
    commit('setDarkTheme', v);
  },
  start: ({ dispatch }) => {
    dispatch('startTime');
  },
  startTime: ({ commit }) => setInterval(() => commit('updateTime'), 60 * 1000),
};

export const module: Module<SystemState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};
