import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import edit from '@/pages/assets/modules/services/view/edit/routes';

const routes: UvRoute[] = [
  {
    path: '',
    name: 'assets.services.show',
    props: true,
    meta: {
      permissions: ['read.services'],
      breadcrumb: {
        label: ({ service }) => (service ? service.name : 'Serviciu'),
        route: 'assets.services.show',
      },
      header: {
        label: ({ service }) => (service ? `${service.name} - Serviciu` : 'Serviciu'),
        menu: () => [
          {
            label: 'Editeaza',
            route: {
              name: 'assets.services.edit',
            },
            icon: 'edit',
            permissions: ['update.services'],
          },
          {
            label: 'Asociaza produse',
            route: {
              name: 'assets.services.products.edit',
            },
            icon: 'sync_alt',
            permissions: ['update.services'],
          },
        ],
      },
    },
    component: imp(
      () => import('@/pages/assets/modules/services/view/Show.vue' /* webpackChunkName: 'assets.services.main' */),
    ),
    children: [
      ...edit,
      {
        path: 'products',
        props: true,
        component: imp(
          () =>
            import(
              '@/pages/assets/modules/services/view/products/Main.vue' /* webpackChunkName: 'assets.services.main' */
            ),
        ),
        children: [
          {
            path: 'edit',
            props: true,
            name: 'assets.services.products.edit',
            meta: {
              breadcrumb: {
                label: 'Produse',
                route: 'assets.services.show',
              },
            },
            component: imp(
              () =>
                import(
                  '@/pages/assets/modules/services/view/products/Edit.vue' /* webpackChunkName: 'assets.services.main' */
                ),
            ),
          },
        ],
      },
      {
        path: 'edit',
        name: 'assets.services.edit',
        props: true,
        meta: {
          permissions: ['update.services'],
        },
        component: imp(
          () => import('@/pages/assets/modules/services/view/Edit.vue' /* webpackChunkName: 'assets.services.main' */),
        ),
      },
      {
        path: 'location/edit',
        name: 'assets.services.edit.location',
        meta: {
          permissions: ['update.services'],
        },
        component: imp(
          () =>
            import(
              '@/pages/assets/modules/services/view/location/Edit.vue' /* webpackChunkName: 'assets.services.main' */
            ),
        ),
      },
    ],
  },
];

export default routes;
