import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import createVuexSerializer, { PathMapDefinition } from '@/lib/vuex-serializer';
import { module as activeInventory, ActiveInventoryState } from '@/store/activeInventory';
import { AssetsState, module as assets } from '@/store/assets';
import { AuthState, module as auth } from '@/store/auth';
import institutions from '@/store/institutions';
import { module as messages, MessagesState } from '@/store/messages';
import { module as modules, ModulesState } from '@/store/modules';
import { module as notifications, NotificationsState } from '@/store/notifications';
import { module as pageDeps, PageDepsState } from '@/store/pageDeps';
import { module as userInstitution, UserInstitutionState } from '@/store/userInstitution';
import users from '@/store/users';
import { module as system, SystemState } from '@/store/system';

export interface RootState {
  activeInventory: ActiveInventoryState;
  assets: AssetsState;
  auth: AuthState;
  messages: MessagesState;
  modules: ModulesState;
  notifications: NotificationsState;
  pageDeps: PageDepsState;
  system: SystemState;
  userInstitution: UserInstitutionState;
}

Vue.use(Vuex);

const serializerPaths: PathMapDefinition = ['system.darkTheme'];

export default new Store<RootState>({
  modules: {
    activeInventory,
    assets,
    auth,
    institutions,
    messages,
    modules,
    notifications,
    pageDeps,
    userInstitution,
    users,
    system,
  },
  plugins: [createVuexSerializer(serializerPaths, { prefetch: true, throttle: true })],
});
