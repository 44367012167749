import imp from '@/lib/imp';
import { createInjectionComponent, UvRoute } from '@/lib/router';

const routes: UvRoute = {
  path: 'incidents',
  props: true,
  component: createInjectionComponent(),
  meta: {
    header: {
      label: 'Incidente',
      route: 'incidents.index',
    },
    breadcrumb: {
      label: 'Incidente',
      route: 'incidents.index',
    },
  },
  children: [
    {
      path: '/',
      props: true,
      component: imp(() => import('@/pages/incidents/incidents/List.vue' /* webpackChunkName: 'incidents' */)),
      name: 'incidents.index',
      children: [
        {
          path: ':incidentId',
          props: true,
          name: 'incidents.show',
          component: imp(() => import('@/pages/incidents/incidents/Show.vue' /* webpackChunkName: 'incidents.view' */)),
          meta: {
            header: {
              label: 'Rezolvare incident',
              route: 'incidents.index',
            },
            breadcrumb: {
              label: 'Rezolvare incident',
              route: 'incidents.index',
            },
          },
          children: [
            {
              path: 'citizen-reports/:citizenReportId',
              props: true,
              name: 'incidents.citizenReports.show',
              component: imp(
                () =>
                  import('@/pages/incidents/incidents/ShowCitizenReport.vue' /* webpackChunkName: 'incidents.view' */),
              ),
            },
          ],
        },
      ],
    },
  ],
};

export default routes;
