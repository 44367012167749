import { UvRoute } from '@/lib/router';
import imp from '@/lib/imp';

const route: UvRoute = {
  path: 'notifications',
  redirect: { name: 'me.notifications.unread' },
  name: 'me.notifications',
  component: imp(() => import('@/pages/me/notifications/Main.vue' /* webpackChunkName: 'notifications' */)),
  children: [
    {
      path: 'unread',
      name: 'me.notifications.unread',
      props: {
        unread: true,
      },
      meta: {
        header: {
          label: 'Notificari necitite',
        },
        breadcrumb: {
          label: 'Notificari necitite',
        },
      },
      component: imp(() => import('@/pages/me/notifications/List.vue' /* webpackChunkName: 'notifications' */)),
    },
    {
      path: 'read',
      name: 'me.notifications.read',
      props: {
        unread: false,
      },
      meta: {
        header: {
          label: 'Notificari citite',
        },
        breadcrumb: {
          label: 'Notificari citite',
        },
      },
      component: imp(() => import('@/pages/me/notifications/List.vue' /* webpackChunkName: 'notifications' */)),
    },
  ],
};

export default route;
