import imp from '@/lib/imp';
import view from '@/pages/urbanism/common/files/view/routes';
import { Endpoint } from '@/lib/api-builder';
import { UvRoute } from '@/lib/router';

interface IConfig {
  route: string;
  loadApi: Endpoint;
  loadApiParams: string[];
  uploadApi: Endpoint;
  uploadApiParams: string[];
  downloadApi: Endpoint;
  downloadApiParams: string[];
}

const filesRouter = (config: IConfig) =>
  ({
    path: 'files',
    props: (route) => ({
      ...route.params,
      ...config,
    }),
    component: imp(() => import('@/pages/urbanism/common/files/Main.vue' /* webpackChunkName: 'urbanism.files' */)),
    meta: {
      header: {
        label: 'Fisiere',
        parentRoute: `urbanism.${config.route}.show`,
        menu: () => [
          {
            label: 'Adauga fisier',
            icon: 'add',
            separated: true,
            route: {
              name: `urbanism.${config.route}.files.create`,
            },
            permissions: ['update.regulations', 'create.files'],
          },
        ],
      },
      breadcrumb: {
        label: 'Fisiere',
        route: `urbanism.${config.route}.files.index`,
      },
    },
    children: [
      {
        path: '',
        props: (route) => ({
          ...route.params,
          ...config,
        }),
        name: `urbanism.${config.route}.files.index`,
        component: imp(() => import('@/pages/urbanism/common/files/List.vue' /* webpackChunkName: 'urbanism.files' */)),
      },
      {
        path: 'create',
        props: (route) => ({
          ...route.params,
          ...config,
        }),
        name: `urbanism.${config.route}.files.create`,
        component: imp(
          () => import('@/pages/urbanism/common/files/actions/Create.vue' /* webpackChunkName: 'urbanism.files' */),
        ),
        meta: {
          header: {
            label: 'Fisier nou',
            parentRoute: `urbanism.${config.route}.files.index`,
          },
          breadcrumb: {
            label: 'Fisier nou',
            route: false,
          },
        },
      },
      view,
    ],
  }) as UvRoute;

export default filesRouter;
