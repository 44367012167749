import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const routes: UvRoute[] = [
  {
    path: 'create',
    name: 'assets.flows.create',
    props: true,
    meta: {
      permissions: ['create.flows'],
    },
    component: imp(
      () => import('@/pages/assets/modules/flows/actions/Create.vue' /* webpackChunkName: 'assets.flows.main' */),
    ),
  },
];

export default routes;
