import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import scriptic from '@/pages/inventory/history/view/scriptic/routes';
import receptions from '@/pages/inventory/history/view/receptions/routes';
import quashes from '@/pages/inventory/history/view/quashes/routes';
import reports from '@/pages/inventory/history/view/reports/routes';

const route: UvRoute = {
  path: ':inventoryId',
  props: true,
  component: imp(() => import('@/pages/inventory/history/view/Main.vue' /* webpackChunkName: 'inventory.history' */)),
  meta: {
    header: {
      label: (deps) => `Inventar ${deps.inventory?.name || 'necunoscut'}`,
      route: 'inventory.history.show',
    },
    breadcrumb: {
      label: (deps) => `Inventar ${deps.inventory?.name || 'necunoscut'}`,
      route: 'inventory.history.show',
    },
  },
  children: [
    {
      path: '',
      name: 'inventory.history.show',
      component: imp(
        () => import('@/pages/inventory/history/view/Actions.vue' /* webpackChunkName: 'inventory.history' */),
      ),
      props: true,
    },
    scriptic,
    receptions,
    quashes,
    reports,
    {
      path: 'committee',
      name: 'inventory.history.scriptic.committee',
      component: imp(
        () =>
          import('@/pages/inventory/history/view/scriptic/Committee.vue' /* webpackChunkName: 'inventory.history' */),
      ),
      props: true,
      meta: {
        permissions: ['read.committee'],
        header: {
          parentRoute: 'inventory.history.scriptic',
          label: 'Comisie',
        },
        breadcrumb: {
          route: 'inventory.history.scriptic.committee',
          label: 'Comisie',
        },
      },
    },
    {
      path: 'org-chart',
      name: 'inventory.history.scriptic.org-chart',
      component: imp(
        () =>
          import('@/pages/inventory/history/view/scriptic/OrgChart.vue' /* webpackChunkName: 'inventory.history' */),
      ),
      props: true,
      meta: {
        breadcrumb: {
          label: 'Organigrama',
          route: 'inventory.active.org-chart',
        },
        header: {
          label: 'Organigrama',
          route: 'inventory.active',
        },
      },
    },
  ],
};

export default route;
