import { PluginObject } from 'vue';

export const permissions: PluginObject<undefined> = {
  install(Vue) {
    Vue.prototype.$can = function (what: string): boolean {
      return this.$store.getters['auth/can'](what);
    };
  },
};

declare module 'vue/types/vue' {
  interface Vue {
    $can: (what: string) => boolean;
  }
}
