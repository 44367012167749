<template>
  <v-dialog
    v-if="mounted"
    @input="change"
    @keydown.esc="choose(false)"
    :max-width="width"
    :persistent="persistent"
    :value="value"
    eager
  >
    <v-toolbar
      v-if="Boolean(title)"
      :color="color"
      dark
      dense
    >
      <v-icon
        v-if="Boolean(icon)"
        class="mr-2"
      >
        {{ icon }}
      </v-icon>
      <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
    </v-toolbar>
    <v-card tile>
      <v-card-text class="pt-4">
        <div v-html="message" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="Boolean(buttonFalseText)"
          @click="choose(false)"
          ref="cancelButton"
          :color="buttonFalseColor"
        >
          {{ buttonFalseText }}
        </v-btn>
        <v-btn
          v-if="Boolean(buttonTrueText)"
          @click="choose(true)"
          ref="confirmButtonRef"
          :color="buttonTrueColor"
        >
          {{ buttonTrueText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs, ref, ComponentPublicInstance, onMounted } from 'vue';

export default defineComponent({
  props: {
    buttonTrueText: {
      type: String,
      default: 'Da',
    },
    buttonFalseText: {
      type: String,
      default: 'Nu',
    },
    buttonTrueColor: {
      type: String,
      default: 'primary',
    },
    buttonFalseColor: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'warning',
    },
    icon: {
      type: String,
      default() {
        return this.$vuetify.icons.values.warning;
      },
    },
    message: {
      type: String,
      required: true,
    },
    persistent: Boolean,
    title: {
      type: String,
    },
    width: {
      type: Number,
      default: 400,
    },
  },
  emits: ['result', 'destroyed'],
  setup(props, ctx) {
    const confirmButtonRef = ref<ComponentPublicInstance<HTMLElement>>();
    const state = reactive({
      value: true,
      mounted: true,
    });
    const choose = (value: boolean) => {
      ctx.emit('result', value);
      state.value = value;
      state.mounted = false;
    };
    const change = () => {
      state.mounted = false;
    };
    onMounted(() => {
      setTimeout(() => (confirmButtonRef.value?.$el as HTMLElement).focus(), 100);
    });
    return {
      ...toRefs(state),
      confirmButtonRef,
      choose,
      change,
    };
  },
});
</script>
