import { parse, compile } from 'path-to-regexp';
import { normalize } from '@/lib/promises';
import { isBoolean, isNumber, isObject, isString } from '@/lib/tools';

const normalize300 = normalize(300);

export enum Method {
  GET = 'get',
  HEAD = 'head',
  OPTIONS = 'options',
  PUT = 'put',
  PATCH = 'patch',
  POST = 'post',
  DELETE = 'delete',
}
export enum SafeMethod {
  GET = Method.GET,
  HEAD = Method.HEAD,
  OPTIONS = Method.OPTIONS,
}

export const makeUrl = (host: string, url: string) => host + (url.length > 0 && url[0] !== '/' ? '/' + url : url);

// eslint-disable-next-line no-use-before-define
export type QsParamObj = { [key: string]: QsParam };
type QsParamT = string | number | boolean | QsParamObj;
export type QsParam = QsParamT | QsParamT[];

type HeadersMaker = (...args: any) => HeadersInit;

interface EndpointOptions {
  asJSON?: boolean;
  acceptJSON?: boolean;
  cookies?: boolean;
}

interface RequestParams extends Omit<RequestInit, 'body'> {
  body?: BodyInit | object;
  normalize?: boolean;
  params?: QsParamObj;
}

export interface Endpoint {
  (p?: RequestParams): Promise<Response>;
  url: (params: QsParamObj) => string;
  req: (p: RequestParams) => Promise<Response>;
}

export type EndpointGenerator = (route: string, options?: EndpointOptions) => Endpoint;

export interface ValidationErrorPayload {
  errors: {
    [k: string]: string[];
  };
}

export interface GenericErrorPayload {
  error: string;
}

export type ErrorPayload = ValidationErrorPayload | GenericErrorPayload;

export interface PaginatedSuccessPayload<T = any> {
  data: T[];
  hasNextPage: boolean;
  hasPrevPage: boolean;
  itemsPerPage: number;
  next: number;
  page: number;
  pagingCounter: number;
  prev: number;
  totalItems: number;
  totalPages: number;
}

export interface SimpleSuccessPayload<T = any> {
  data: T;
}

export type SuccessPayload<T = any> = SimpleSuccessPayload<T> | PaginatedSuccessPayload<T>;

export type Payload = ErrorPayload | SuccessPayload;

export type PayloadHandler = (payload: SuccessPayload, response: Response) => Promise<any> | void;

export type ErrorPayloadHandler = (payload: ErrorPayload, response: Response) => Promise<any> | void;

export interface PayloadHandlerExtras {
  [s: number]: ErrorPayloadHandler;
}

const makeQsParam = (prefix: string, value: QsParam): string => {
  if (Array.isArray(value)) {
    return value.map((item: QsParam, index: number): string => makeQsParam(prefix + '[' + index + ']', item)).join('&');
  }

  if (isString(value) || isNumber(value) || isBoolean(value)) {
    return encodeURIComponent(prefix) + '=' + encodeURIComponent(value);
  }

  if (value === undefined || value === null) {
    return prefix;
  }

  return Object.entries(value)
    .map(([key, objValue]) => makeQsParam(prefix + '[' + key + ']', objValue))
    .join('&');
};

const makeQs = (params: { [key: string]: QsParam }) =>
  '?' +
  Object.entries(params)
    .map(([param, value]) => makeQsParam(encodeURIComponent(param), value))
    .join('&');

const makeMethodRoute =
  (method: string, rootUrl: string, makeHeaders: HeadersMaker): EndpointGenerator =>
  (route = '', options: EndpointOptions = {}): Endpoint => {
    const { asJSON = true, acceptJSON = true, cookies = false } = options;

    const { tokens } = parse(route);
    const variableTokenNames = tokens.filter((token) => token !== 'string').map((token: any) => token.name);
    const toPath = compile(route);

    const url = (params = {}) => {
      const qsParams = Object.entries(params)
        .filter(([param]) => param !== undefined && !variableTokenNames.includes(param))
        .reduce(
          (carry, [param, value]) =>
            Object.defineProperty(carry, param, {
              value,
              enumerable: true,
            }),
          {},
        );

      return makeUrl(rootUrl, toPath(params) + makeQs(qsParams).replace(/^\?$/, ''));
    };

    const fn = (args: RequestParams = {}): Promise<Response> => {
      const { body: _body, headers, params = {}, normalize: _normalize = true, ...rest } = args;

      const runFetch = () =>
        fetch(url(params), {
          ...rest,
          method: method.toUpperCase(),
          credentials: cookies ? 'include' : 'omit',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          body: Object.values(SafeMethod).includes(method)
            ? undefined
            : asJSON || isObject(_body)
              ? JSON.stringify(_body)
              : _body,
          headers: makeHeaders(headers, {
            asJSON,
            acceptJSON,
          }),
        });

      return _normalize ? normalize300(runFetch) : runFetch();
    };

    fn.url = url;
    fn.req = (args: RequestParams = {}) => fn(args);

    return fn;
  };

const makeDefaultHeaders =
  (makeRootHeaders: HeadersMaker): HeadersMaker =>
  (additional: HeadersInit, { asJSON, acceptJSON }: EndpointOptions): HeadersInit => ({
    ...(asJSON ? { 'Content-Type': 'application/json' } : {}),
    ...(acceptJSON ? { Accept: 'application/json' } : {}),
    ...makeRootHeaders(),
    ...additional,
  });

export type Api = {
  [s in Method]: EndpointGenerator;
};

export default (rootUrl: string, makeRootHeaders: HeadersMaker): Api => {
  const entries = Object.values(Method).map((method) => [
    method,
    makeMethodRoute(method, rootUrl, makeDefaultHeaders(makeRootHeaders)),
  ]);

  return Object.fromEntries(entries);
};

export const handleResponsePayload =
  (ret: PayloadHandler | null, extras: PayloadHandlerExtras = {}) =>
  (res: Response): Promise<any> =>
    res.json().then((payload) => {
      if (res.ok) {
        return ret instanceof Function ? ret(payload as SuccessPayload, res) : ret;
      }

      if (extras.hasOwnProperty(res.status)) {
        const statusHandler = extras[res.status];

        return statusHandler(payload as ErrorPayload, res);
      }

      return Promise.reject(new Error(payload.error));
    });

export const isPaginatedSuccessPayload = (p: SuccessPayload): p is PaginatedSuccessPayload => 'total' in p;

export const isValidationErrorPayload = (p: ErrorPayload, r?: Response): p is ValidationErrorPayload =>
  r ? r.status === 422 && 'errors' in p : 'errors' in p;

export const isErrorPayload = (p: ErrorPayload, r?: Response): p is GenericErrorPayload =>
  r ? !r.ok && 'error' in p : 'error' in p;
