import { Endpoint } from '@/lib/api-builder';
import imp from '@/lib/imp';
import { UvRoute, createInjectionComponent } from '@/lib/router';
import quantities from '@/pages/inventory/active/import/common/update/view/quantities/routes';

interface Params {
  baseRouteName: string;
  source: string;
  createApi: Endpoint;
  loadApi: Endpoint;
  editApi: Endpoint;
  quantitiesApi: {
    create: Endpoint;
    edit: Endpoint;
    delete: Endpoint;
  };
}

export default ({ baseRouteName, source, createApi, loadApi, editApi, quantitiesApi }: Params): UvRoute => ({
  path: ':type/items',
  component: createInjectionComponent(),
  children: [
    {
      path: 'create',
      name: `${baseRouteName}.items.create`,
      props: (route) => ({
        ...route.params,
        baseRouteName,
        source,
        createApi,
        parentRoute: { name: baseRouteName },
      }),
      component: imp(
        () =>
          import(
            '@/pages/inventory/active/import/common/update/view/Create.vue' /* webpackChunkName: 'inventory.active.import.type' */
          ),
      ),
    },
    {
      path: ':itemId',
      props: (route) => ({
        ...route.params,
        source,
        loadApi,
      }),
      component: imp(
        () =>
          import(
            '@/pages/inventory/active/import/common/update/view/Main.vue' /* webpackChunkName: 'inventory.active.import.type' */
          ),
      ),
      children: [
        {
          path: 'show',
          name: `${baseRouteName}.items.show`,
          props: (route) => ({
            ...route.params,
            baseRouteName,
            parentRoute: { name: baseRouteName },
          }),
          component: imp(
            () =>
              import(
                '@/pages/inventory/active/import/common/update/view/Show.vue' /* webpackChunkName: 'inventory.active.import.type' */
              ),
          ),
          children: [quantities(baseRouteName, `${baseRouteName}.items.show`, quantitiesApi)],
        },
        {
          path: 'edit',
          name: `${baseRouteName}.items.edit`,
          props: (route) => {
            return {
              ...route.params,
              parentRoute: { name: baseRouteName },
              source,
              editApi,
            };
          },
          component: imp(
            () =>
              import(
                '@/pages/inventory/active/import/common/update/view/Edit.vue' /* webpackChunkName: 'inventory.active.import.type' */
              ),
          ),
        },
      ],
    },
  ],
});
