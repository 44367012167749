export default {
  host: process.env.VUE_APP_OSM_HOST,
  auth_callback_url: process.env.VUE_APP_OSM_HOST + process.env.VUE_APP_OSM_AUTH_CALLBACK_URI,
  map: {
    minZoom: 1,
    url: process.env.VUE_APP_OSM_TILES_URL,
    extent: [
      [
        [19.88525390625, 43.51668853502906],
        [29.9267578125, 43.51668853502906],
        [29.9267578125, 48.44377831058802],
        [19.88525390625, 48.44377831058802],
        [19.88525390625, 43.51668853502906],
      ],
    ],
    tiles: {
      maxZoom: 25,
      maxNativeZoom: 17,
    },
  },
};
