import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: '',
  name: 'institution.users.index',
  component: imp(
    () => import('@/pages/institution/users/index/Main.vue' /* webpackChunkName: 'institution.users.index' */),
  ),
  children: [
    {
      path: 'create',
      name: 'institution.users.create',
      meta: {
        permissions: ['create.users'],
      },
      component: imp(
        () =>
          import(
            '@/pages/institution/users/index/actions/Create.vue' /* webpackChunkName: 'institution.users.index' */
          ),
      ),
    },
  ],
};

export default route;
