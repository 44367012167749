import api from '@/api';
import { prepTree, Tree } from '@/lib/trees';
import { initModule } from '@/store/modules';
import { Module } from 'vuex';
import { RootState } from '.';

export interface AssetsModule extends Tree {
  color: string;
}

export interface AssetsState {
  loading: boolean;
  error: null | Error;
  module: null | AssetsModule;
}

export const module: Module<AssetsState, RootState> = {
  namespaced: true,
  state: {
    loading: false,
    error: null,
    module: null,
  },
  getters: {
    moduleName: (s) => (s.module ? s.module.label : null),
  },
  mutations: {
    startLoading(state) {
      state.error = null;
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    setError(state, error) {
      state.error = error;
    },
    setModule(state, moduleData) {
      const module: AssetsModule = {
        ...prepTree(moduleData),
        ...initModule(moduleData),
      };
      state.module = module;
    },
  },
  actions: {
    load({ commit }, moduleId) {
      commit('startLoading');
      return api.assets.modules
        .show({ params: { moduleId } })
        .then((response) =>
          response
            .json()
            .then((payload) =>
              response.ok ? commit('setModule', payload.data) : Promise.reject(new Error(payload.error)),
            ),
        )
        .catch((e) => commit('setError', e))
        .finally(() => commit('stopLoading'));
    },
  },
};
