import api from '@/api';
import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import roles from '@/pages/institution/users/view/roles/routes';
import header from '@/pages/institution/users/view/header';

const route: UvRoute = {
  path: ':userId',
  meta: {
    permissions: ['read.users'],
  },
  component: imp(
    () => import('@/pages/institution/users/view/Main.vue' /* webpackChunkName: 'institution.users.show' */),
  ),
  props: (route) => ({
    userId: route.params.userId,
  }),
  children: [
    {
      path: '',
      name: 'institution.users.show',
      component: imp(
        () => import('@/pages/institution/users/view/Show.vue' /* webpackChunkName: 'institution.users.show' */),
      ),
      meta: {
        header,
      },
      children: [
        {
          path: 'name',
          name: 'institution.users.edit.name',
          meta: {
            permissions: ['update.users'],
          },
          props: {
            submitFn: api.institution.users.edit.name,
          },
          component: imp(() => import('@/components/users/edit/Name.vue' /* webpackChunkName: 'users.edit' */)),
        },
        {
          path: 'password',
          name: 'institution.users.edit.password',
          meta: {
            permissions: ['update.users'],
          },
          props: {
            submitFn: api.institution.users.edit.password,
          },
          component: imp(() => import('@/components/users/edit/Password.vue' /* webpackChunkName: 'users.edit' */)),
        },
        {
          path: 'email',
          name: 'institution.users.edit.email',
          meta: {
            permissions: ['update.users'],
          },
          props: {
            submitFn: api.institution.users.edit.email,
          },
          component: imp(() => import('@/components/users/edit/Email.vue' /* webpackChunkName: 'users.edit' */)),
        },
      ],
    },
    roles,
  ],
};

export default route;
