import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const routes: UvRoute = {
  path: ':categoryId',
  props: true,
  component: imp(
    () => import('@/pages/incidents/category/view/Main.vue' /* webpackChunkName: 'incidents.categories.view' */),
  ),
  meta: {
    breadcrumb: {
      label: ({ category }) => category || 'Categorie',
      route: 'incidents.categories.show',
    },
    header: {
      label: ({ category }) => category || 'Categorie',
    },
  },
  children: [
    {
      path: '/',
      props: true,
      name: 'incidents.categories.show',
      component: imp(
        () => import('@/pages/incidents/category/view/Show.vue' /* webpackChunkName: 'incidents.categories.view' */),
      ),
    },
    {
      path: 'edit',
      props: true,
      name: 'incidents.categories.edit',
      component: imp(
        () => import('@/pages/incidents/category/view/Edit.vue' /* webpackChunkName: 'incidents.categories.view' */),
      ),
      meta: {
        breadcrumb: {
          label: 'Editare',
          route: false,
        },
        header: {
          label: ({ category }) => (category ? `Editare ${category}` : 'Editare categorie'),
          parentRoute: 'incidents.categories.show',
        },
      },
    },
  ],
};

export default routes;
