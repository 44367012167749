import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: '',
  component: imp(
    () =>
      import(
        '@/pages/assets/modules/products/view/maintenances/index/Main.vue' /* webpackChunkName: 'assets.products.maintenances' */
      ),
  ),
  children: [
    {
      path: '',
      name: 'assets.products.maintenances.index',
      component: imp(
        () =>
          import(
            '@/pages/assets/modules/products/view/maintenances/index/List.vue' /* webpackChunkName: 'assets.products.maintenances' */
          ),
      ),
      children: [
        {
          path: 'assign',
          name: 'assets.products.maintenances.assign',
          component: imp(
            () =>
              import(
                '@/pages/assets/modules/products/view/maintenances/index/Assign.vue' /* webpackChunkName: 'assets.products.maintenances.assign' */
              ),
          ),
        },
      ],
    },
  ],
};

export default route;
