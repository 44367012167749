import imp from '@/lib/imp';

export default {
  path: 'scan/allocate',
  name: 'inventory.scriptic.scans.allocate',
  component: imp(
    () =>
      import(
        '@/pages/inventory/active/scriptic/view/scan/Allocate.vue' /* webpackChunkName: 'inventory.active.scriptic.scan' */
      ),
  ),
};
