import Layout from '@/layout/Main.vue';
import NotFound from '@/layout/NotFound.vue';
import { UvRoute } from '@/lib/router';
import Login from '@/pages/Login.vue';
import admin from '@/pages/admin/routes';
import assets from '@/pages/assets/routes';
import home from '@/pages/home/routes';
import incidents from '@/pages/incidents/routes';
import institution from '@/pages/institution/routes';
import inventory from '@/pages/inventory/routes';
import maps from '@/pages/maps/routes';
import me from '@/pages/me/routes';
import registry from '@/pages/registry/routes';
import reports from '@/pages/reports/routes';
import urbanism from '@/pages/urbanism/routes';

const routes: UvRoute[] = [
  {
    path: '/login',
    name: 'login',
    meta: { auth: false },
    component: Login,
  },
  {
    path: '/',
    component: Layout,
    meta: {
      auth: true,
      breadcrumb: {
        icon: 'home',
        route: 'home',
      },
    },
    children: [
      home,
      admin,
      assets,
      incidents,
      institution,
      inventory,
      maps,
      me,
      registry,
      urbanism,
      reports,
      {
        path: '*',
        component: NotFound,
      },
    ],
  },
];

export default routes;
