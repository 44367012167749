import api from '@/api';
import files from '@/pages/urbanism/common/files/routes';
import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import areas from '@/pages/urbanism/common/areas/routes';

const routes: UvRoute = {
  path: ':subdivisionId',
  props: true,
  component: imp(
    () =>
      import(
        '@/pages/urbanism/regulation/view/subdivisions/view/Main.vue' /* webpackChunkName: 'urbanism.regulations.subdivisions.view' */
      ),
  ),
  meta: {
    breadcrumb: {
      label: ({ subdivision }) => subdivision || 'Subdiviziune',
      route: 'urbanism.regulations.subdivisions.show',
    },
    header: {
      label: ({ subdivision }) => subdivision || 'Subdiviziune',
      parentRoute: 'urbanism.regulations.subdivisions.index',
    },
  },
  children: [
    {
      path: '/',
      props: true,
      name: 'urbanism.regulations.subdivisions.show',
      component: imp(
        () =>
          import(
            '@/pages/urbanism/regulation/view/subdivisions/view/Show.vue' /* webpackChunkName: 'urbanism.regulations.subdivisions.view' */
          ),
      ),
    },
    {
      path: 'edit',
      props: true,
      name: 'urbanism.regulations.subdivisions.edit',
      component: imp(
        () =>
          import(
            '@/pages/urbanism/regulation/view/subdivisions/view/Edit.vue' /* webpackChunkName: 'urbanism.regulations.subdivisions' */
          ),
      ),
      meta: {
        breadcrumb: {
          label: 'Editare',
          route: false,
        },
        header: {
          label: ({ subdivision }) => (subdivision ? `Editare ${subdivision}` : 'Editare'),
          parentRoute: 'urbanism.regulations.subdivisions.show',
        },
      },
    },
    files({
      route: 'regulations.subdivisions',
      loadApi: api.urbanism.regulations.subdivisions.files.index,
      loadApiParams: ['regulationId', 'subdivisionId'],
      uploadApi: api.urbanism.regulations.subdivisions.files.upload,
      uploadApiParams: ['regulationId', 'subdivisionId'],
      downloadApi: api.urbanism.regulations.subdivisions.files.download,
      downloadApiParams: ['regulationId', 'subdivisionId'],
    }),
    areas({
      route: 'regulations.subdivisions',
      loadApi: api.urbanism.regulations.subdivisions.area.show,
      loadApiParams: ['regulationId', 'subdivisionId'],
      loadBoundsApi: api.urbanism.regulations.subdivisions.area.bounds,
      loadBoundsApiParams: ['regulationId', 'subdivisionId'],
      loadAdditionalAreasApi: api.urbanism.regulations.subdivisions.neighbors,
      loadAdditionalAreasApiParams: ['regulationId', 'subdivisionId'],
      saveApi: api.urbanism.regulations.subdivisions.area.edit,
      saveApiParams: ['regulationId', 'subdivisionId'],
    }),
  ],
};

export default routes;
