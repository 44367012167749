import imp from '@/lib/imp';
import categories from '@/pages/admin/assets/modules/categories/routes';
import { makeTreeRoutes } from '@/components/tree-nodes/tree/routes';
import { createInjectionComponent, UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: 'modules',
  component: createInjectionComponent(),
  props: true,
  meta: {
    permissions: ['manage.asset-modules'],
    header: {
      label: 'Module',
      route: 'admin.modules.index',
      menu: (route) => [
        {
          label: 'Adauga modul',
          icon: 'add',
          separated: true,
          route: {
            name: 'admin.modules.create',
            params: route.params,
          },
          permissions: ['manage.asset-modules'],
        },
      ],
    },
    breadcrumb: {
      label: 'Module',
      route: 'admin.modules.index',
    },
  },
  children: [
    {
      path: '',
      component: imp(
        () => import('@/pages/admin/assets/modules/index/Main.vue' /* webpackChunkName: 'assets.modules' */),
      ),
      children: [
        {
          path: '',
          name: 'admin.modules.index',
          component: imp(
            () => import('@/pages/admin/assets/modules/index/List.vue' /* webpackChunkName: 'assets.modules' */),
          ),
          meta: {
            permissions: ['manage.asset-modules'],
          },
          children: [
            ...makeTreeRoutes({
              owner: 'assets-modules',
              permissionsRoot: 'asset-modules',
              labels: {
                create: 'Adauga modul',
                rename: 'Redenumeste modul',
              },
              routes: {
                root: 'admin.modules',
                success: 'admin.modules.nodes',
                close: 'admin.modules.index',
              },
            }),
          ],
        },
      ],
    },
    categories,
  ],
};

export default route;
