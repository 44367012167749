import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const routes: UvRoute = {
  path: 'map',
  props: true,
  component: imp(() => import('@/pages/assets/modules/map/Main.vue' /* webpackChunkName: 'assets.map' */)),
  children: [
    {
      path: '',
      props: true,
      name: 'assets.map.show',
      meta: {
        header: {
          label: 'Harta GIS',
        },
        breadcrumb: {
          label: 'Harta GIS',
          route: 'assets.map.show',
        },
        wide: ({ fullscreen }) => fullscreen,
      },
      component: imp(() => import('@/pages/assets/modules/map/Show.vue' /* webpackChunkName: 'assets.map' */)),
      children: [
        {
          path: 'products/:productId/areas/:areaId/inventory/items',
          props: true,
          name: 'assets.map.products.areas.inventory.items.index',
          component: imp(
            () => import('@/components/map/products/inventory/InventoryList.vue' /* webpackChunkName: 'assets.map' */),
          ),
        },
      ],
    },
  ],
};

export default routes;
