import imp from '@/lib/imp';
import { UvRoute, createInjectionComponent } from '@/lib/router';
import view from '@/pages/inventory/active/committee/view/routes';

const route: UvRoute = {
  path: 'committee',
  component: createInjectionComponent(),
  props: true,
  meta: {
    permissions: ['read.committees'],
    header: {
      parentRoute: 'inventory.active',
      label: 'Comisie',
      menu: (route) => [
        {
          label: 'Adauga membru',
          icon: 'add',
          separated: true,
          route: {
            name: 'inventory.committee.create',
            params: route.params,
          },
        },
      ],
    },
    breadcrumb: {
      route: 'inventory.committee.index',
      label: 'Comisie',
    },
  },
  children: [
    {
      path: '',
      name: 'inventory.committee.index',
      component: imp(
        () => import('@/pages/inventory/active/committee/index/Main.vue' /* webpackChunkName: 'inventory.committee' */),
      ),
      props: true,
      children: [
        {
          path: 'create',
          name: 'inventory.committee.create',
          meta: {
            permissions: ['create.committees'],
          },
          props: true,
          component: imp(
            () =>
              import(
                '@/pages/inventory/active/committee/index/actions/Create.vue' /* webpackChunkName: 'institution.committee' */
              ),
          ),
        },
        view,
      ],
    },
  ],
};

export default route;
