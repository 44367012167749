import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import view from '@/pages/maps/streets/view/routes';
import { CreateElement } from 'vue';

const routes: UvRoute = {
  path: 'streets',
  component: {
    render: (h: CreateElement) => h('router-view'),
  },
  meta: {
    breadcrumb: {
      label: 'Adrese',
      route: 'maps.streets.index',
    },
    header: {
      label: 'Adrese',
      menu: () => [
        {
          label: 'Adauga strada',
          icon: 'add',
          route: {
            name: 'maps.streets.create',
          },
          permissions: ['create.maps'],
        },
      ],
    },
  },
  children: [
    {
      path: '',
      props: true,
      name: 'maps.streets.index',
      component: imp(() => import('@/pages/maps/streets/Main.vue' /* webpackChunkName: 'maps.streets.index' */)),
      children: [
        {
          path: 'create',
          name: 'maps.streets.create',
          component: imp(() => import('@/pages/maps/streets/Create.vue' /* webpackChunkName: 'maps.streets.create' */)),
        },
      ],
    },
    view,
  ],
};

export default routes;
