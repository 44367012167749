import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import location from '@/pages/assets/modules/products/view/edit/location/routes';

const routes: UvRoute[] = [
  {
    path: 'edit/labels',
    name: 'assets.products.edit.labels',
    props: true,
    meta: {
      permissions: ['update.products'],
    },
    component: imp(
      () =>
        import('@/pages/assets/modules/products/view/edit/Labels.vue' /* webpackChunkName: 'assets.products.edit' */),
    ),
  },
  {
    path: 'edit/stocks',
    name: 'assets.products.edit.stocks',
    props: true,
    meta: {
      permissions: ['update.products'],
    },
    component: imp(
      () =>
        import('@/pages/assets/modules/products/view/edit/Stocks.vue' /* webpackChunkName: 'assets.products.edit' */),
    ),
  },
  location,
  {
    path: 'edit/fields',
    name: 'assets.products.edit.fields',
    props: true,
    component: imp(
      () =>
        import(
          '@/pages/assets/modules/products/view/edit/fields/Main.vue' /* webpackChunkName: 'assets.products.edit' */
        ),
    ),
    meta: {
      permissions: ['update.products'],
    },
  },
  {
    path: 'edit/supplier',
    name: 'assets.products.edit.supplier',
    component: imp(
      () =>
        import('@/pages/assets/modules/products/view/edit/Supplier.vue' /* webpackChunkName: 'assets.products.edit' */),
    ),
    meta: {
      permissions: ['update.products'],
    },
  },
  {
    path: 'edit/dates',
    name: 'assets.products.edit.dates',
    component: imp(
      () =>
        import('@/pages/assets/modules/products/view/edit/Dates.vue' /* webpackChunkName: 'assets.products.edit' */),
    ),
    meta: {
      permissions: ['update.products'],
    },
  },
  {
    path: 'edit/warranty',
    name: 'assets.products.edit.warranty',
    component: imp(
      () =>
        import('@/pages/assets/modules/products/view/edit/Warranty.vue' /* webpackChunkName: 'assets.products.edit' */),
    ),
    meta: {
      permissions: ['update.products'],
    },
  },
  {
    path: 'edit/management',
    name: 'assets.products.edit.management',
    component: imp(
      () =>
        import(
          '@/pages/assets/modules/products/view/edit/Management.vue' /* webpackChunkName: 'assets.products.edit' */
        ),
    ),
    meta: {
      permissions: ['update.products'],
    },
  },
];

export default routes;
