// import { importStatus } from '@/lib/inventory-import';
// import { InventoryImportSource, ScripticItemType } from '@/pages/inventory/Inventory';

import { UvRouteHeader } from '@/lib/router';

const header: UvRouteHeader = {
  route: 'inventory.active',
  label: 'Inventar activ',
  menu: (
    route,
    {
      inventory,
      downloadQrCodes,
      resetInventory,
    }: { inventory: any; downloadQrCodes: () => Promise<string>; resetInventory: () => Promise<void> },
  ) =>
    inventory
      ? [
          {
            label: 'Scaneaza',
            icon: 'fullscreen',
            color: 'success',
            route: {
              name: 'inventory.active.scan.select-node',
              params: route.params,
            },
          },
          {
            isDivider: true,
          },
          {
            label: 'Sabloane tiparire',
            icon: 'print',
            route: {
              name: 'inventory.active.print-templates',
              params: route.params,
            },
          },
          {
            label: 'Tiparire etichete',
            icon: 'print',
            route: {
              name: 'inventory.active.print',
              params: route.params,
            },
            disabled: true,
          },
          {
            isDivider: true,
          },
          {
            label: 'Organigrama',
            icon: 'edit',
            route: {
              name: 'inventory.active.org-chart',
              params: route.params,
            },
          },
          {
            label: 'Comisie',
            icon: 'people_alt',
            permissions: ['read.committees'],
            route: {
              name: 'inventory.committee.index',
              params: route.params,
            },
          },
          {
            label: 'Modifica',
            icon: 'edit',
            route: {
              name: 'inventory.active.edit',
              params: route.params,
            },
          },
          {
            disabled: () => {
              return true;
              // const status = importStatus(inventory);
              // if (status[ScripticItemType.MF].enabled) {
              //   return status[ScripticItemType.MF].sourceEnabled(InventoryImportSource.INITIAL);
              // }
              // if (status[ScripticItemType.OI].enabled) {
              //   return status[ScripticItemType.OI].sourceEnabled(InventoryImportSource.INITIAL);
              // }
            },
            label: 'Adauga reper',
            icon: 'create',
            route: {
              name: 'inventory.scriptic.create',
              params: route.params,
            },
          },
          {
            isDivider: true,
          },
          {
            label: 'Descarca etichete',
            icon: 'cloud_download',
            click: downloadQrCodes,
          },
          {
            isDivider: true,
          },
          {
            label: 'Reseteaza inventar',
            icon: 'restart_alt',
            click: resetInventory,
            permissions: ['manage.inventories'],
          },
        ]
      : [],
};

export default header;
