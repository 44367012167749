import { pointInPolygon as pip } from 'geojson-utils-ts';
import { LatLng } from 'leaflet';

export const radiansToDegrees = (radians: number) => (radians * 180) / Math.PI;
export const degreesToRadians = (degrees: number) => (degrees * Math.PI) / 180;

export const vectorToCoordinate = ([x, y, z]: [x: number, y: number, z: number]) => [
  Math.atan2(z, Math.sqrt(x * x + y * y)),
  Math.atan2(y, x),
];

export const coordinateToVector = ([lat, lng]: [lat: number, lng: number]) => [
  Math.cos(lat) * Math.cos(lng),
  Math.cos(lat) * Math.sin(lng),
  Math.sin(lat),
];

export const calculateVectorsCenter = (vectors: any) =>
  vectors
    .reduce(
      ([X, Y, Z]: [x: number, y: number, z: number], [x, y, z]: [x: number, y: number, z: number]) => [
        X + x,
        Y + y,
        Z + z,
      ],
      [0.0, 0.0, 0.0],
    )
    .map((c: any) => c / vectors.length);

export const convertToBasic = (coordinates: any) =>
  coordinates.reduce((acc: any, { lat, lng }: any) => [...acc, [lat, lng]], []);

export const closePolygon = (polygon: any) => (polygon.length >= 3 ? [...polygon, polygon[0]] : polygon);

export const getPolygonCenter = (coordinates: any) =>
  vectorToCoordinate(
    calculateVectorsCenter(
      convertToBasic(coordinates).map((coordinates: any) => coordinateToVector(coordinates.map(degreesToRadians))),
    ),
  ).map(radiansToDegrees);

export const bounds = {
  parse: (s: any) =>
    s
      .split(';')
      .map((pair: any) =>
        pair
          .split(',')
          .filter((a: any) => !!a)
          .map(parseFloat),
      )
      .filter((pair: any) => pair.length === 2 && pair.every((a: any) => !isNaN(a))),
  stringify: (coordinates: any) => coordinates.map((c: any) => c.join(', ')).join('; '),
};

export const pointInPolygon = (point: any, bounds: any) =>
  pip(
    {
      type: 'Point',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      coordinates: Object.values(point).reverse(),
    },
    {
      type: 'Polygon',
      coordinates: [bounds.map((latlng: any) => Object.values(latlng).reverse())],
    },
  );

export const toCircle = (circle: any) => ({
  type: 'Feature',
  properties: {
    radius: circle.radius,
  },
  geometry: {
    type: 'Point',
    coordinates: Object.values(circle.latlng).slice().reverse(),
  },
});

export const fromCircle = (o: any) => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  latlng: new LatLng(...o.geometry.coordinates.slice().reverse()),
  radius: o.properties.radius,
});

const toPolyCoordinates = (latlngs: any) => {
  const [first, ...rest] = latlngs.map((latlng: any) => Object.values(latlng).slice().reverse());

  return [[first, ...rest, first]];
};

export const toPolygon = (areaId: any, latlngs: any) => ({
  _id: areaId,
  type: 'Polygon',
  coordinates: toPolyCoordinates(latlngs),
});

const fromPolyCoordinates = (coords: any) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  coords.map((coordinate: any) => new LatLng(...coordinate.slice().reverse()));

export const fromPolygon = (o: any) => fromPolyCoordinates(o.coordinates[0]);

export const toMultiPolygon = (areaId: any, multilatlngs: any) => ({
  _id: areaId,
  type: 'MultiPolygon',
  coordinates: multilatlngs.map((latlngs: any) => toPolyCoordinates(latlngs)),
});

export const fromMultiPolygon = (o: any) => o.coordinates.map((polygon: any) => fromPolyCoordinates(polygon[0]));

export const toPoint = (areaId: any, latlng: any) => ({
  _id: areaId,
  type: 'Point',
  coordinates: Object.values(latlng).reverse(),
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const fromPoint = (o: any) => new LatLng(...o.coordinates.slice().reverse());

export const toMultiPoint = (areaId: any, latlngs: any) => ({
  _id: areaId,
  type: 'MultiPoint',
  coordinates: latlngs.map((latlng: any) => Object.values(latlng).slice().reverse()),
});

export const fromMultiPoint = (o: any) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  o.coordinates.map((coordinate: any) => new LatLng(...coordinate.slice().reverse()));

export const fromGeometryCollection = (g: any) =>
  g.geometries.map(({ _id, type, coordinates }: { _id: any; type: any; coordinates: any }) => ({
    _id,
    type,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    coordinates: types[type].from({ coordinates }),
  }));

export const toGeometryCollection = (g: any) => ({
  type: 'GeometryCollection',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  geometries: g.map((shape: any) => types[shape.type].to(shape._id, shape.coordinates)),
});

export const toLineString = (areaId: any, latlngs: any) => ({
  _id: areaId,
  type: 'LineString',
  coordinates: latlngs.map((latlng: any) => Object.values(latlng).slice().reverse()),
});

export const fromLineString = (o: any) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  o.coordinates.map((coordinate: any) => new LatLng(...coordinate.slice().reverse()));

const types = {
  Polygon: {
    to: toPolygon,
    from: fromPolygon,
  },
  MultiPolygon: {
    to: toMultiPolygon,
    from: fromMultiPolygon,
  },
  Point: {
    to: toPoint,
    from: fromPoint,
  },
  MultiPoint: {
    to: toMultiPoint,
    from: fromMultiPoint,
  },
  GeometryCollection: {
    to: toGeometryCollection,
    from: fromGeometryCollection,
  },
  LineString: {
    to: toLineString,
    from: fromLineString,
  },
};

export const geoJSONConverter = (type: any) => {
  if (!types.hasOwnProperty(type)) {
    throw new Error(`Unknown type ${type}.`);
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return types[type];
};
