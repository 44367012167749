import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: 'confirm/:itemId',
  props: true,
  component: imp(
    () => import('@/pages/inventory/active/scan/confirm/Main.vue' /* webpackChunkName: 'inventory.active.scan' */),
  ),
  meta: {
    header: {
      label: 'Confirma',
      route: 'inventory.active.scan.confirm',
    },
  },
  children: [
    {
      path: '',
      name: 'inventory.active.scan.confirm',
      component: imp(
        () =>
          import('@/pages/inventory/active/scan/confirm/Confirm.vue' /* webpackChunkName: 'inventory.active.scan' */),
      ),
    },
    // {
    //   path: 'photos',
    //   name: 'active-inventory.confirm.photos',
    //   component: imp(() => import('@/pages/inventory/active/scan/confirm/ScripticItemPhotos.vue' /* webpackChunkName: 'inventory.active.scan' */))
    // }
  ],
};

export default route;
