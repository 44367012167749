import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

import maintenances from '@/pages/assets/modules/view/reports/maintenances/routes';

const route: UvRoute = {
  path: '',
  props: true,
  component: imp(() => import('@/pages/assets/modules/view/reports/Main.vue' /* webpackChunkName: 'assets.reports' */)),
  meta: {
    permissions: ['read.reports'],
    breadcrumb: {
      label: 'Rapoarte',
      route: 'assets.modules.reports',
    },
    header: {
      label: 'Rapoarte',
      route: 'assets.modules.reports',
    },
  },
  children: [
    {
      path: 'reports',
      name: 'assets.modules.reports',
      component: imp(
        () => import('@/pages/assets/modules/view/reports/List.vue' /* webpackChunkName: 'assets.reports' */),
      ),
    },
    ...maintenances,
    {
      path: 'products/general',
      name: 'assets.modules.reports.products',
      props: true,
      meta: {
        permissions: ['read.products'],
        header: {
          label: 'Raport produse',
          parentRoute: 'assets.modules.reports',
        },
      },
      component: imp(
        () =>
          import(
            '@/pages/assets/modules/view/reports/products/Main.vue' /* webpackChunkName: 'assets.reports.products' */
          ),
      ),
    },
    {
      path: 'products/dynamic',
      name: 'assets.modules.reports.dynamic.list',
      props: true,
      meta: {
        permissions: ['read.products'],
        header: {
          label: 'Lista rapoarte dinamice produse',
          parentRoute: 'assets.modules.reports',
        },
      },
      component: imp(
        () =>
          import(
            '@/pages/assets/modules/view/reports/dynamic/List.vue' /* webpackChunkName: 'assets.reports.products' */
          ),
      ),
    },
    {
      path: 'products/dynamic/:reportId',
      name: 'assets.modules.reports.dynamic.show',
      props: true,
      meta: {
        permissions: ['read.products'],
        breadcrumb: {
          label: ({ productReport }) => 'Raport dinamic ' + (productReport ? productReport.name : ''),
          parentRoute: 'assets.modules.reports.dynamic.list',
        },
        header: {
          label: ({ productReport }) => 'Raport dinamic ' + (productReport ? productReport.name : ''),
          parentRoute: 'assets.modules.reports.dynamic.list',
        },
      },
      component: imp(
        () =>
          import(
            '@/pages/assets/modules/view/reports/dynamic/Main.vue' /* webpackChunkName: 'assets.reports.products' */
          ),
      ),
    },
    {
      path: 'services/general',
      name: 'assets.modules.reports.services',
      props: true,
      meta: {
        permissions: ['read.services'],
        header: {
          label: 'Raport servicii',
          parentRoute: 'assets.modules.reports',
        },
      },
      component: imp(
        () =>
          import(
            '@/pages/assets/modules/view/reports/services/Main.vue' /* webpackChunkName: 'assets.reports.products' */
          ),
      ),
    },
  ],
};

export default route;
