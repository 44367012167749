import tree from '@/components/tree-nodes/routes';
import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: 'org-chart',
  component: imp(
    () => import('@/pages/inventory/active/org-chart/Main.vue' /* webpackChunkName: 'inventory.active.org-chart' */),
  ),
  meta: {
    breadcrumb: {
      label: 'Organigrama',
      route: 'inventory.active.org-chart',
    },
    header: {
      label: 'Organigrama',
      route: 'inventory.active',
    },
  },
  children: [
    tree({
      label: 'Organigrama',
      namespace: 'orgChart',
      permissionsRoot: 'org-charts',
      rootRoute: 'inventory.active.org-chart',
    }),
  ],
};

export default route;
