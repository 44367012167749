import imp from '@/lib/imp';
import actions from '@/pages/assets/modules/maintenances/actions/routes';
import view from '@/pages/assets/modules/maintenances/view/routes';
import { createInjectionComponent, UvRoute, UvRouteHeader } from '@/lib/router';

const header: UvRouteHeader = {
  label: 'Mentenante',
  route: 'assets.maintenances.index',
  menu: () => [
    {
      label: 'Adauga mentenanta',
      icon: 'add',
      separated: true,
      route: {
        name: 'assets.maintenances.create',
      },
      permissions: ['create.maintenances'],
    },
  ],
};

const route: UvRoute = {
  path: 'maintenances',
  props: true,
  component: createInjectionComponent(),
  meta: {
    breadcrumb: {
      label: 'Mentenante',
      route: 'assets.maintenances.index',
    },
    header,
  },
  children: [
    {
      path: '',
      name: 'assets.maintenances.index',
      props: true,
      meta: {
        permissions: ['list.maintenances'],
      },
      component: imp(
        () => import('@/pages/assets/modules/maintenances/List.vue' /* webpackChunkName: 'assets.maintenances.main' */),
      ),
      children: actions,
    },
    view,
  ],
};

export default route;
