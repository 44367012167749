import { createInjectionComponent, UvRoute, UvRouteHeader } from '@/lib/router';
import index from '@/pages/assets/modules/products/view/maintenances/index/routes';
import view from '@/pages/assets/modules/products/view/maintenances/view/routes';

const header: UvRouteHeader = {
  label: 'Mentenante',
  route: 'assets.products.maintenances.index',
  menu: () => [
    {
      label: 'Adauga',
      icon: 'add',
      route: {
        name: 'assets.products.maintenances.assign',
      },
      permissions: ['update.products'],
    },
  ],
};

const route: UvRoute = {
  path: 'maintenances',
  component: createInjectionComponent(),
  meta: {
    breadcrumb: {
      label: 'Mentenante',
      route: 'assets.products.maintenances.index',
    },
    header,
  },
  children: [index, view],
};

export default route;
