import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: '/',
  props: true,
  name: 'admin.field-groups.index',
  component: imp(
    () => import('@/pages/admin/field-groups/index/Main.vue' /* webpackChunkName: 'admin.field-groups.index' */),
  ),
  children: [
    {
      path: 'create',
      props: true,
      name: 'admin.field-groups.create',
      component: imp(
        () =>
          import(
            '@/pages/admin/field-groups/index/actions/Create.vue' /* webpackChunkName: 'admin.field-groups.index' */
          ),
      ),
    },
  ],
};

export default route;
