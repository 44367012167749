import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: 'edit/location',
  name: 'assets.products.edit.location',
  meta: {
    permissions: ['update.products'],
  },
  component: imp(
    () =>
      import(
        '@/pages/assets/modules/products/view/edit/location/Main.vue' /* webpackChunkName: 'assets.products.edit' */
      ),
  ),
  children: [
    {
      path: 'inventory/items',
      name: 'assets.products.edit.location.inventory.items',
      meta: {
        permissions: ['update.products'],
      },
      component: imp(
        () =>
          import(
            '@/pages/assets/modules/products/view/edit/location/inventory/Items.vue' /* webpackChunkName: 'assets.products.edit' */
          ),
      ),
    },
    {
      path: 'inventory/quantity',
      name: 'assets.products.edit.location.inventory.quantity',
      meta: {
        permissions: ['update.products'],
      },
      component: imp(
        () =>
          import(
            '@/pages/assets/modules/products/view/edit/location/inventory/Quantity.vue' /* webpackChunkName: 'assets.products.edit' */
          ),
      ),
    },
  ],
};

export default route;
