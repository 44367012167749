import imp from '@/lib/imp';
import { UvRoute, createInjectionComponent } from '@/lib/router';

const route: UvRoute = {
  path: '',
  props: true,
  component: createInjectionComponent(),
  meta: {
    permissions: ['create.inventories'],
  },
  children: [
    {
      path: 'create',
      props: true,
      name: 'inventory.create',
      component: imp(() => import('@/pages/inventory/create/Create.vue' /* webpackChunkName: 'inventory.create' */)),
      meta: {
        breadcrumb: {
          label: 'Inventar nou',
        },
        header: {
          label: 'Inventar nou',
        },
      },
    },
    {
      path: 'next',
      props: true,
      name: 'inventory.next',
      component: imp(() => import('@/pages/inventory/create/Next.vue' /* webpackChunkName: 'inventory.create' */)),
      meta: {
        breadcrumb: {
          label: 'Inventar urmator',
        },
        header: {
          label: 'Inventar urmator',
        },
      },
    },
  ],
};

export default route;
