import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: ':flowId',
  props: true,
  component: imp(
    () => import('@/pages/assets/modules/flows/view/Main.vue' /* webpackChunkName: 'assets.flows.main' */),
  ),
  meta: {
    header: {
      label: ({ flow }) => (flow ? `Vizualizare flux "${flow.name}"` : 'Vizualizare flux'),
      route: 'assets.flows.show',
    },
    breadcrumb: {
      label: ({ flow }) => (flow ? flow.name : 'Flux'),
      route: 'assets.flows.show',
    },
  },
  children: [
    {
      path: '',
      name: 'assets.flows.show',
      props: true,
      component: imp(
        () => import('@/pages/assets/modules/flows/view/Show.vue' /* webpackChunkName: 'assets.flows.main' */),
      ),
      children: [
        {
          path: 'activate',
          name: 'assets.flows.activate',
          props: true,
          meta: {
            permissions: ['update.flows'],
          },
          component: imp(
            () => import('@/pages/assets/modules/flows/view/Activate.vue' /* webpackChunkName: 'assets.flows.main' */),
          ),
        },
        {
          path: 'edit/name',
          name: 'assets.flows.edit.name',
          props: true,
          meta: {
            permissions: ['update.flows'],
          },
          component: imp(
            () => import('@/pages/assets/modules/flows/view/edit/Name.vue' /* webpackChunkName: 'assets.flows.main' */),
          ),
        },
        {
          path: 'items/create',
          name: 'assets.flows.checklist.create',
          props: true,
          meta: {
            permissions: ['create.flows'],
          },
          component: imp(
            () =>
              import(
                '@/pages/assets/modules/flows/view/checklist/Create.vue' /* webpackChunkName: 'assets.flows.items' */
              ),
          ),
        },
        {
          path: 'items/:itemId/rename',
          name: 'assets.flows.checklist.rename',
          props: true,
          meta: {
            permissions: ['update.flows'],
          },
          component: imp(
            () =>
              import(
                '@/pages/assets/modules/flows/view/checklist/Rename.vue' /* webpackChunkName: 'assets.flows.items' */
              ),
          ),
        },
      ],
    },
  ],
};

export default route;
