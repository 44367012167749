import imp from '@/lib/imp';
import { createInjectionComponent, UvRoute } from '@/lib/router';
import edit from '@/pages/assets/modules/products/view/edit/routes';
import maintenances from '@/pages/assets/modules/products/view/maintenances/routes';
import flows from '@/pages/assets/modules/products/view/flows/routes';
import inventory from '@/pages/assets/modules/products/view/inventory/routes';
import { UvProductKey } from '@/pages/assets/modules/definitions';

const routes: UvRoute = {
  path: ':productId',
  props: true,
  meta: {
    header: {
      label: ({ product }) => (product ? `Vizualizare produs "${product.name}"` : 'Vizualizare produs'),
      route: 'assets.products.show',
    },
    breadcrumb: {
      label: ({ product }) => (product ? product.name : 'Produs'),
      route: 'assets.products.show',
    },
    permissions: ['read.products'],
  },
  component: imp(
    () => import('@/pages/assets/modules/products/view/Main.vue' /* webpackChunkName: 'assets.products.main' */),
  ),
  children: [
    {
      path: '',
      name: 'assets.products.show',
      props: true,
      component: imp(
        () => import('@/pages/assets/modules/products/view/Show.vue' /* webpackChunkName: 'assets.products.main' */),
      ),
      children: [
        {
          path: 'maintenances/:maintenanceId/delete',
          name: 'assets.products.maintenances.delete',
          props: true,
          component: createInjectionComponent(),
        },
        ...edit,
      ],
    },
    {
      path: '',
      props: true,
      component: createInjectionComponent(['product', [UvProductKey, 'product']]),
      children: [maintenances, flows, inventory],
    },
  ],
};
export default routes;
