import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import active from '@/pages/inventory/active/routes';
import create from '@/pages/inventory/create/routes';
import history from '@/pages/inventory/history/routes';

const route: UvRoute = {
  path: 'inventory',
  props: true,
  component: imp(() => import('@/pages/inventory/Main.vue' /* webpackChunkName: 'inventory' */)),
  meta: {
    header: {
      label: 'Invent',
      route: 'inventory',
    },
    breadcrumb: {
      label: 'Invent',
      route: 'inventory',
    },
  },
  children: [
    {
      path: '',
      component: imp(() => import('@/pages/inventory/Actions.vue' /* webpackChunkName: 'inventory' */)),
      name: 'inventory',
      props: true,
    },
    create,
    active,
    history,
  ],
};

export default route;
