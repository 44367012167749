import imp from '@/lib/imp';
import { UvRoute, UvRouteHeader } from '@/lib/router';

const header: UvRouteHeader = {
  label: ({ user }) => (user ? `Roluri utilizator "${user}"` : 'Roluri utilizator'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  menu: (route, { institution }) => [
    {
      label: 'Alege aplicatii',
      icon: 'app_registration',
      separated: true,
      route: institution
        ? {
            name: 'admin.institutions.applications',
            params: { institutionId: institution._id },
          }
        : { name: 'home' },
    },
  ],
};

const route: UvRoute = {
  path: 'edit/roles',
  name: 'admin.users.edit.roles',
  props: true,
  meta: {
    permissions: ['manage.users'],
    header,
    breadcrumb: {
      label: ({ user }) => user || 'Utilizator',
    },
  },
  component: imp(() => import('@/pages/admin/users/view/roles/Main.vue' /* webpackChunkName: 'admin.users.edit' */)),
};

export default route;
