import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const routes: UvRoute[] = [
  {
    path: 'reports/maintenances/expiring',
    name: 'assets.modules.reports.maintenances.expiring',
    props: true,
    meta: {
      header: {
        label: 'Raport mentenante expirate',
        parentRoute: 'assets.modules.reports',
      },
    },
    component: imp(
      () =>
        import(
          '@/pages/assets/modules/view/reports/maintenances/expiring/Main.vue' /* webpackChunkName: 'assets.reports.products' */
        ),
    ),
  },
  {
    path: 'reports/maintenances/pending',
    name: 'assets.modules.reports.maintenances.pending',
    props: true,
    meta: {
      wide: true,
      header: {
        label: 'Raport mentenante cu flux finalizat dar nepornite',
        parentRoute: 'assets.modules.reports',
      },
    },
    component: imp(
      () =>
        import(
          '@/pages/assets/modules/view/reports/maintenances/pending/Main.vue' /* webpackChunkName: 'assets.reports.products' */
        ),
    ),
  },
  {
    path: 'reports/maintenances/progressing',
    name: 'assets.modules.reports.maintenances.progressing',
    props: true,
    meta: {
      wide: true,
      header: {
        label: 'Raport mentenante neincepute in diverse etape',
        parentRoute: 'assets.modules.reports',
      },
    },
    component: imp(
      () =>
        import(
          '@/pages/assets/modules/view/reports/maintenances/progressing/Main.vue' /* webpackChunkName: 'assets.reports.products' */
        ),
    ),
  },
];

export default routes;
