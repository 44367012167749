import { PluginObject } from 'vue';
import { Endpoint } from '@/lib/api-builder';
import api from '@/api';

interface DownloadPluginOptions {
  api: Endpoint;
  params?: Record<string, unknown>;
}

export interface DownloadMaker {
  (options: DownloadPluginOptions): Promise<void>;
}

export const requestToken = (): Promise<string> => {
  return api.access
    .token()
    .then((response) =>
      response.json().then((payload) => (response.ok ? payload.data.token : Promise.reject(new Error(payload.error)))),
    );
};

export const makeDownload: DownloadMaker = async ({ api, params }) => {
  const container = document.querySelector('[data-app=true]') || document.body;
  const token = await requestToken();

  const url = api.url({
    ...(params || {}),
    token,
  });

  const anchor = document.createElement('a');

  anchor.type = 'hidden';
  anchor.target = '_blank';
  anchor.href = url;
  container.appendChild(anchor);
  anchor.click();
  anchor.remove();
};

export const plugin: PluginObject<DownloadPluginOptions> = {
  install(Vue) {
    Vue.prototype.$download = function (options: DownloadPluginOptions) {
      return makeDownload(options);
    };
  },
};

declare module 'vue/types/vue' {
  interface Vue {
    $download: DownloadMaker;
  }
}
