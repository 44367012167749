import imp from '@/lib/imp';
import { createInjectionComponent, UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: '/',
  meta: {
    wide: true,
  },
  component: createInjectionComponent(),
  children: [
    {
      path: '',
      name: 'home',
      meta: {
        wide: true,
      },
      component: imp(() => import('@/pages/home/Default.vue' /* webpackChunkName: 'home' */)),
    },
  ],
};

export default route;
