import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: '',
  name: 'institution.suppliers.index',
  meta: {
    breadcrumb: {
      label: 'Furnizori',
      route: 'institution.suppliers.index',
    },
  },
  component: imp(
    () => import('@/pages/institution/suppliers/index/Main.vue' /* webpackChunkName: 'institution.suppliers.index' */),
  ),
  children: [
    {
      path: 'create',
      name: 'institution.suppliers.create',
      meta: {
        permissions: ['create.suppliers'],
      },
      component: imp(
        () =>
          import(
            '@/pages/institution/suppliers/index/actions/Create.vue' /* webpackChunkName: 'institution.suppliers.index' */
          ),
      ),
    },
  ],
};

export default route;
