import index from '@/pages/assets/modules/products/view/flows/index/routes';
import view from '@/pages/assets/modules/products/view/flows/view/routes';
import { createInjectionComponent, UvRoute } from '@/lib/router';

const routes: UvRoute = {
  path: 'flows',
  component: createInjectionComponent(),
  meta: {
    breadcrumb: {
      label: 'Fluxuri',
      route: 'assets.products.flows.index',
    },
    header: {
      label: 'Fluxuri',
      route: 'assets.products.flows.index',
    },
  },
  children: [index, view],
};

export default routes;
