import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';
import config from '@/config';

export class PermissionNotGrantedError extends Error {
  constructor(message?: string) {
    super(message);
    this.message = message || 'Permission error';
    this.name = 'PermissionNotGrantedError';
  }
}

export class FirebaseError extends Error {
  constructor(message?: string) {
    super(message);
    this.message = message || 'Firebase error';
    this.name = 'FirebaseError';
  }
}

export class IgnorableFirebaseError extends Error {}

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

export class NotificationApiNotSupported extends Error {
  constructor() {
    super('Notification API not supported');
    this.name = 'NotificationApiNotSupported';
  }
}

export class NotificationsNotSupportedOnSafariBrowser extends Error {
  constructor() {
    super('Notifications not supported on Safari browser');
    this.name = 'NotificationsNotSupportedOnSafariBrowser';
  }
}

export const activateNotifications = () => {
  if (!('Notification' in window)) {
    return Promise.reject(new NotificationApiNotSupported());
  }
  if (window.hasOwnProperty('safari')) {
    return Promise.reject(new NotificationsNotSupportedOnSafariBrowser());
  }
  return Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app);
      getAnalytics(app);
      if (config.debug) {
        onMessage(messaging, (payload: unknown) => {
          // console.log({ payload });
        });
      }
      return getToken(messaging, { vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY })
        .then((currentToken: string | undefined) => {
          if (currentToken) {
            if (config.debug) {
              // console.log(`Your firebase token is: ${currentToken}`);
            }
            return currentToken;
          } else {
            throw new FirebaseError('No token found');
          }
        })
        .catch((err: Error) => {
          if (err.message.indexOf('no active Service Worker') !== -1) {
            throw new IgnorableFirebaseError(err.message);
          }
          throw new FirebaseError(err.message);
        });
    } else {
      throw new PermissionNotGrantedError();
    }
  });
};
