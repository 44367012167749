<template>
  <v-list>
    <v-list-item disabled>
      <v-list-item-title>Limite</v-list-item-title>
    </v-list-item>
    <v-list-item
      v-for="(limit, k) in state.limits"
      :key="k"
      style="min-height: 30px"
    >
      <v-list-item-title>{{ limit.label }}</v-list-item-title>
      <v-progress-linear
        :value="limit.percentage.value"
        :color="limit.color.value"
        height="25"
      >
        <template>
          <div style="font-size: 12px">
            {{ limit.transformFn(limit.current) }} / {{ limit.maximum === 0 ? '∞' : limit.transformFn(limit.maximum) }}
          </div>
        </template>
      </v-progress-linear>
    </v-list-item>
  </v-list>
</template>
<script lang="ts">
import { computed, ComputedRef, defineComponent, reactive, UnwrapRef } from 'vue';
import { injectStrict } from '@/lib/inject';
import { StoreKey } from '@/symbols';
import { Institution } from '@/store/userInstitution';
import { bytesToMegaBytes } from '@/lib/tools';
import { identity } from 'rambda';
import { bus } from '@/main';

interface IState {
  loading: ComputedRef<boolean>;
  error: ComputedRef<Error | null>;
  institution: ComputedRef<Institution | null>;
  limits: ComputedRef<
    {
      label: string;
      current: number;
      maximum: number;
      transformFn?: (v: number) => unknown;
      percentage: ComputedRef<number>;
      color: ComputedRef<string>;
    }[]
  >;
}

export default defineComponent({
  setup() {
    const $store = injectStrict(StoreKey);
    const state: UnwrapRef<IState> = reactive<IState>({
      loading: computed(() => $store.state.userInstitution.loading),
      error: computed(() => $store.state.userInstitution.error),
      institution: computed(() => $store.state.userInstitution.institution),
      limits: computed(() =>
        [
          {
            label: 'Spatiu (MB)',
            current: state.institution ? state.institution.limits.general.space.current : 0,
            maximum: state.institution ? state.institution.limits.general.space.maximum : 0,
            transformFn: bytesToMegaBytes,
          },
          {
            label: 'Utilizatori',
            current: state.institution ? state.institution.limits.general.users.current : 0,
            maximum: state.institution ? state.institution.limits.general.users.maximum : 0,
          },
          {
            label: 'Scriptic',
            current: state.institution ? state.institution.limits.applications.inventory.scripticItems.current : 0,
            maximum: state.institution ? state.institution.limits.applications.inventory.scripticItems.maximum : 0,
            transformFn: (v: number) => v.toFixed(2),
          },
        ].map((limit) => ({
          ...limit,
          transformFn: limit.transformFn || identity,
          percentage: computed(() => {
            if (limit.current === 0 || limit.maximum === 0) return 0;
            return (limit.current * 100) / limit.maximum;
          }),
          color: computed(() => {
            if (limit.current === 0 || limit.maximum === 0) return 'green';
            const ratio = (limit.current * 100) / limit.maximum;
            if (ratio <= 50) return 'green';
            if (ratio > 50 && ratio <= 75) return 'orange';
            return 'red';
          }),
        })),
      ),
    });
    const events = [
      'scriptic-item:confirm',
      'scriptic-item.photo:uploaded',
      'scriptic-item-update:confirm',
      'reception.quantity:update',
      'user:create',
      'user:associate-institution',
    ];
    bus.$on(events, () => {
      $store.dispatch('userInstitution/load', { force: true });
    });
    return {
      state,
    };
  },
});
</script>
