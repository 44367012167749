import imp from '@/lib/imp';
import view from '@/pages/urbanism/common/areas/view/routes';
import { Endpoint } from '@/lib/api-builder';
import { UvRoute } from '@/lib/router';

interface IConfig {
  route: string;
  loadApi: Endpoint;
  loadApiParams: string[];
  loadBoundsApi: Endpoint;
  loadBoundsApiParams: string[];
  loadAdditionalAreasApi?: Endpoint;
  loadAdditionalAreasApiParams?: string[];
  saveApi: Endpoint;
  saveApiParams: string[];
}

const mapRouter = (config: IConfig) =>
  ({
    path: 'area',
    props: (route) => ({
      ...route.params,
      ...config,
    }),
    component: imp(() => import('@/pages/urbanism/common/areas/Main.vue' /* webpackChunkName: 'global' */)),
    children: [view(config)],
  }) as UvRoute;

export default mapRouter;
