import _Vue, { PluginObject } from 'vue';

const plugin: PluginObject<unknown> = {
  install(Vue: typeof _Vue) {
    Vue.prototype.$msg = async function (this: _Vue, msg: any | Error): Promise<any> {
      await this.$store.dispatch('messages/push', msg);
    };

    Vue.prototype.$err = function (this: _Vue, err: any | Error) {
      this.$msg(err);

      return false;
    };
  },
};

export default plugin;

declare module 'vue/types/vue' {
  interface Vue {
    $msg: (message: any | Error) => Promise<any>;
    $err: (e: Error) => false;
  }
}
