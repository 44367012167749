import loading from '@/components/import/Loading.vue';
import error from '@/components/import/Error.vue';
import { AsyncComponentFactory, AsyncComponentPromise } from 'vue/types/options';

type Loader = () => Promise<typeof import('*.vue')>;

export default (loader: Loader): AsyncComponentFactory =>
  () => ({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    component: loader() as AsyncComponentPromise,
    loading,
    error,
    delay: 400,
    timeout: 10000,
  });
