import tables from '@/config/tables';
import osm from '@/config/osm';
import * as ro from '@/config/ro';

const debug: string | undefined = process.env.VUE_APP_DEBUG;

export default {
  debug: debug && debug.trim().toLowerCase() === 'true',
  mobileAppHost: process.env.VUE_APP_MOBILE_HOST,
  ro,
  tables,
  osm,
};
