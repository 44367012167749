<template>
  <i>
    {{ field.label }}
    <template v-if="field.rules.required">*</template>
  </i>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FieldsLabel',
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
});
</script>
<style lang="scss" scoped>
i {
  font-style: normal;
}
</style>
