import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: '',
  props: true,
  component: imp(() => import('@/pages/assets/modules/index/Main.vue' /* webpackChunkName: 'assets.main' */)),
  children: [
    {
      path: '',
      props: true,
      name: 'assets.modules.index',
      component: imp(() => import('@/pages/assets/modules/index/List.vue' /* webpackChunkName: 'assets.main' */)),
    },
  ],
};

export default route;
