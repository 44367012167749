import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: 'edit/roles',
  name: 'institution.users.edit.roles',
  props: true,
  meta: {
    permissions: ['update.users'],
    header: {
      label: ({ user }) => (user ? `Roluri utilizator "${user}"` : 'Roluri utilizator'),
    },
    breadcrumb: {
      label: ({ user }) => user || 'Utilizator',
    },
  },
  component: imp(() => import('@/pages/institution/users/view/roles/Main.vue' /* webpackChunkName: 'users.edit' */)),
};

export default route;
