import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const routes: UvRoute = {
  path: 'information',
  props: true,
  component: imp(
    () => import('@/pages/incidents/information/Main.vue' /* webpackChunkName: 'incidents.information' */),
  ),
  meta: {
    header: {
      label: 'Informatii',
      route: 'incidents.information',
    },
    breadcrumb: {
      label: 'Informatii',
      route: 'incidents.information',
    },
  },
  children: [
    {
      path: '/',
      props: true,
      name: 'incidents.information',
      component: imp(
        () => import('@/pages/incidents/information/Edit.vue' /* webpackChunkName: 'incidents.information' */),
      ),
    },
  ],
};

export default routes;
