import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import { NodeAction } from '@/components/tree-nodes/nodesManager';

interface TreeRouterOptions {
  owner: string;
  routes: {
    root: string;
    success: string;
    close: string;
  };
  labels: {
    create: string;
    rename: string;
  };
  permissionsRoot: string;
  nodeActions?: NodeAction[];
}

export const makeTreeRoutes = (opts: TreeRouterOptions): UvRoute[] => {
  const { owner, routes, labels, permissionsRoot, nodeActions = [] } = opts;

  const parentRoute = `${routes.root}.index`;

  return [
    {
      path: 'create',
      name: `${routes.root}.create`,
      component: imp(() => import('@/components/tree-nodes/tree/Create.vue' /* webpackChunkName: 'tree' */)),
      meta: {
        permissions: [`${permissionsRoot}.create`],
      },
      props: {
        parentRoute,
        nodeActions,
        labels,
        routes,
        owner,
      },
    },
    {
      path: ':treeId',
      component: imp(() => import('@/components/tree-nodes/tree/edit/Main.vue' /* webpackChunkName: 'tree' */)),
      name: `${routes.root}.show`,
      meta: {
        permissions: [`${permissionsRoot}.read`],
      },
      props: (route) => ({
        treeId: route.params.treeId,
        routes: { parent: parentRoute, ...routes },
      }),
      children: [
        {
          path: 'rename',
          name: `${routes.root}.rename`,
          component: imp(() => import('@/components/tree-nodes/tree/edit/Rename.vue' /* webpackChunkName: 'tree' */)),
          meta: {
            permissions: [`${permissionsRoot}.update`],
          },
          props: {
            parentRoute,
            nodeActions,
            labels,
            owner,
            routes: { parent: parentRoute, ...routes },
          },
        },
      ],
    },
  ];
};
