import imp from '@/lib/imp';
import view from '@/pages/inventory/history/view/routes';
import { createInjectionComponent, UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: 'history',
  component: createInjectionComponent(),
  meta: {
    breadcrumb: {
      label: 'Istoric inventariere',
      route: 'inventory.history',
    },
    header: {
      label: 'Istoric inventariere',
      route: 'inventory.history',
    },
  },
  props: true,
  children: [
    {
      path: '',
      name: 'inventory.history',
      component: imp(() => import('@/pages/inventory/history/Main.vue' /* webpackChunkName: 'inventory.history' */)),
      props: true,
    },
    view,
  ],
};

export default route;
