import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import index from '@/pages/institution/suppliers/index/routes';
import view from '@/pages/institution/suppliers/view/routes';
import unverified from '@/pages/institution/suppliers/unverified/routes';

const route: UvRoute = {
  path: 'suppliers',
  component: imp(
    () => import('@/pages/institution/suppliers/Main.vue' /* webpackChunkName: 'institution.suppliers.index' */),
  ),
  meta: {
    permissions: ['list.suppliers'],
    header: {
      route: 'institution.suppliers.index',
      label: 'Furnizori',
      menu: () => [
        {
          label: 'Adauga furnizor',
          icon: 'add',
          separated: true,
          route: {
            name: 'institution.suppliers.create',
          },
        },
        {
          label: 'Furnizori neverificati',
          icon: 'list',
          separated: true,
          route: {
            name: 'institution.suppliers.unverified',
          },
        },
      ],
    },
  },
  children: [index, unverified, view],
};

export default route;
