import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: 'institutions',
  component: imp(
    () => import('@/pages/admin/institutions/Main.vue' /* webpackChunkName: 'admin.institutions.index' */),
  ),
  meta: {
    permissions: ['manage.institutions'],
    breadcrumb: {
      label: 'Institutii',
      route: 'admin.institutions.index',
    },
    header: {
      route: 'admin.institutions.index',
      label: 'Institutii',
      menu: () => [
        {
          label: 'Adauga institutie',
          icon: 'add',
          separated: true,
          route: {
            name: 'admin.institutions.create',
          },
        },
      ],
    },
  },
  children: [
    {
      path: '',
      meta: {
        permissions: ['manage.institutions'],
      },
      name: 'admin.institutions.index',
      component: imp(
        () => import('@/pages/admin/institutions/index/Main.vue' /* webpackChunkName: 'admin.institutions.index' */),
      ),
      children: [
        {
          path: 'create',
          name: 'admin.institutions.create',
          component: imp(
            () =>
              import(
                '@/pages/admin/institutions/index/actions/Create.vue' /* webpackChunkName: 'admin.institutions.index' */
              ),
          ),
        },
      ],
    },
    {
      path: ':institutionId',
      component: imp(
        () => import('@/pages/admin/institutions/view/Main.vue' /* webpackChunkName: 'admin.institutions.show' */),
      ),
      props: (route) => ({
        institutionId: route.params.institutionId,
      }),
      meta: {
        permissions: ['manage.institutions'],
        breadcrumb: {
          label: ({ institution }) => institution || 'Institutie',
        },
        header: {
          route: 'admin.institutions.index',
          label: ({ institution }) =>
            institution ? `Vizualizare institutie "${institution}"` : 'Vizualizare institutie',
          menu: (route) => [
            {
              label: 'Editeaza',
              icon: 'edit',
              route: {
                name: 'admin.institutions.edit',
                params: route.params,
              },
            },
          ],
        },
      },
      children: [
        {
          path: '',
          name: 'admin.institutions.show',
          component: imp(
            () => import('@/pages/admin/institutions/view/Show.vue' /* webpackChunkName: 'admin.institutions.show' */),
          ),
          children: [
            {
              path: 'applications',
              name: 'admin.institutions.applications',
              meta: {
                permissions: ['manage.institutions'],
              },
              component: imp(
                () =>
                  import(
                    '@/pages/admin/institutions/view/Applications.vue' /* webpackChunkName: 'admin.institutions.edit' */
                  ),
              ),
            },
            {
              path: 'modules',
              name: 'admin.institutions.modules',
              meta: {
                permissions: ['manage.institutions'],
              },
              component: imp(
                () =>
                  import(
                    '@/pages/admin/institutions/view/Modules.vue' /* webpackChunkName: 'admin.institutions.edit' */
                  ),
              ),
            },
            {
              path: 'edit',
              name: 'admin.institutions.edit',
              meta: {
                permissions: ['manage.institutions'],
              },
              component: imp(
                () =>
                  import('@/pages/admin/institutions/view/Edit.vue' /* webpackChunkName: 'admin.institutions.edit' */),
              ),
            },
            {
              path: 'address',
              name: 'admin.institutions.address',
              meta: {
                permissions: ['manage.institutions'],
              },
              component: imp(
                () =>
                  import(
                    '@/pages/admin/institutions/view/Address.vue' /* webpackChunkName: 'admin.institutions.address' */
                  ),
              ),
            },
            {
              path: 'limits',
              name: 'admin.institutions.limits',
              meta: {
                permissions: ['manage.institutions'],
              },
              component: imp(
                () =>
                  import(
                    '@/pages/admin/institutions/view/Limits.vue' /* webpackChunkName: 'admin.institutions.limits' */
                  ),
              ),
            },
            {
              path: 'suspension-date',
              name: 'admin.institutions.suspension-date',
              meta: {
                permissions: ['manage.institutions'],
              },
              component: imp(
                () =>
                  import(
                    '@/pages/admin/institutions/view/SuspensionDate.vue' /* webpackChunkName: 'admin.institutions.suspension-date' */
                  ),
              ),
            },
            {
              path: 'tile-providers',
              name: 'admin.institutions.tile-providers',
              meta: {
                permissions: ['manage.institutions'],
              },
              component: imp(
                () =>
                  import(
                    '@/pages/admin/institutions/view/TileProviders.vue' /* webpackChunkName: 'admin.institutions.tile-providers' */
                  ),
              ),
            },
          ],
        },
      ],
    },
  ],
};

export default route;
