import api from '@/api';
import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import header from '@/pages/institution/suppliers/view/header';

const route: UvRoute = {
  path: ':supplierId',
  meta: {
    permissions: ['read.suppliers'],
  },
  component: imp(
    () => import('@/pages/institution/suppliers/view/Main.vue' /* webpackChunkName: 'institution.suppliers.show' */),
  ),
  props: (route) => ({
    supplierId: route.params.supplierId,
  }),
  children: [
    {
      path: '',
      name: 'institution.suppliers.show',
      component: imp(
        () =>
          import('@/pages/institution/suppliers/view/Show.vue' /* webpackChunkName: 'institution.suppliers.show' */),
      ),
      meta: {
        header,
      },
      props: true,
      children: [
        {
          path: 'edit',
          name: 'institution.suppliers.edit',
          meta: {
            permissions: ['update.suppliers'],
          },
          props: {
            submitFn: api.institution.suppliers.edit.name,
          },
          component: imp(
            () =>
              import(
                '@/pages/institution/suppliers/view/Edit.vue' /* webpackChunkName: 'institution.suppliers.edit' */
              ),
          ),
        },
      ],
    },
  ],
};

export default route;
