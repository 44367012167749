import { UvRoute } from '@/lib/router';
import suppliers from '@/pages/institution/suppliers/routes';
import users from '@/pages/institution/users/routes';

const route: UvRoute = {
  path: 'institution',
  component: {
    render: (h: any) => h('router-view'),
  },
  meta: {
    breadcrumb: {
      label: 'Institutie',
      route: false,
    },
  },
  children: [users, suppliers],
};

export default route;
