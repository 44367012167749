import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: 'reports',
  meta: {
    breadcrumb: { label: 'Rapoarte', route: 'inventory.history.reports' },
    header: { label: 'Rapoarte' },
  },
  component: imp(
    () => import('@/pages/inventory/history/view/reports/Main.vue' /* webpackChunkName: 'inventory.history.reports' */),
  ),
  props: true,
  children: [
    {
      path: '',
      name: 'inventory.history.reports',
      meta: {
        header: {
          label: 'Rapoarte',
          parentRoute: 'inventory.history',
        },
      },
      component: imp(
        () =>
          import('@/pages/inventory/history/view/reports/List.vue' /* webpackChunkName: 'inventory.history.reports' */),
      ),
    },
    {
      path: 'inventory/:reportType(mf|oi)',
      name: 'inventory.history.reports.inventory',
      props: true,
      meta: {
        header: {
          label: (deps) => `Inventar ${deps.reportTypeLabel || '-'}`,
          parentRoute: 'inventory.history.reports',
        },
        breadcrumb: {
          label: (deps) => `Inventar ${deps.reportTypeLabel || '-'}`,
        },
      },
      component: imp(
        () => import('@/pages/reports/inventory/MainReport.vue' /* webpackChunkName: 'reports.inventory' */),
      ),
    },
    {
      path: 'inventory/org-chart',
      name: 'inventory.history.reports.inventory.org-chart',
      props: true,
      meta: {
        header: {
          label: 'Inventar faptic pe noduri',
          parentRoute: 'inventory.history.reports',
        },
        breadcrumb: {
          label: 'Inventar faptic pe noduri',
        },
      },
      component: imp(
        () =>
          import(
            '@/pages/reports/inventory/OrgChartReport.vue' /* webpackChunkName: 'inventory.history.reports.inventory' */
          ),
      ),
    },
    {
      path: 'institution/user-activity',
      name: 'inventory.history.reports.institution.user-activity',
      meta: {
        breadcrumb: { label: 'Raport activitate utilizator' },
        header: {
          label: 'Raport activitate utilizator',
          parentRoute: 'inventory.history.reports',
        },
      },
      component: imp(
        () =>
          import(
            '@/pages/reports/institution/UserActivity.vue' /* webpackChunkName: 'inventory.history.reports.institution' */
          ),
      ),
    },
  ],
};

export default route;
