<template>
  <v-list dense>
    <component
      v-for="(item, i) in innerMenu"
      :is="item.is"
      :key="i"
      :item="item"
    />
  </v-list>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue';
import PrimaryItem from '@/components/listMenu/PrimaryItem.vue';
import NormalItem from '@/components/listMenu/NormalItem.vue';
import { VDivider } from 'vuetify/lib';

export default defineComponent({
  name: 'ListItemMenu',
  components: {
    PrimaryItem,
    NormalItem,
    VDivider,
  },
  props: {
    menu: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const getView = (item: any) => {
      if (item.hasOwnProperty('isPrimary') && item.isPrimary) {
        return 'PrimaryItem';
      }
      if (item.hasOwnProperty('isDivider') && item.isDivider) {
        return 'v-divider';
      }
      return 'NormalItem';
    };

    const innerMenu = computed(() =>
      props.menu.map((item: any, index: any) => ({
        ...item,
        index,
        props: {
          title: item.label,
          to: item.route,
        },
        on: {
          click: (e: any) => {
            if (!item.click) {
              return;
            }
            e.preventDefault();
            item.click(e);
          },
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        is: getView(item, index),
      })),
    );

    return {
      innerMenu,
    };
  },
});
</script>
