import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import quantities from '@/pages/inventory/active/scriptic/view/quantities/routes';
import scan from '@/pages/inventory/active/scriptic/view/scan/routes';

const route: UvRoute = {
  path: ':itemId',
  component: imp(
    () => import('@/pages/inventory/active/scriptic/view/Main.vue' /* webpackChunkName: 'inventory.active.scriptic' */),
  ),
  props: true,
  meta: {
    permissions: ['read.scriptic-items'],
  },
  children: [
    {
      path: '',
      name: 'inventory.scriptic.show',
      component: imp(
        () =>
          import(
            '@/pages/inventory/active/scriptic/view/show/Main.vue' /* webpackChunkName: 'inventory.active.scriptic' */
          ),
      ),
      props: true,
      children: [
        quantities,
        {
          props: true,
          path: 'scans/:scanId/remove',
          name: 'inventory.scriptic.scans.remove',
          component: imp(
            () =>
              import(
                '@/pages/inventory/active/scriptic/view/scan/Remove.vue' /* webpackChunkName: 'inventory.active.scriptic' */
              ),
          ),
        },
      ],
    },
    {
      path: 'edit',
      name: 'inventory.scriptic.edit',
      component: imp(
        () =>
          import('@/pages/inventory/active/scriptic/view/Edit.vue' /* webpackChunkName: 'inventory.active.scriptic' */),
      ),
      meta: {
        permissions: ['update.scriptic-items'],
      },
      props: true,
    },
    {
      path: 'delete',
      name: 'inventory.scriptic.delete',
      component: imp(
        () =>
          import(
            '@/pages/inventory/active/scriptic/view/Delete.vue' /* webpackChunkName: 'inventory.active.scriptic' */
          ),
      ),
      meta: {
        permissions: ['delete.scriptic-items'],
      },
      props: true,
    },
    scan,
  ],
};

export default route;
