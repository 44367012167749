import imp from '@/lib/imp';
import { createInjectionComponent, UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: 'create',
  component: createInjectionComponent(),
  children: [
    {
      path: 'select-category',
      name: 'assets.products.create',
      props: true,
      meta: {
        permissions: ['create.products'],
        header: {
          label: 'Adauga produs',
        },
      },
      component: imp(
        () =>
          import(
            '@/pages/assets/modules/products/create/SelectCategory.vue' /* webpackChunkName: 'assets.products.main' */
          ),
      ),
    },
    {
      path: ':categoryId',
      name: 'assets.products.create.form',
      props: true,
      meta: {
        permissions: ['create.products'],
        header: {
          label: ({ selectedCategory }) => 'Adauga produs' + (selectedCategory ? ' in ' + selectedCategory.label : ''),
        },
      },
      component: imp(
        () =>
          import(
            '@/pages/assets/modules/products/create/CategoryLoader.vue' /* webpackChunkName: 'assets.products.main' */
          ),
      ),
    },
  ],
};

export default route;
