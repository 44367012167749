import api from '@/api';

export default {
  namespaced: true,
  state: {
    list: {
      data: [],
      pagination: {
        sortBy: 'createdAt',
        descending: true,
        totalItems: 0,
        page: 1,
        rowsPerPage: 25,
      },
    },
  },
  mutations: {
    setList: (state: any, { data, totalItems }: any) => {
      state.list.data = data;
      state.list.pagination.totalItems = totalItems;
    },
    setListPagination: (state: any, { page, rowsPerPage, sortBy, descending }: any) => {
      state.list.pagination.sortBy = sortBy || 'createdAt';
      state.list.pagination.descending = descending || true;
      state.list.pagination.page = page || 1;
      state.list.pagination.rowsPerPage = rowsPerPage || 25;
    },
  },
  actions: {
    load: async ({ state, commit }: any, pagination: any = {}) => {
      const { page = 1, rowsPerPage = 25 } = pagination;
      commit('setListPagination', {
        page,
        rowsPerPage,
      });

      const response = await api.admin.users.index({
        params: {
          page: state.list.pagination.page,
          rowsPerPage: state.list.pagination.rowsPerPage,
        },
      });

      const payload = await response.json();
      if (!response.ok) {
        throw new Error(payload.error);
      }
      commit('setList', payload);
    },
  },
};
