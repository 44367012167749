import map from '@/pages/urbanism/map/routes';
import regulations from '@/pages/urbanism/regulation/routes';
import subdivisionTypes from '@/pages/urbanism/subdivision-types/routes';
import { createInjectionComponent, UvRoute } from '@/lib/router';
import imp from '@/lib/imp';

const routes: UvRoute = {
  path: 'urbanism',
  props: true,
  component: createInjectionComponent(),
  meta: {
    breadcrumb: {
      label: 'Urbanism',
      route: 'urbanism',
    },
  },
  children: [
    {
      path: '',
      component: imp(() => import('@/pages/urbanism/Actions.vue')),
      name: 'urbanism',
      props: true,
      meta: {
        header: {
          label: 'Urbanism',
          route: 'urbanism',
        },
      },
    },
    map,
    regulations,
    subdivisionTypes,
  ],
};

export default routes;
