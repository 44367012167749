import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: ':maintenanceId',
  props: true,
  component: imp(
    () =>
      import(
        '@/pages/assets/modules/products/view/maintenances/view/Main.vue' /* webpackChunkName: 'assets.products.maintenances' */
      ),
  ),
  meta: {
    header: {
      label: ({ maintenance }) => (maintenance ? maintenance.name : 'Vizualizare mentenanta'),
      route: 'assets.products.maintenances.show',
      menu: () => [],
    },
    breadcrumb: {
      label: ({ maintenance }) => (maintenance ? maintenance.name : 'Vizualizare mentenanta'),
      route: 'assets.products.maintenances.show',
    },
  },
  children: [
    {
      path: '',
      name: 'assets.products.maintenances.show',
      component: imp(
        () =>
          import(
            '@/pages/assets/modules/products/view/maintenances/view/Show.vue' /* webpackChunkName: 'assets.products.maintenances' */
          ),
      ),
      children: [
        {
          path: 'start',
          name: 'assets.products.maintenances.start',
          component: imp(
            () =>
              import(
                '@/pages/assets/modules/products/view/maintenances/view/Start.vue' /* webpackChunkName: 'assets.products.maintenances' */
              ),
          ),
        },
        {
          path: 'archive',
          name: 'assets.products.maintenances.archive',
          props: true,
          component: imp(
            () =>
              import(
                '@/pages/assets/modules/products/view/maintenances/view/Archive.vue' /* webpackChunkName: 'assets.maintenances.archive' */
              ),
          ),
        },
      ],
    },
  ],
};

export default route;
