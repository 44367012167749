import { parseISO, format } from 'date-fns';
import { bool, formats } from '@/config/ro';
import { Duration, parse as parseDuration } from 'iso8601-duration';
import { isNumber, isString } from '@/lib/tools';

const durationLabels: Array<[keyof Duration, string, string]> = [
  ['years', 'an', 'ani'],
  ['months', 'lună', 'luni'],
  ['weeks', 'săptămână', 'săptămâni'],
  ['days', 'zi', 'zile'],
  ['hours', 'oră', 'ore'],
  ['minutes', 'minut', 'minute'],
  ['seconds', 'secundă', 'secunde'],
];

export const humanizeDuration = (duration: string | Duration = {}): string => {
  const _duration = isString(duration) ? parseDuration(duration) : duration;

  return durationLabels
    .filter(([field]) => !!_duration[field])
    .map(([field, sg, pl]) => `${_duration[field]} ${_duration[field] === 1 ? sg : pl}`)
    .join(', ');
};

export const parseDate = (dateStr: string): Date | null => {
  const date = parseISO(dateStr);

  return isNaN(date.getTime()) ? null : date;
};

interface DateFormatter {
  (date: string): string;
  (date: Date): string;
}

const _format =
  (f: string): DateFormatter =>
  (dateStr: string | Date) => {
    if (!dateStr) return '';

    const date = dateStr instanceof Date ? dateStr : Number.isInteger(dateStr) ? new Date(dateStr) : parseISO(dateStr);

    return date && !isNaN(date.getTime()) ? format(date, f) : '';
  };

export const formatDateTime: DateFormatter = _format(formats.dateTime);
export const formatDate: DateFormatter = _format(formats.date);
export const formatDateAlt: DateFormatter = _format(formats.dateAlt);
export const formatTimestamp: DateFormatter = _format(formats.fullDateTime);

export const formatCurrency = (v: number): string => v.toFixed(2);

export const formatQuantity = (v: number) => (Number.isInteger(v) ? v : v.toFixed(2));

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const boolean = (s: boolean) => (s ? bool.yes : bool.no);

export const nullable = (v: string | number | null | undefined, emptyText = '-'): string | number =>
  (isString(v) && v.length > 0) || isNumber(v) ? v : emptyText;

export const truncate = (v: string | null | undefined, n = 100): string =>
  isString(v) && v.length > 0 ? v.substring(0, n - 1) + '…' : '';
