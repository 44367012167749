import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const routes: UvRoute[] = [
  {
    path: 'create',
    name: 'assets.products.inventory.items.create',
    props: true,
    meta: {
      permissions: ['read.products'],
    },
    component: imp(
      () =>
        import(
          '@/pages/assets/modules/products/view/inventory/items/actions/Create.vue' /* webpackChunkName: 'assets.products.inventory' */
        ),
    ),
  },
];

export default routes;
