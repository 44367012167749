<template>
  <router-link
    v-if="hasRoute"
    :to="route"
    exact
    custom
    v-slot="{ href, navigate }"
  >
    <li>
      <a
        @click="navigate"
        @keypress.enter="navigate"
        :href="href"
      >
        <v-icon v-if="meta.icon">{{ meta.icon }}</v-icon>
        <span>{{ text }}</span>
      </a>
    </li>
  </router-link>
  <span v-else>
    <v-icon v-if="meta.icon">{{ meta.icon }}</v-icon>
    <span>{{ text }}</span>
  </span>
</template>
<script lang="ts">
import { RouteMetaBreadcrumbsLabel } from '@/lib/router';
import { StoreKey } from '@/symbols';
import { injectStrict } from '@/lib/inject';
import { defineComponent, computed, PropType } from 'vue';

export default defineComponent({
  props: {
    meta: {
      type: Object as PropType<RouteMetaBreadcrumbsLabel>,
      required: true,
    },
    params: {
      type: Object as PropType<Record<string, string>>,
    },
    routeName: {
      type: String,
    },
  },
  setup(props) {
    const store = injectStrict(StoreKey);
    const deps = computed(() => store.state.pageDeps.deps);

    return {
      text: computed(() => {
        const text =
          props.meta.label instanceof Function ? props.meta.label(Object.fromEntries(deps.value)) : props.meta.label;

        return text && text.length > 30 ? text.substring(0, 30) + '…' : text;
      }),
      route: computed(() => {
        return {
          name: 'route' in props.meta && props.meta.route ? props.meta.route : props.routeName,
          params: props.params,
        };
      }),
      hasRoute: computed(() => {
        return 'route' in props.meta && props.meta.route !== false;
      }),
    };
  },
});
</script>
