<template>
  <v-app id="login">
    <v-container class="app fill-height justify-center align-center">
      <v-form
        v-model.trim="valid"
        @submit.prevent="doLogin"
        data-cy="login-form"
      >
        <v-card max-width="500">
          <v-card-text class="text-center pb-0">
            <img
              :src="require('@/assets/logo-login.png')"
              alt="Gr8 Logo Login"
            />
          </v-card-text>
          <v-card-text>
            <uv-alert-icon
              v-if="error"
              type="error"
            >
              {{ error.message }}
            </uv-alert-icon>
            <v-text-field
              v-model.trim="form.email"
              @blur="$v.form.email.$touch"
              :required="fields.email.rules.required"
              :error-messages="errors.email"
              :label="fields.email.label"
              type="email"
              data-cy="email"
              autofocus
            />
            <v-text-field
              v-model="form.password"
              @blur="$v.form.password.$touch"
              @click:append="() => (show = !show)"
              :append-icon="show ? 'visibility' : 'visibility_off'"
              :required="fields.password.rules.required"
              :error-messages="errors.password"
              :label="fields.password.label"
              :type="show ? 'text' : 'password'"
              data-cy="password"
            />
          </v-card-text>
          <v-card-actions v-show="!success">
            <v-btn
              :href="mobileAppURL"
              target="_blank"
              color="success darken-2"
              text
              link
            >
              <v-icon small>open_in_new</v-icon>
              Deschide aplicatia mobila
            </v-btn>
            <v-spacer />
            <v-btn
              :loading="submitting"
              color="primary"
              type="submit"
            >
              Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-container>
  </v-app>
</template>
<script lang="ts">
import config from '@/config';
import makeValidator from '@/lib/validator';
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import { mapActions, mapState } from 'vuex';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Login',
  mixins: [validationMixin],
  data() {
    const fields = {
      email: {
        label: 'E-mail',
        default: '',
        rules: {
          required,
          email,
        },
      },
      password: {
        label: 'Parola',
        default: '',
        rules: { required },
      },
    };
    return {
      valid: true,
      success: false,
      show: false,
      form: Object.entries(fields).reduce(
        (carry, [field, { default: value }]: any) => ({
          ...carry,
          [field]: value,
        }),
        {},
      ),
      fields,
    };
  },
  validations() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.validator.validator();
  },
  computed: {
    ...mapState('auth', ['error', 'submitting']),
    ...mapState(['darkTheme']),
    validator() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return makeValidator(this.fields, 'form');
    },
    errors() {
      return this.validator.errors(this.$v, this.$store.state.auth.errors);
    },
    mobileAppURL() {
      return config.mobileAppHost;
    },
  },
  mounted() {
    this.$vuetify.theme.dark = this.darkTheme;
  },
  methods: {
    ...mapActions('auth', ['login']),
    doLogin() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return Promise.resolve();
      }
      const credentials = this.validator.values(this.$v);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.login(credentials);
    },
  },
});
</script>
