<template>
  <v-app-bar
    color="primary"
    dark
  >
    <header-back-button
      v-if="parent"
      :current="current"
      :parent="parent"
    />
    <v-toolbar-title
      v-if="title"
      data-cy="page-header"
    >
      {{ title }}
    </v-toolbar-title>
    <v-skeleton-loader
      v-else
      type="heading"
      width="450"
    />
    <v-spacer />
    <header-menu
      v-if="hasMenu"
      :menu="filteredMenu"
    />
  </v-app-bar>
</template>
<script lang="ts">
import HeaderBackButton from '@/components/pages/header/BackButton.vue';
import HeaderMenu from '@/components/pages/header/Menu.vue';
import { mapGetters, mapState } from 'vuex';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PageHeaderNav',
  components: {
    HeaderBackButton,
    HeaderMenu,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('pageDeps', ['deps']),
    ...mapState('auth', ['ability']),
    current() {
      return this.items[0];
    },
    parent() {
      return this.items[1];
    },
    meta() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.current.meta;
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    title() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.meta.label instanceof Function ? this.meta.label(this.deps) : this.meta.label;
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    hasMenu() {
      return (
        this.meta.hasOwnProperty('menu') &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.filteredMenu.filter((itm: any) => (itm.hasOwnProperty('isDivider') ? !itm.isDivider : itm)).length > 0
      );
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    menu() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.meta.menu(this.current, this.deps, this.$route.params);
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    filteredMenu() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.menu.filter(
        (itm: any) =>
          (itm.hasOwnProperty('disabled')
            ? itm.disabled instanceof Function
              ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                !itm.disabled(this.deps)
              : !itm.disabled
            : true) &&
          (itm.hasOwnProperty('permissions')
            ? itm.permissions.every((permission: any) => {
                const [action, subject] = permission.split('.');
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return this.ability.can(action, subject);
              })
            : true),
      );
    },
  },
});
</script>
