import imp from '@/lib/imp';
import { injectStrict } from '@/lib/inject';
import { UvRoute, UvRouteHeader } from '@/lib/router';
import { TreeNode } from '@/lib/trees';
import router from '@/router';
import { NodeAction, NodesManagerKey } from '@/components/tree-nodes/nodesManager';

interface IOptions {
  label: UvRouteHeader['label'];
  permissionsRoot: any;
  rootRoute: string;
  namespace: string;
  nodeRoutes?: UvRoute[];
  nodeActions?: NodeAction[];
  nodeIcon?: ((node: TreeNode) => string) | null;
}

type TreeManagerRoute = UvRoute & {
  meta: {
    nodeActions: IOptions['nodeActions'];
  };
};

export default (opts: IOptions): UvRoute => {
  const { label, permissionsRoot, rootRoute, nodeRoutes = [], nodeActions = [], nodeIcon = null } = opts;

  return {
    path: '',
    component: imp(() => import('@/components/tree-nodes/Main.vue' /* webpackChunkName: 'tree' */)),
    props: {
      rootRoute,
      nodeActions,
      nodeIcon,
    },
    meta: {
      permissions: [`read.${permissionsRoot}`],
      header: {
        label,
        menu: (route) => [
          {
            label: 'Adauga radacina',
            icon: 'add',
            separated: true,
            route: {
              name: `${rootRoute}.node.root.add`,
              params: route.params,
            },
          },
        ],
      },
    },
    children: [
      {
        path: '',
        name: rootRoute,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: imp(() => import('@/components/tree-nodes/NoNodeView.vue' /* webpackChunkName: 'tree' */)),
      },
      {
        path: 'node/add',
        name: `${rootRoute}.node.root.add`,
        meta: {
          permissions: [`create.${permissionsRoot}`],
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: imp(() => import('@/components/tree-nodes/actions/AddRootNode.vue' /* webpackChunkName: 'tree' */)),
      },
      {
        path: 'node/:nodeId',
        name: `${rootRoute}.node`,
        meta: {
          permissions: [`read.${permissionsRoot}`],
        },
        component: imp(() => import('@/components/tree-nodes/NodeView.vue' /* webpackChunkName: 'tree' */)),
        props: (route) => {
          const manager = injectStrict(NodesManagerKey);
          if (!manager.state.tree) {
            return router.back();
          }
          const node = manager.state.tree.nodes.find((node) => node.id === route.params.nodeId);

          if (!node) {
            return router.back();
          }

          return {
            nodeIcon,
            ...route.params,
            node,
          };
        },
        children: [
          {
            path: 'add',
            name: `${rootRoute}.node.add`,
            meta: {
              permissions: [`create.${permissionsRoot}`],
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component: imp(() => import('@/components/tree-nodes/actions/AddNode.vue' /* webpackChunkName: 'tree' */)),
          },
          {
            path: 'rename',
            name: `${rootRoute}.node.rename`,
            meta: {
              permissions: [`update.${permissionsRoot}`],
            },
            component: imp(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              () => import('@/components/tree-nodes/actions/RenameNode.vue' /* webpackChunkName: 'tree' */),
            ),
          },
          {
            path: 'delete',
            name: `${rootRoute}.node.delete`,
            meta: {
              permissions: [`delete.${permissionsRoot}`],
            },
            component: imp(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              () => import('@/components/tree-nodes/actions/DeleteNode.vue' /* webpackChunkName: 'tree' */),
            ),
          },
          {
            path: 'move',
            name: `${rootRoute}.node.move`,
            meta: {
              permissions: [`update.${permissionsRoot}`],
            },
            component: imp(() => import('@/components/tree-nodes/actions/MoveNode.vue' /* webpackChunkName: 'tree' */)),
          },
          ...nodeRoutes.map<TreeManagerRoute>((nodeRoute) => ({
            ...nodeRoute,
            meta: {
              ...(nodeRoute.meta ? nodeRoute.meta : []),
              nodeActions,
            },
            props: (route) => ({
              nodeIcon,
              ...route.params,
            }),
          })),
        ],
      },
    ],
  };
};
