import imp from '@/lib/imp';
import { UvRoute, UvRouteHeader } from '@/lib/router';

const header: UvRouteHeader = {
  label: ({ fieldGroup }) =>
    fieldGroup ? `Vizualizare grup de campuri "${fieldGroup}"` : 'Vizualizare grup de campuri',
  menu: () => [
    {
      label: 'Modifica detalii',
      icon: 'edit',
      route: {
        name: 'admin.field-groups.edit',
      },
      permissions: ['manage.field-groups'],
    },
  ],
};
const route: UvRoute = {
  path: ':fieldGroupId',
  props: true,
  component: imp(
    () => import('@/pages/admin/field-groups/view/Main.vue' /* webpackChunkName: 'admin.field-groups.view' */),
  ),
  meta: {
    breadcrumb: {
      route: 'admin.field-groups.show',
      label: ({ fieldGroup }) => fieldGroup || 'Grup de campuri',
    },
  },
  children: [
    {
      path: '',
      props: true,
      name: 'admin.field-groups.show',
      component: imp(
        () => import('@/pages/admin/field-groups/view/Show.vue' /* webpackChunkName: 'admin.field-groups.view' */),
      ),
      meta: {
        header,
      },
      children: [
        {
          path: 'fields/create',
          props: true,
          name: 'admin.field-groups.add-field',
          component: imp(
            () =>
              import(
                '@/pages/admin/field-groups/view/actions/CreateField.vue' /* webpackChunkName: 'admin.field-groups.fields' */
              ),
          ),
        },
        {
          path: 'fields/:fieldId/edit',
          props: true,
          name: 'admin.field-groups.edit-field',
          component: imp(
            () =>
              import(
                '@/pages/admin/field-groups/view/actions/edit/Main' /* webpackChunkName: 'admin.field-groups.fields' */
              ),
          ),
        },
        {
          path: 'edit',
          props: true,
          name: 'admin.field-groups.edit',
          component: imp(
            () => import('@/pages/admin/field-groups/view/Edit.vue' /* webpackChunkName: 'admin.field-groups.view' */),
          ),
        },
      ],
    },
  ],
};

export default route;
