import { jwtDecode } from 'jwt-decode';
import { differenceInSeconds, fromUnixTime, isPast as isPastDate, isValid as isValidDate } from 'date-fns';

export interface AuthUser {
  sub: ObjectId;
  exp: Date;
  jti: string;
  email: string;
  admin: boolean;
  roles: any[];
  institutionId?: ObjectId;
  fullName: string;
}

export interface RawAuthPayload {
  sub: string;
  exp: number;
  jti: string;
  email: string;
  admin: boolean;
  roles: any[];
  institutionId?: string;
  fullName: string;
}

export interface ExpiryTimeoutOptions {
  preceding: number;
}

export const getExpiryTimeout = ({ preceding }: ExpiryTimeoutOptions) => {
  return (now: Date, exp: Date) => {
    return Math.max(0, differenceInSeconds(exp, now) - preceding) * 1000;
  };
};

export const extractUserJwtData = (token: string): AuthUser => {
  const data = <RawAuthPayload>jwtDecode(token);

  if (!data.hasOwnProperty('exp')) {
    throw new Error('Token is does not have an exp claim.');
  }
  const exp = fromUnixTime(data.exp);
  if (!isValidDate(exp)) {
    throw new Error('Token exp claim is invalid.');
  }

  if (isPastDate(exp)) {
    throw new Error('Token claim is expired.');
  }

  return {
    ...data,
    exp,
  };
};
