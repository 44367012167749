import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: ':itemId',
  props: true,
  meta: {
    permissions: ['read.products'],
  },
  component: imp(
    () =>
      import(
        '@/pages/assets/modules/products/view/inventory/items/view/Main.vue' /* webpackChunkName: 'assets.products.inventory' */
      ),
  ),
  children: [
    {
      path: 'edit',
      name: 'assets.products.inventory.items.edit',
      props: true,
      meta: {
        permissions: ['read.products'],
      },
      component: imp(
        () =>
          import(
            '@/pages/assets/modules/products/view/inventory/items/view/Edit.vue' /* webpackChunkName: 'assets.products.inventory' */
          ),
      ),
    },
  ],
};

export default route;
