import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import { Endpoint } from '@/lib/api-builder';

interface IConfig {
  route: string;
  loadApi: Endpoint;
  loadApiParams: string[];
  saveApi: Endpoint;
  saveApiParams: string[];
}

const viewRouter = (config: IConfig) =>
  ({
    path: 'edit',
    props: (route) => ({
      ...route.params,
      ...config,
    }),
    component: imp(
      () => import('@/pages/urbanism/common/areas/view/Main.vue' /* webpackChunkName: 'urbanism.areas' */),
    ),
    children: [
      {
        path: '',
        props: (route) => ({
          ...route.params,
          ...config,
        }),
        name: `urbanism.${config.route}.area.edit`,
        component: imp(
          () => import('@/pages/urbanism/common/areas/view/edit/Main.vue' /* webpackChunkName: 'urbanism.areas' */),
        ),
        meta: {
          wide: true,
          header: {
            label: 'Editare locatie',
            parentRoute: `urbanism.${config.route}.show`,
          },
          breadcrumb: {
            label: 'Editare locatie',
            route: false,
          },
        },
      },
    ],
  }) as UvRoute;

export default viewRouter;
