import { PluginObject } from 'vue';
import { extend, localize, ValidationObserver, ValidationProvider } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import ro from 'vee-validate/dist/locale/ro.json';

export const plugin: PluginObject<any> = {
  install(Vue): void {
    localize('ro', ro);

    Vue.component('ValidationObserver', ValidationObserver);
    Vue.component('ValidationProvider', ValidationProvider);

    Object.keys(rules).forEach((rule: string) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      extend(rule, rules[rule]);
    });

    extend('decimal', {
      message: (field) => `Câmpul ${field} trebuie să fie un număr întreg sau o zecimală validă`,
      validate: (value) => !isNaN(parseFloat(value)) && isFinite(value),
    });
  },
};
