import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import show from '@/pages/inventory/history/view/scriptic/show/routes';
import header from '@/pages/inventory/history/view/scriptic/header';

const route: UvRoute = {
  path: 'scriptic',
  name: 'inventory.history.scriptic',
  component: imp(
    () =>
      import('@/pages/inventory/history/view/scriptic/List.vue' /* webpackChunkName: 'inventory.history.scriptic' */),
  ),
  props: true,
  meta: {
    header,
    breadcrumb: {
      label: 'Lista scriptic',
      route: 'inventory.history.scriptic',
    },
  },
  children: [show],
};

export default route;
