import { UvRoute } from '@/lib/router';
import imp from '@/lib/imp';
import assets from '@/pages/admin/assets/routes';
import fieldGroups from '@/pages/admin/field-groups/routes';
import institutions from '@/pages/admin/institutions/routes';
import logs from '@/pages/admin/logs/routes';
import settings from '@/pages/admin/settings/routes';
import roles from '@/pages/admin/roles/routes';
import users from '@/pages/admin/users/routes';
import { CreateElement } from 'vue';

const route: UvRoute = {
  path: 'admin',
  component: {
    render: (h: CreateElement) => h('router-view'),
  },
  meta: {
    breadcrumb: {
      label: 'Admin',
      route: false,
    },
  },
  children: [
    assets,
    fieldGroups,
    institutions,
    logs,
    settings,
    roles,
    users,
    {
      path: 'seed',
      name: 'admin.seed.index',
      component: imp(() => import('@/pages/Seed.vue' /* webpackChunkName: 'admin.seed' */)),
    },
    {
      path: 'migrate',
      name: 'admin.migrate.index',
      component: imp(() => import('@/pages/Migrate.vue' /* webpackChunkName: 'admin.migrate' */)),
    },
  ],
};

export default route;
