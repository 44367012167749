<template>
  <div>
    <template v-for="item in separatedItems">
      <uv-tooltip-btn
        v-on="item.on"
        :key="item.label"
        :to="item.route"
        :tooltip-text="item.label"
        icon
        class="mr-1"
      >
        <v-icon>{{ item.icon || 'android' }}</v-icon>
      </uv-tooltip-btn>
    </template>
    <v-menu
      v-if="menuItems.length > 1"
      bottom
      left
      offset-x="offset-x"
      offset-y="offset-y"
    >
      <template #activator="{ on }">
        <v-btn
          v-on="on"
          dark
          data-cy="page-header-menu-activator"
          icon
        >
          <v-icon>more_vert</v-icon>
        </v-btn>
      </template>
      <template #default>
        <list-menu :menu="menuItems" />
      </template>
    </v-menu>
    <v-btn
      v-else-if="menuItems.length === 1"
      v-on="menuItems[0].on"
      :to="menuItems[0].route"
      data-cy="page-header-button"
      light
      small
    >
      <span>{{ menuItems[0].label }}</span>
      <v-icon
        v-if="!!menuItems[0].icon"
        right
        small
      >
        {{ menuItems[0].icon }}
      </v-icon>
    </v-btn>
  </div>
</template>
<script lang="ts">
import ListMenu from '@/components/listMenu/Main.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PageHeaderMenu',
  components: {
    ListMenu,
  },
  props: {
    menu: {
      type: Array,
      required: true,
    },
  },
  computed: {
    separatedItems() {
      return this.menu.filter((item: any) => item.separated);
    },
    menuItems() {
      return this.menu.filter((item: any) => !item.separated);
    },
  },
});
</script>
