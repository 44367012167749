import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: 'logs',
  name: 'admin.logs.index',
  component: imp(() => import('@/pages/admin/logs/Main.vue' /* webpackChunkName: 'admin.logs.index' */)),
  meta: {
    permissions: ['manage.logs'],
    header: {
      route: 'admin.logs.index',
      label: 'Inregistrari',
    },
    breadcrumb: {
      label: 'Inregistrari',
      route: 'admin.logs.index',
    },
  },
};

export default route;
