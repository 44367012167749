import api from '@/api';
import areas from '@/pages/urbanism/common/areas/routes';
import files from '@/pages/urbanism/common/files/routes';
import imp from '@/lib/imp';
import subdivisions from '@/pages/urbanism/regulation/view/subdivisions/routes';
import { UvRoute } from '@/lib/router';

const routes: UvRoute = {
  path: ':regulationId',
  props: true,
  component: imp(
    () => import('@/pages/urbanism/regulation/view/Main.vue' /* webpackChunkName: 'urbanism.regulations.view' */),
  ),
  meta: {
    breadcrumb: {
      label: ({ regulation }) => regulation || 'Reglementare',
      route: 'urbanism.regulations.show',
    },
    header: {
      label: ({ regulation }) => regulation || 'Reglementare',
    },
  },
  children: [
    {
      path: '/',
      props: true,
      name: 'urbanism.regulations.show',
      component: imp(
        () => import('@/pages/urbanism/regulation/view/Show.vue' /* webpackChunkName: 'urbanism.regulations.view' */),
      ),
    },
    {
      path: 'edit',
      props: true,
      name: 'urbanism.regulations.edit',
      component: imp(
        () => import('@/pages/urbanism/regulation/view/Edit.vue' /* webpackChunkName: 'urbanism.regulations.view' */),
      ),
      meta: {
        breadcrumb: {
          label: 'Editare',
          route: false,
        },
        header: {
          label: ({ regulation }) => (regulation ? `Editare ${regulation}` : 'Editare reglementare'),
          parentRoute: 'urbanism.regulations.show',
        },
      },
    },
    subdivisions,
    files({
      route: 'regulations',
      loadApi: api.urbanism.regulations.files.index,
      loadApiParams: ['regulationId'],
      uploadApi: api.urbanism.regulations.files.upload,
      uploadApiParams: ['regulationId'],
      downloadApi: api.urbanism.regulations.files.download,
      downloadApiParams: ['regulationId'],
    }),
    areas({
      route: 'regulations',
      loadApi: api.urbanism.regulations.area.show,
      loadApiParams: ['regulationId'],
      loadBoundsApi: api.urbanism.regulations.area.bounds,
      loadBoundsApiParams: ['regulationId'],
      saveApi: api.urbanism.regulations.area.edit,
      saveApiParams: ['regulationId'],
    }),
  ],
};

export default routes;
