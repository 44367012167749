import imp from '@/lib/imp';
import { UvRoute, UvRouteHeader } from '@/lib/router';

const header: UvRouteHeader = {
  label: ({ flow }) => (flow ? flow.name : 'Flux'),
  parentRoute: 'assets.products.flows.index',
  menu: () => [
    {
      // disabled: true,
      label: 'Actualizeaza',
      icon: 'check',
      route: {
        name: 'assets.products.flows.checklist',
      },
    },
    {
      label: 'Modifica descrierea',
      icon: 'edit',
      route: {
        name: 'assets.products.flows.edit.description',
      },
    },
  ],
};

const route: UvRoute = {
  path: ':flowId',
  props: true,
  component: imp(
    () =>
      import(
        '@/pages/assets/modules/products/view/flows/view/Main.vue' /* webpackChunkName: 'assets.products.flows' */
      ),
  ),
  children: [
    {
      path: '',
      props: true,
      meta: {
        header,
        breadcrumb: {
          label: ({ flow }) => (flow ? flow.name : 'Flux'),
          route: 'assets.products.flows.show',
        },
      },
      name: 'assets.products.flows.show',
      component: imp(
        () =>
          import(
            '@/pages/assets/modules/products/view/flows/view/Show.vue' /* webpackChunkName: 'assets.products.flows' */
          ),
      ),
      children: [
        {
          props: true,
          path: 'checklist',
          name: 'assets.products.flows.checklist',
          component: imp(
            () =>
              import(
                '@/pages/assets/modules/products/view/flows/view/checklist/Main.vue' /* webpackChunkName: 'assets.products.flows.checklist' */
              ),
          ),
        },
        {
          props: true,
          path: 'edit/description',
          name: 'assets.products.flows.edit.description',
          component: imp(
            () =>
              import(
                '@/pages/assets/modules/products/view/flows/view/edit/Description.vue' /* webpackChunkName: 'assets.products.flows.edit' */
              ),
          ),
        },
      ],
    },
  ],
};

export default route;
