import imp from '@/lib/imp';
import products from '@/pages/assets/modules/products/routes';
import services from '@/pages/assets/modules/services/routes';
import view from '@/pages/assets/modules/view/routes';
import reports from '@/pages/assets/modules/view/reports/routes';
import flows from '@/pages/assets/modules/flows/routes';
import maintenances from '@/pages/assets/modules/maintenances/routes';
import notifications from '@/pages/assets/modules/notifications/routes';
import productsMap from '@/pages/assets/modules/view/map/products/routes';
import moduleMap from '@/pages/assets/modules/map/routes';
import index from '@/pages/assets/modules/index/routes';
import { UvRoute, UvRouteHeader } from '@/lib/router';
import { CreateElement } from 'vue';

const moduleHeader: UvRouteHeader = {
  route: 'assets.modules.show',
  label: ({ module }) => (module ? module.label : undefined),
};

const route: UvRoute = {
  path: 'modules',
  props: true,
  component: {
    render: (h: CreateElement) => h('router-view'),
  },
  meta: {
    breadcrumb: {
      label: 'Module',
      route: 'assets.modules.index',
    },
    header: {
      route: 'assets.modules.index',
      label: 'Module',
    },
  },
  children: [
    index,
    moduleMap,
    {
      path: ':moduleId',
      props: true,
      component: imp(() => import('@/pages/assets/modules/view/Main.vue' /* webpackChunkName: 'assets.main' */)),
      meta: {
        breadcrumb: {
          label: ({ module }) => (module ? module.label : 'Modul'),
          route: 'assets.modules.show',
        },
        header: moduleHeader,
      },
      children: [view, flows, maintenances, notifications, products, productsMap, reports, services],
    },
  ],
};

export default route;
