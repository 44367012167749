import { pointInPolygon } from '@/lib/coordinates';
import { parseISO } from 'date-fns/parseISO';
import { multirange } from 'multi-integer-range';
import { map } from 'rambda';
import { RuleDecl } from 'vue/types/options';
import { helpers } from 'vuelidate/lib/validators';
import { properISO8601Pattern } from '@/lib/tools';

interface Field {
  label: string;
  rules: Record<string, RuleDecl> & { rule?: RuleDecl };
  cast?: () => void;
  as?: string;
}

interface MixinFields {
  fields: string;
  form: string;
  backendErrors: string;
  errors: string;
  labels: string;
}

const validatorConfig = {
  debug: false,
};

const sameAsFields = {
  password: 'Parola',
};

const errors = {
  required: (label: string) => `Campul ${label} este obligatoriu`,
  numeric: (label: string) => `Campul ${label} trebuie sa fie de tip numeric`,
  integer: (label: string) => `Campul ${label} trebuie sa fie de tip numar intreg`,
  email: (label: string) => `Campul ${label} nu este o adresa de e-mail valida`,
  minLength: (label: string, { min }: { min: number }) => `Campul ${label} trebuie sa aiba cel putin ${min} caractere`,
  minValue: (label: string, { min }: { min: number }) => `Campul ${label} trebuie sa aiba o valoare minima de ${min}`,
  sameAs: (label: string, { eq }: { eq: keyof typeof sameAsFields }) => {
    const sameAs = sameAsFields[eq] || 'Field';
    return `Campurile ${label} si ${sameAs} trebuie sa fie identice`;
  },
  postalCode: (label: string) => `Campul ${label} are un format invalid`,
  location: (label: string) => `Campul ${label} are un format invalid (45.3, 25.6)`,
  locationBounds: (label: string) => `Campul ${label} are un format invalid (x1, y1; x2, y2; x3, y3;...)`,
  locationWithinBounds: (label: string) => `Coordonatele din campul ${label} nu se afla in permetrul prestabilit`,
  Polygon: () => 'Aria selectata nu este un poligon',
  Point: () => 'Aria selectata nu este un punct',
};

const errorMessage = (label: any, rule: any, $field: any) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  errors.hasOwnProperty(rule) ? errors[rule](label, $field.$params[rule]) : `${label} failed: ${rule}`;

const fieldRules = (rules: any, field: any) =>
  Object.entries(rules).reduce((carry, [name, rule]) => {
    if (validatorConfig.debug) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // console.log(`Adding rule ${name} to field ${field}`, { rule: rule.hasOwnProperty('rule') ? rule.rule : rule });
    }
    return Object.defineProperty(carry, name, {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value: rule.hasOwnProperty('rule') ? rule.rule : rule,
      enumerable: true,
    });
  }, {});

const makeValidator = (fields: Record<string, Field>, form: string, config = {}) => {
  // console.log({ fields, form, config });
  Object.keys(validatorConfig).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    validatorConfig[key] = config[key] || validatorConfig[key];
  });
  if (validatorConfig.debug) {
    // console.log(`Creating validator for form ${form}`);
  }
  const validator = () => ({
    [form]: Object.entries(fields).reduce((carry, [field, { rules }]) => {
      const value = fieldRules(rules, field);
      return Object.defineProperty(carry, field, {
        value,
        enumerable: true,
      });
    }, {}),
  });

  const rulesFailed = (config: any) => {
    // console.log({ fn: 'rulesFailed', config });
    return config.$anyError
      ? Object.entries(config)
          .filter(([rule, value]) => rule[0] !== '$' && value === false)
          .map(([rule]) => rule)
      : [];
  };

  const errors = ($v: any, errors: any) => {
    // console.log({ fn: 'errors', $v, errors });
    return Object.entries(fields).reduce((carry, [field, config]) => {
      const $field = $v[form][field];
      return Object.defineProperty(carry, field, {
        value:
          errors && errors.hasOwnProperty(field)
            ? errors[field]
            : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              rulesFailed($field).map((rule) => errorMessage(config.label, rule, $field)),
        enumerable: true,
      });
    }, {});
  };

  const values = ($v: any) => {
    // console.log({ fn: 'values', $v });
    return Object.entries(fields).reduce(
      (carry, [field, config]) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        config.rules.hasOwnProperty('required') || $v[form][field].$model !== ''
          ? {
              ...carry,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              [config.hasOwnProperty('as') ? config.as : field]: (config.hasOwnProperty('cast') &&
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                config.cast instanceof Function
                ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  config.cast
                : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  (v) => v)($v[form][field].$model),
            }
          : carry,
      {},
    );
  };
  const labels = (/* $v */) =>
    Object.entries(fields).reduce(
      (carry, [field, config]) => ({
        ...carry,
        [field]: config.label + (config.rules.hasOwnProperty('required') ? ' *' : ''),
      }),
      {},
    );

  return {
    validator,
    values,
    errors,
    labels,
  };
};

export const min = (n: number) => (v: string) =>
  v === null || v.length === 0 || v.length >= n || `Trebuie sa contina minimum ${n} caractere.`;

export const formGenerator = (fields: any) =>
  Object.entries(fields).reduce(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (carry, [field, { default: value }]) => ({
      ...carry,
      [field]: value instanceof Function ? value() : value,
    }),
    {},
  );

const coordinatesRegexp =
  '[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?),\\s*[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)';

const locationRegexp = new RegExp(`^${coordinatesRegexp}$`);
export const isLatLng = (v: any) => v.lat && v.lng;
export const isPolygon = (latlngs: any) =>
  Array.isArray(latlngs) && latlngs.length >= 3 && latlngs.every((latlng) => isLatLng(latlng));
export const isLineString = (latlngs: any) =>
  Array.isArray(latlngs) && latlngs.length >= 2 && latlngs.every((latlng) => isLatLng(latlng));
export const isPoint = (v: any) => isLatLng(v);
export const isMultiPoint = (v: any) => Array.isArray(v) && v.length && v.every((v) => isPoint(v));
export const isMultiPolygon = (polys: any) =>
  Array.isArray(polys) && polys.length && polys.every((latlngs) => isPolygon(latlngs));

const internalValidations = {
  isPolygon,
  isPoint,
  isMultiPoint,
  isMultiPolygon,
  isLineString,
};

export const validations = {
  within:
    ({ items, multiple = false }: { items: any; multiple: boolean }) =>
    (value: any) => {
      const keys = Object.keys(items);
      if (!helpers.req(value)) {
        return true;
      }
      if (multiple) {
        if (!Array.isArray(value)) {
          return false;
        }

        return value.every((v) => keys.includes(v));
      }

      return keys.includes(value);
    },
  nullableLatLng: (v: any) => isLatLng(v) || v === null,
  Point: isPoint,
  MultiPoint: isMultiPoint,
  Polygon: isPolygon,
  LineString: isLineString,
  MultiPolygon: isMultiPolygon,
  GeometryCollection: (polys: any) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    polys.every((poly: any) => internalValidations[`is${poly.type}`](poly.coordinates)),
  geoJSONPoint: (point: any) =>
    point &&
    point.hasOwnProperty('type') &&
    point.type === 'Point' &&
    point.hasOwnProperty('coordinates') &&
    point.coordinates.length === 2,
  geoJSONPolygon: (poly: any) =>
    poly &&
    poly.hasOwnProperty('type') &&
    poly.type === 'Polygon' &&
    poly.hasOwnProperty('coordinates') &&
    poly.coordinates[0].length >= 4 &&
    poly.coordinates[0].every((coords: any) => coords.length === 2),
  postalCode: (v: any) => /^\d{6}$/.test(v),
  location: helpers.regex('location', locationRegexp),
  locationBounds: helpers.regex(
    'locationBounds',
    new RegExp(`^${coordinatesRegexp}(;\\s*${coordinatesRegexp}){2,};?$`),
  ),
  locationWithinBounds: (bounds: any) => (value: any) => {
    const matches = locationRegexp.exec(value);
    if (!matches) {
      return true; // others should validate
    }
    return pointInPolygon(
      {
        lat: matches[1],
        lng: matches[4],
      },
      bounds instanceof Function ? bounds() : bounds,
    );
  },
  multiRange: (v: any) => {
    if (!helpers.req(v)) {
      return true;
    }
    try {
      return !!multirange(v);
    } catch (e) {
      return false;
    }
  },
  date: (v: any) => !helpers.req(v) || !isNaN(parseISO(v).getTime()),
  duration: (v: any) => !helpers.req(v) || properISO8601Pattern.test(v),
  hexColor: (v: any) => /^#[0-9a-f]{3,6}$/i.test(v),
};

export default makeValidator;

export const makeMixin = (_: MixinFields) => {
  // console.log({ _ });
  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    validations() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.validator.validator();
    },
    data() {
      return {
        [_.backendErrors]: undefined,
        [_.form]: undefined,
      };
    },
    computed: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      validator() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // console.log({ t: this });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return makeValidator(this[_.fields], _.form);
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [_.errors]() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return this.validator.errors(this.$v, this[_.backendErrors]);
      },
      [_.labels]() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return this.validator.labels(this.$v);
      },
    },
    created() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this[_.form] = map(({ default: value }) => (value instanceof Function ? value() : value), this[_.fields]);
    },
  };
};

export const mixin = makeMixin({
  fields: 'fields',
  form: 'form',
  backendErrors: 'backendErrors',
  errors: 'errors',
  labels: 'labels',
});
