<template>
  <v-snackbar
    v-if="show"
    v-model="show"
    :color="message.color"
    :multi-line="message.hasOwnProperty('body')"
    :timeout="message.timeout || timeout"
  >
    <template #default>
      <template v-if="Array.isArray(message.title)">
        <div
          v-for="message in message.title"
          :key="message.title"
        >
          {{ message }}
        </div>
      </template>
      <div v-else>{{ message.title }}</div>
    </template>
    <template #action>
      <v-btn
        @click="remove()"
        color="white"
        icon
        text
      >
        <v-icon v-if="count === 1">close</v-icon>
        <span v-else-if="count > 1">Next ({{ showCount }} more)</span>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script lang="ts">
import { mapActions, mapGetters } from 'vuex';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    store: {
      required: true,
      type: String,
    },
    value: {
      required: false,
      default: false,
    },
    timeout: {
      required: false,
      default: 3000,
    },
  },
  data: () => ({
    show: false,
    message: undefined,
  }),
  computed: {
    ...mapGetters({
      count: 'messages/count',
      head: 'messages/head',
    }),
    showCount() {
      return this.count - 1;
    },
  },
  watch: {
    head: {
      handler(head) {
        if (head) {
          this.message = head;
          this.$nextTick(() => {
            this.show = true;
          });
        }
      },
      immediate: true,
    },
    show(v) {
      if (!v) {
        this.shift();
      }
    },
  },
  methods: {
    ...mapActions({
      shift: 'messages/shift',
    }),
    remove() {
      this.show = false;
    },
  },
});
</script>
