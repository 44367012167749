import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const routes: UvRoute = {
  path: ':subdivisionTypeId',
  props: true,
  component: imp(
    () =>
      import(
        '@/pages/urbanism/subdivision-types/view/Main.vue' /* webpackChunkName: 'urbanism.subdivision-types.view' */
      ),
  ),
  meta: {
    breadcrumb: {
      label: ({ subdivisionType }) => subdivisionType || 'Tip de subdiviziune',
      route: 'urbanism.subdivision-types.show',
    },
    header: {
      label: ({ subdivisionType }) => subdivisionType || 'Tip de subdiviziune',
      route: 'urbanism.subdivision-types.index',
    },
  },
  children: [
    {
      path: '/',
      props: true,
      name: 'urbanism.subdivision-types.show',
      component: imp(
        () =>
          import(
            '@/pages/urbanism/subdivision-types/view/Show.vue' /* webpackChunkName: 'urbanism.subdivision-types.view' */
          ),
      ),
    },
    {
      path: 'edit',
      props: true,
      name: 'urbanism.subdivision-types.edit',
      component: imp(
        () =>
          import(
            '@/pages/urbanism/subdivision-types/view/Edit.vue' /* webpackChunkName: 'urbanism.subdivision-types.view' */
          ),
      ),
      meta: {
        breadcrumb: {
          label: 'Editare',
          route: false,
        },
        header: {
          label: ({ subdivisionType }) =>
            subdivisionType ? `Editare ${subdivisionType}` : 'Editare tip de subdiviziune',
          parentRoute: 'urbanism.subdivision-types.show',
        },
      },
    },
  ],
};

export default routes;
