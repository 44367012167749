import { PluginObject } from 'vue';
import config from '@/config';

export const uv: PluginObject<undefined> = {
  install(Vue) {
    Vue.prototype.$uv = config;
  },
};

declare module 'vue/types/vue' {
  interface Vue {
    $uv: () => typeof config;
  }
}
