import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: 'notifications',
  name: 'assets.modules.notifications',
  meta: {
    header: {
      label: 'Setari notificari',
      parentRoute: 'assets.modules.show',
      menu: () => [
        {
          separated: true,
          label: 'Adauga notificare',
          route: { name: 'assets.modules.notifications.create' },
          icon: 'add',
        },
      ],
    },
    breadcrumb: {
      label: 'Setari',
    },
  },
  props: true,
  component: imp(
    () => import('@/pages/assets/modules/notifications/List.vue' /* webpackChunkName: 'assets.modules.notification' */),
  ),
  children: [
    {
      path: 'create',
      name: 'assets.modules.notifications.create',
      component: imp(
        () =>
          import(
            '@/pages/assets/modules/notifications/create/Main.vue' /* webpackChunkName: 'assets.modules.notifications' */
          ),
      ),
    },
  ],
};

export default route;
