import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import tree from '@/components/tree-nodes/routes';
import fieldGroups from '@/pages/admin/assets/modules/categories/field-groups/routes';

const route: UvRoute = {
  path: ':treeId/categories',
  component: imp(
    () => import('@/pages/admin/assets/modules/categories/Main.vue' /* webpackChunkName: 'assets.modules.nodes' */),
  ),
  props: true,
  meta: {
    breadcrumb: {
      label: (deps) => deps.module?.label || 'Modul',
      route: 'admin.modules.nodes',
    },
  },
  children: [
    fieldGroups,
    tree({
      label: (deps) => deps.module?.label || 'Modul',
      namespace: 'modules',
      permissionsRoot: 'assets-modules',
      rootRoute: 'admin.modules.nodes',
      nodeActions: [
        {
          route: (node) => ({
            name: 'admin.modules.nodes.icon',
            params: { nodeId: node.id },
          }),
          label: 'Pictograma',
          icon: 'image',
          iconColor: 'blue',
        },
        {
          route: (node) => ({
            name: 'admin.modules.nodes.field-groups.index',
            params: { nodeId: node.id },
          }),
          icon: 'list',
          iconColor: 'red',
          label: 'Grupuri de campuri',
        },
      ],
      nodeRoutes: [
        {
          path: 'icon',
          props: true,
          name: 'admin.modules.nodes.icon',
          component: imp(
            () =>
              import(
                '@/pages/admin/assets/modules/categories/ChangeIcon.vue' /* webpackChunkName: 'assets.modules.nodes' */
              ),
          ),
        },
      ],
      nodeIcon: (node) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        node.icon || '',
    }),
  ],
};

export default route;
