import { UvRoute } from '@/lib/router';
import imp from '@/lib/imp';

const route: UvRoute = {
  path: 'api-tokens',
  name: 'me.api-tokens',
  component: imp(() => import('@/pages/me/api-tokens/Main.vue' /* webpackChunkName: 'me.api-tokens' */)),
  meta: {
    header: {
      label: 'Token-uri API',
      menu: () => {
        return [
          {
            label: 'Adauga token API',
            icon: 'add',
            separated: true,
            route: {
              name: 'me.api-tokens.create',
            },
          },
        ];
      },
    },
    breadcrumb: {
      label: 'Token-uri API',
    },
  },
  children: [
    {
      path: 'create',
      name: 'me.api-tokens.create',
      component: imp(() => import('@/pages/me/api-tokens/Create.vue' /* webpackChunkName: 'me.api-tokens' */)),
    },
  ],
};

export default route;
