<template>
  <v-app-bar-nav-icon>
    <v-btn
      :to="backRoute"
      exact
      icon
    >
      <v-icon>arrow_back</v-icon>
    </v-btn>
  </v-app-bar-nav-icon>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PageHeaderBackButton',
  props: {
    current: {
      type: Object,
      required: true,
    },
    parent: {
      type: Object,
      required: true,
    },
  },
  computed: {
    backRoute() {
      return {
        name: this.current.meta.parentRoute || this.parent.meta.route || this.parent.name,
        params: this.parent.params,
      };
    },
  },
});
</script>
