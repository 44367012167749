import { ActionTree, Module, MutationTree } from 'vuex';
import { RootState } from '.';
import api from '@/api';
import { handleResponsePayload } from '@/lib/api-builder';

export interface NotificationsState {
  loading: boolean;
  count: number;
  error: Error | null;
}

const state: NotificationsState = {
  loading: false,
  count: 0,
  error: null,
};

export interface NotificationsPayload {
  count: number;
}

const mutations: MutationTree<NotificationsState> = {
  update: (state, { count }: NotificationsPayload) => {
    state.count = count;
  },
  startLoading: (state) => {
    state.loading = true;
    state.error = null;
  },
  stopLoading: (state) => {
    state.loading = false;
  },
  setError: (state, error: Error) => {
    state.error = error;
  },
  clear: (state) => {
    state.count = 0;
  },
};

let timer: number | null = null;

const actions: ActionTree<NotificationsState, RootState> = {
  update: ({ commit, dispatch }) =>
    Promise.resolve(commit('startLoading'))
      .then(() => api.me.notifications.count())
      .then(
        handleResponsePayload((payload) => {
          commit('update', payload.data);

          if (timer) {
            clearTimeout(timer);
          }

          timer = setTimeout(() => dispatch('update'), 3600 * 24 * 9999);
        }),
      )
      .catch((e) => commit('setError', e))
      .finally(() => commit('stopLoading')),
};

export const module: Module<NotificationsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};
