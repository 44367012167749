import imp from '@/lib/imp';
import { UvRoute, UvRouteHeader } from '@/lib/router';
import view from '@/pages/assets/modules/services/view/routes';

const header: UvRouteHeader = {
  label: 'Servicii',
  route: 'assets.services.index',
  menu: () => [
    {
      label: 'Adauga serviciu',
      icon: 'add',
      separated: true,
      route: {
        name: 'assets.services.create',
      },
      permissions: ['create.services'],
    },
  ],
};

const route: UvRoute = {
  path: 'services',
  props: true,
  component: imp(
    () => import('@/pages/assets/modules/services/Main.vue' /* webpackChunkName: 'assets.services.main' */),
  ),
  meta: {
    breadcrumb: {
      label: 'Servicii',
      route: 'assets.services.index',
    },
    header,
  },
  children: [
    {
      path: '',
      name: 'assets.services.index',
      props: true,
      component: imp(
        () => import('@/pages/assets/modules/services/List.vue' /* webpackChunkName: 'assets.services.main' */),
      ),
      meta: {
        permissions: ['read.services'],
      },
      children: [
        {
          path: 'create',
          name: 'assets.services.create',
          props: true,
          meta: {
            permissions: ['create.services'],
          },
          component: imp(
            () =>
              import(
                '@/pages/assets/modules/services/actions/Create.vue' /* webpackChunkName: 'assets.services.main' */
              ),
          ),
        },
        {
          path: ':serviceId/location/preview',
          name: 'assets.services.location.preview',
          props: true,
          meta: {
            permissions: ['read.services'],
          },
          component: imp(
            () =>
              import(
                '@/pages/assets/modules/services/view/location/Preview.vue' /* webpackChunkName: 'assets.services.main' */
              ),
          ),
        },
      ],
    },
    {
      path: ':serviceId',
      props: true,
      component: imp(
        () => import('@/pages/assets/modules/services/view/Main.vue' /* webpackChunkName: 'assets.services.main' */),
      ),
      children: view,
    },
  ],
};

export default route;
