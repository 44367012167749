import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: 'map',
  props: true,
  name: 'assets.modules.map',
  component: imp(
    () => import('@/pages/assets/modules/view/map/products/Main.vue' /* webpackChunkName: 'assets.main' */),
  ),
  meta: {
    wide: true,
    spaceless: true,
    breadcrumb: {
      label: 'Harta',
      route: 'assets.modules.map',
    },
    header: {
      label: 'Harta',
      route: 'assets.modules.map',
    },
  },
  children: [
    {
      path: 'products/:productId/areas/:areaId/inventory/items',
      props: true,
      name: 'assets.modules.map.areas.products.inventory.items.index',
      component: imp(
        () => import('@/components/map/products/inventory/InventoryList.vue' /* webpackChunkName: 'assets.main' */),
      ),
    },
  ],
};

export default route;
