import api from '@/api';
import { ActionTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/store/index';
import { Inventory, InventoryImportSource, ScripticItemType } from '@/pages/inventory/Inventory';

interface DefaultImportWorkspace {
  inventoryId: null | string;
}

export interface ActiveInventoryState {
  loading: boolean;
  error: null | Error;
  inventory: null | Inventory;
  importWorkspace: DefaultImportWorkspace;
}

const defaultImportWorkspace: DefaultImportWorkspace = {
  inventoryId: null,
};

const state: ActiveInventoryState = {
  loading: false,
  error: null,
  inventory: null,
  importWorkspace: localStorage.hasOwnProperty('import_workspace')
    ? JSON.parse(localStorage.getItem('import_workspace') || '')
    : defaultImportWorkspace,
};

const mutations: MutationTree<ActiveInventoryState> = {
  startLoading: (state) => {
    state.loading = true;
    state.error = null;
  },
  stopLoading: (state) => {
    state.loading = false;
  },
  setInventory: (state, inventory) => {
    state.inventory = inventory;
  },
  setError: (state, error) => {
    state.error = error;
  },
  setImportWorkspace: (state, workSpace) => {
    state.importWorkspace = workSpace;
  },
  startProcessing: (state, { type, source }: { type: ScripticItemType; source: InventoryImportSource }) => {
    if (state.inventory) {
      state.inventory.config.types[type].import.sources[source].processing = true;
    }
  },
};

const actions: ActionTree<ActiveInventoryState, RootState> = {
  startProcessing: ({ commit }, { type, source }) => {
    commit('startProcessing', { type, source });
  },
  load: ({ commit, state }) =>
    Promise.resolve(commit('startLoading'))
      .then(() => api.inventory.active())
      .then((response) =>
        response.json().then((payload) => {
          if (response.ok) {
            if (payload.data && payload.data._id !== state.importWorkspace.inventoryId) {
              commit('setImportWorkspace', defaultImportWorkspace);
            }
            commit('setInventory', payload.data);
          } else {
            return Promise.reject(new Error(payload.error));
          }
        }),
      )
      .catch((e) => commit('setError', e))
      .finally(() => commit('stopLoading')),
  saveImportWorkspace: ({ commit, state }, { source, type, inventoryId, pagination }) => {
    const workSpace = {
      ...state.importWorkspace,
      inventoryId,
      [source]: {
        [type]: pagination,
      },
    };
    localStorage.setItem('import_workspace', JSON.stringify(workSpace));
    commit('setImportWorkspace', workSpace);
  },
};

export const module: Module<ActiveInventoryState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};
