import routes from '@/pages/routes';
import qs from 'qs';
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  parseQuery(query) {
    return qs.parse(query, {
      decodeDotInKeys: true,
    });
  },
  stringifyQuery(query) {
    const result = qs.stringify(query);

    return result ? '?' + result : '';
  },
});
