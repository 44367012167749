import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: 'roles',
  component: imp(() => import('@/pages/admin/roles/Main.vue' /* webpackChunkName: 'admin.roles.index' */)),
  meta: {
    permissions: ['manage.roles'],
    header: {
      route: 'admin.roles.index',
      label: 'Roluri',
    },
    breadcrumb: {
      label: 'Roluri',
      route: 'admin.roles.index',
    },
  },
  children: [
    {
      path: '',
      component: imp(() => import('@/pages/admin/roles/Main.vue' /* webpackChunkName: 'admin.roles.index' */)),
      children: [
        {
          path: '',
          name: 'admin.roles.index',
          component: imp(() => import('@/pages/admin/roles/Builder.vue' /* webpackChunkName: 'admin.roles.index' */)),
        },
      ],
    },
  ],
};

export default route;
