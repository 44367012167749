import imp from '@/lib/imp';
import { UvRoute, UvRouteHeader } from '@/lib/router';

const header: UvRouteHeader = {
  label: ({ category }) => 'Campuri categorie ' + (category || ''),
  route: 'admin.modules.nodes.field-groups.index',
  menu: (route) => [
    {
      label: 'Actualizeaza campurile',
      icon: 'refresh',
      separated: true,
      route: {
        name: 'admin.modules.nodes.field-groups.associate',
        params: route.params,
      },
    },
  ],
};

const route: UvRoute = {
  path: ':nodeId/field-groups',
  component: imp(
    () =>
      import(
        '@/pages/admin/assets/modules/categories/field-groups/Main.vue' /* webpackChunkName: 'assets.modules.nodes.field-groups' */
      ),
  ),
  props: true,
  name: 'admin.modules.nodes.field-groups.index',
  meta: {
    header,
    breadcrumb: {
      label: ({ category }) => category || 'Categorie',
      route: 'admin.modules.nodes',
    },
  },
  children: [
    {
      path: 'associate',
      name: 'admin.modules.nodes.field-groups.associate',
      component: imp(
        () =>
          import(
            '@/pages/admin/assets/modules/categories/field-groups/actions/Associate.vue' /* webpackChunkName: 'assets.modules.nodes.field-groups' */
          ),
      ),
      props: true,
    },
  ],
};

export default route;
