import { RouteConfig } from 'vue-router';
import streets from '@/pages/maps/streets/routes';
import { CreateElement } from 'vue';

const routes: RouteConfig = {
  path: 'maps',
  props: true,
  component: {
    render: (h: CreateElement) => h('router-view'),
  },
  children: [streets],
};

export default routes;
