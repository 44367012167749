import api from '@/api';
import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import { InventoryImportSource, ScripticItemType } from '@/pages/inventory/Inventory';

const baseRouteName = `inventory.active.import.${InventoryImportSource.RECEPTION}.${ScripticItemType.OI}`;

const route: UvRoute = {
  path: ScripticItemType.OI,
  name: baseRouteName,
  props: (route) => ({
    ...route.params,
    baseRouteName,
  }),
  meta: {
    header: {
      label: 'Lista cu obiecte de inventar importate',
      menu: (route, { inventory }) => [
        {
          label: 'Adauga receptie noua',
          icon: 'add',
          disabled: inventory ? !inventory.config.types.mf.import.enabled : false,
          separated: true,
          route: {
            name: `${baseRouteName}.create`,
            params: route.params,
          },
        },
        {
          label: 'Importa',
          icon: 'arrow_upward',
          disabled: inventory ? !inventory.config.types.oi.import.enabled : false,
          separated: true,
          route: {
            name: `${baseRouteName}.upload`,
            params: route.params,
          },
        },
      ],
    },
    breadcrumb: {
      label: 'Lista cu obiecte de inventar importate',
      route: baseRouteName,
    },
  },
  component: imp(
    () =>
      import(
        '@/pages/inventory/active/import/reception/lists/oi/Main.vue' /* webpackChunkName: 'inventory.active.import.reception' */
      ),
  ),
  children: [
    {
      path: 'create',
      name: `${baseRouteName}.create`,
      component: imp(
        () =>
          import(
            '@/pages/inventory/active/import/reception/lists/oi/actions/Create.vue' /* webpackChunkName: 'inventory.active.import.reception' */
          ),
      ),
      props: (route) => ({
        ...route.params,
        baseRouteName,
      }),
    },
    {
      path: ':itemId/quantities/:quantityId',
      component: imp(
        () =>
          import(
            '@/pages/inventory/active/import/reception/lists/oi/quantities/Main.vue' /* webpackChunkName: 'inventory.active.import.reception' */
          ),
      ),
      props: (route) => ({
        ...route.params,
        baseRouteName,
      }),
      children: [
        {
          path: '',
          name: `${baseRouteName}.quantities.edit`,
          component: imp(
            () =>
              import(
                '@/pages/inventory/active/import/reception/lists/oi/quantities/Edit.vue' /* webpackChunkName: 'inventory.active.import.reception' */
              ),
          ),
          props: (route) => ({
            ...route.params,
            baseRouteName,
          }),
        },
      ],
    },
    {
      path: 'upload',
      name: `${baseRouteName}.upload`,
      props: {
        source: InventoryImportSource.RECEPTION,
        type: ScripticItemType.OI,
        api: api.inventory.import.upload,
        title: 'Incarca receptii de obiecte de inventar',
        parentRoute: { name: baseRouteName },
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      parentRoute: { name: `${baseRouteName}.upload` },
      component: imp(
        () =>
          import(
            '@/pages/inventory/active/import/common/Upload.vue' /* webpackChunkName: 'inventory.active.import.reception' */
          ),
      ),
    },
  ],
};

export default route;
