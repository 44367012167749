import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const routes: UvRoute = {
  path: 'map',
  props: true,
  component: imp(() => import('@/pages/urbanism/map/Main.vue' /* webpackChunkName: 'urbanism.map' */)),
  meta: {
    wide: true,
    spaceless: true,
    breadcrumb: {
      label: 'Harta',
      route: 'urbanism.map.index',
    },
  },
  children: [
    {
      path: '/',
      props: true,
      component: imp(() => import('@/pages/urbanism/map/Show.vue' /* webpackChunkName: 'urbanism.map' */)),
      name: 'urbanism.map.index',
    },
  ],
};

export default routes;
