import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: ':itemId',
  name: 'inventory.history.scriptic.item',
  component: imp(
    () =>
      import(
        '@/pages/inventory/history/view/scriptic/show/Main.vue' /* webpackChunkName: 'inventory.history.scriptic.item' */
      ),
  ),
  props: true,
};

export default route;
