export interface Tree {
  id: string;
  label: string;
  // eslint-disable-next-line no-use-before-define
  tree: TreeNode[];
  // eslint-disable-next-line no-use-before-define
  nodes: TreeNode[];
}

export interface TreeNode {
  tree: Tree;
  children: TreeNode[];
  parent: TreeNode | null;

  id: string;
  treeId: string;
  parentId: string | null;
  label: string;
  depth: number;
}

interface RawNode {
  id: string;
  treeId: string;
  parentId: string | null;
  label: string;
  _lft: number;
  _rgt: number;
  children: RawNode[];
}

export interface PrepTree {
  id: string;
  label: string;
  tree: RawNode[];
}

export const flattenNodes = (nodes: TreeNode[]): TreeNode[] =>
  nodes.flatMap((node: TreeNode) => [node].concat(flattenNodes(node.children)));

export const flattenNode = (node: TreeNode): TreeNode[] => [node].concat(flattenNodes(node.children));

export const isChildOf = (node: TreeNode, nodes: TreeNode[]): boolean =>
  nodes.some((child) => child === node || isChildOf(node, child.children));

export const prepNodes = (nodes: RawNode[], tree: Tree, parent: TreeNode | null = null, depth = 0): TreeNode[] =>
  nodes.map((nodeData) => makeNode(nodeData, tree, parent, depth));

export const makeNode = (nodeData: RawNode, tree: Tree, parent?: TreeNode | null, depth = 1): TreeNode => {
  const node: TreeNode = {
    ...nodeData,
    depth,
    children: [],
    tree,
    parent: parent || null,
  };

  return {
    ...node,
    children: nodeData.children ? prepNodes(nodeData.children, tree, node, depth + 1) : [],
  };
};

export const createRootNode = (tree: Tree, nodeData: any): TreeNode => {
  const node = makeNode(nodeData, tree, null, 0);
  tree.nodes.push(node);
  tree.tree.push(node);

  return node;
};

export const createNode = (parent: TreeNode, nodeData: any): TreeNode => {
  const node = makeNode(nodeData, parent.tree, parent, parent.depth + 1);
  parent.children.push(node);
  parent.tree.nodes.push(node);

  return node;
};

export const prepTree = ({ tree: treeNodes, ...treeData }: PrepTree): Tree => {
  const tree: Tree = {
    ...treeData,
    tree: [],
    nodes: [],
  };

  tree.tree = prepNodes(treeNodes, tree);
  tree.nodes = flattenNodes(tree.tree);

  return tree;
};

export const deleteNode = (node: TreeNode) => {
  const subtree = flattenNode(node);
  node.tree.nodes = node.tree.nodes.filter((node) => !subtree.includes(node));
  const target = node.parent ? node.parent.children : node.tree.tree;
  const idx = target.indexOf(node);
  if (idx > -1) {
    target.splice(idx, 1);
  }
  return node;
};

export const moveNode = (node: TreeNode, newParent?: TreeNode) => {
  if (node.parent) {
    const idx = node.parent.children.indexOf(node);
    node.parent.children.splice(idx, 1);
  }
  if (newParent) {
    newParent.children.push(node);
    node.parent = newParent;
  } else {
    node.parent = null;
  }
  return node;
};

export const renameNode = (node: TreeNode, label: string) => {
  node.label = label;

  return node;
};

export const ancestors = (node: TreeNode): TreeNode[] => {
  if (node.parent) {
    return [node, ...ancestors(node.parent)];
  }
  return [node];
};
