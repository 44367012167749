import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: '',
  props: true,
  name: 'assets.products.import.file',
  component: imp(
    () =>
      import(
        '@/pages/assets/modules/products/import/file/Main.vue' /* webpackChunkName: 'assets.products.inventory.file' */
      ),
  ),
  meta: {
    header: {
      label: 'Importa produse din fisier',
      route: 'assets.products.import.file',
    },
    breadcrumb: {
      label: 'Importa produse din fisier',
      route: 'assets.products.import.file',
    },
  },
  children: [],
};

export default route;
