import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import { Committee } from '@/pages/inventory/active/committee/Committee';

const header = {
  parentRoute: 'inventory.committee.index',
  label: ({ committee }: { committee: Committee }) =>
    committee
      ? `Vizualizare membru de comisie "${committee.lastName} ${committee.firstName}"`
      : 'Vizualizare membru de comisie',
  menu: (route: any) => [
    {
      route: {
        name: 'inventory.committee.edit',
        params: route.params,
      },
      separated: true,
      icon: 'edit',
      label: 'Editeaza',
    },
  ],
};

const route: UvRoute = {
  path: ':committeeId',
  meta: {
    header,
    permissions: ['read.committees'],
  },
  component: imp(
    () => import('@/pages/inventory/active/committee/view/Main.vue' /* webpackChunkName: 'inventory.committee' */),
  ),
  props: true,
  children: [
    {
      path: 'edit',
      name: 'inventory.committee.edit',
      props: true,
      meta: {
        permissions: ['update.committees'],
      },
      component: imp(
        () => import('@/pages/inventory/active/committee/view/Edit.vue' /* webpackChunkName: 'inventory.committee' */),
      ),
    },
  ],
};

export default route;
