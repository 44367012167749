import { UvRoute, createInjectionComponent } from '@/lib/router';
import { InventoryImportSource } from '@/pages/inventory/Inventory';
import mf from '@/pages/inventory/active/import/initial/lists/mf/routes';
import oi from '@/pages/inventory/active/import/initial/lists/oi/routes';

const route: UvRoute = {
  path: `import/${InventoryImportSource.INITIAL}`,
  props: true,
  name: `inventory.active.import.${InventoryImportSource.INITIAL}`,
  component: createInjectionComponent(),
  children: [mf, oi],
};

export default route;
