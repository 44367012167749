import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const routes: UvRoute[] = [
  {
    path: 'create',
    name: 'assets.maintenances.create',
    props: true,
    meta: {
      permissions: ['create.maintenances'],
    },
    component: imp(
      () =>
        import(
          '@/pages/assets/modules/maintenances/actions/Create.vue' /* webpackChunkName: 'assets.maintenances.main' */
        ),
    ),
  },
];

export default routes;
