import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const routes: UvRoute[] = [
  {
    path: 'edit/contract',
    name: 'assets.services.edit.contract',
    props: true,
    meta: {
      permissions: ['update.services'],
    },
    component: imp(
      () =>
        import('@/pages/assets/modules/services/view/edit/Contract.vue' /* webpackChunkName: 'assets.services.edit' */),
    ),
  },
  {
    path: 'edit/start-date',
    name: 'assets.services.edit.startDate',
    props: true,
    meta: {
      permissions: ['update.services'],
    },
    component: imp(
      () =>
        import(
          '@/pages/assets/modules/services/view/edit/StartDate.vue' /* webpackChunkName: 'assets.services.edit' */
        ),
    ),
  },
  {
    path: 'edit/reception-date',
    name: 'assets.services.edit.receptionDate',
    props: true,
    meta: {
      permissions: ['update.services'],
    },
    component: imp(
      () =>
        import(
          '@/pages/assets/modules/services/view/edit/ReceptionDate.vue' /* webpackChunkName: 'assets.services.edit' */
        ),
    ),
  },
  {
    path: 'edit/warranty',
    name: 'assets.services.edit.warranty',
    props: true,
    meta: {
      permissions: ['update.services'],
    },
    component: imp(
      () =>
        import('@/pages/assets/modules/services/view/edit/Warranty.vue' /* webpackChunkName: 'assets.services.edit' */),
    ),
  },
  {
    path: 'edit/supplier',
    name: 'assets.services.edit.supplier',
    props: true,
    meta: {
      permissions: ['update.services'],
    },
    component: imp(
      () =>
        import('@/pages/assets/modules/services/view/edit/Supplier.vue' /* webpackChunkName: 'assets.services.edit' */),
    ),
  },
  {
    path: 'edit/photos',
    name: 'assets.services.edit.photos',
    component: imp(
      () =>
        import('@/pages/assets/modules/services/view/edit/Photos.vue' /* webpackChunkName: 'assets.services.edit' */),
    ),
    meta: {
      permissions: ['update.services'],
    },
  },
  {
    path: 'edit/files',
    name: 'assets.services.edit.files',
    component: imp(
      () =>
        import('@/pages/assets/modules/services/view/edit/Files.vue' /* webpackChunkName: 'assets.services.edit' */),
    ),
    meta: {
      permissions: ['update.services'],
    },
  },
];

export default routes;
