import Confirm from '@/components/VuetifyConfirm.vue';
import _Vue, { PluginObject } from 'vue';
import Vuetify from 'vuetify';
import vuetify from '@/plugins/vuetify';

interface DialogPluginOptions {
  vuetify: Vuetify;
  property?: string;
}

interface DialogOptions {
  message?: string;
  title?: string;
  color?: string;
  icon?: string;
  buttonTrueColor?: string;
}

export interface ShowConfirmationDialog {
  (message: string, options?: Omit<DialogOptions, 'message'>): Promise<boolean>;
  warning: (message: string, options?: Omit<DialogOptions, 'message'>) => Promise<boolean>;
  danger: (message: string, options?: Omit<DialogOptions, 'message'>) => Promise<boolean>;
}

export interface ConfirmDialog {
  (Vue: typeof _Vue, options: DialogPluginOptions): ShowConfirmationDialog;
}

export const makeConfirmDialog: ConfirmDialog = (Vue, { vuetify, ...options }) => {
  if (!vuetify) {
    console.warn('Module vuetify-confirm needs vuetify instance. Use Vue.use(VuetifyConfirm, { vuetify })');
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const Ctor = Vue.extend(Object.assign({ vuetify, value: false }, Confirm));

  function createDialogCmp(opts: DialogOptions): Promise<boolean> {
    const container = document.querySelector('[data-app=true]') || document.body;
    return new Promise((resolve) => {
      const cmp = new Ctor({
        propsData: Object.assign({}, options, opts),
        updated: () => {
          container.removeChild(cmp.$el);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          resolve(cmp.value);
        },
      });

      container.appendChild(cmp.$mount().$el);
    });
  }

  function show(message: string, options: Omit<DialogOptions, 'message'> = {}) {
    return createDialogCmp({
      message,
      ...options,
    });
  }

  show.warning = (message: string, options: Omit<DialogOptions, 'message'> = {}) => {
    const { title = 'Atentie', ..._options } = options;

    return show(message, {
      ..._options,
      title,
      color: 'orange darken-2',
      icon: 'warning',
      buttonTrueColor: 'warning',
    });
  };

  show.danger = (message: string, options: Omit<DialogOptions, 'message'> = {}) => {
    const { title = 'Atentie', ..._options } = options;

    return show(message, {
      ..._options,
      title,
      color: 'red darken-4',
      icon: 'error',
      buttonTrueColor: 'error',
    });
  };

  return show;
};

export const plugin: PluginObject<DialogPluginOptions> = {
  install(Vue, options): void {
    if (!options) {
      throw new Error('you have to pass options in here.');
    }
    const property = options.property || '$confirm';
    delete options.property;

    Vue.prototype[property] = makeConfirmDialog(Vue, options);
  },
};

export const useConfirm = () => {
  return makeConfirmDialog(_Vue, { vuetify });
};
