import api from '@/api';
import { AuthUser, extractUserJwtData, getExpiryTimeout } from '@/lib/auth';
import { Application } from '@/types/ApiResponses';
import { Ability, AbilityBuilder } from '@casl/ability';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '.';

export type Permission = string;

export interface AuthState {
  error: Error | null;
  errors: string[];
  initializing: boolean;
  submitting: boolean;
  refreshing: boolean;
  token: string | null;
  user: AuthUser | null;
  applications: Application[];
  ability: null | Ability;
  permissions: Permission[];
}

export interface PermissionPayload {
  resource: string;
  action: string;
}

export interface AuthorizationPayload {
  authorizationToken: string;
  permissions: string[];
  applications: Application[];
}

interface RefreshOptions {
  silent?: boolean;
}

const expiryTimeout = getExpiryTimeout({
  preceding: 5, // 5 seconds
});

let authorizationTimer: number;

const state: AuthState = {
  error: null,
  errors: [],
  user: null,
  initializing: true,
  submitting: false,
  refreshing: false,
  token: null,
  applications: [],
  ability: null,
  permissions: [],
};

const getters: GetterTree<AuthState, RootState> = {
  isGuest: (state) => !state.token,
  can: (state) => (what: string | string[]) => {
    const permissions = Array.isArray(what) ? what : [what];

    return state.user && permissions.some((permission) => state.permissions.includes(permission));
  },
};

const mutations: MutationTree<AuthState> = {
  authorize: (state, { authorizationToken, permissions, applications }: AuthorizationPayload) => {
    // console.log(`Setting token to ${authorizationToken}`);
    state.user = extractUserJwtData(authorizationToken);
    state.token = authorizationToken;
    const { can, build } = new AbilityBuilder(Ability);
    permissions.forEach((permission) => {
      const [subject, action] = permission.split('.');
      can(action, subject);
    });
    state.ability = build();
    state.permissions = permissions;
    state.applications = applications;
  },
  beginInitialization: (state) => {
    state.initializing = true;
  },
  submit: (state) => {
    state.error = null;
    state.errors = [];
    state.submitting = true;
  },
  refresh: (state) => {
    state.error = null;
    state.refreshing = true;
  },
  setError: (state, error) => {
    state.error = error;
  },
  setErrors: (state, errors) => {
    state.errors = errors;
  },
  finish: (state) => {
    state.initializing = false;
    state.submitting = false;
    state.refreshing = false;
  },
  logout: (state) => {
    state.token = null;
    state.user = null;
    state.applications = [];
    state.permissions = [];
  },
};

const actions: ActionTree<AuthState, RootState> = {
  login: ({ commit, dispatch }, { email, password }) =>
    Promise.resolve(commit('submit'))
      .then(() =>
        api.auth.login({
          body: {
            email,
            password,
          },
        }),
      )
      .then((response) =>
        response.json().then((payload: any) => {
          if (response.ok) {
            dispatch('authorize', payload.data);
          } else if (response.status === 422) {
            commit('setErrors', payload.errors);
            return Promise.reject(new Error(payload.error));
          } else {
            return Promise.reject(new Error(payload.error));
          }
        }),
      )
      .catch((e) => commit('setError', e))
      .finally(() => commit('finish')),
  initialize: ({ commit, dispatch }) => {
    commit('beginInitialization');

    return dispatch('refresh', { silent: true });
  },
  refresh: ({ commit, dispatch }, { silent = false }: RefreshOptions = {}) =>
    Promise.resolve(commit('refresh'))
      .then(() => api.auth.refresh())
      .then((response) => {
        if (silent && response.status === 401) {
          return;
        }

        return response.json().then((payload: any) => {
          if (response.ok) {
            return dispatch('authorize', payload.data);
          } else {
            return Promise.reject(new Error(payload.error));
          }
        });
      })
      .catch((e) => {
        commit('logout');
        commit('setError', e);
      })
      .finally(() => commit('finish')),
  authorize: async ({ commit, dispatch, state }, payload: AuthorizationPayload) => {
    commit('authorize', payload);

    if (authorizationTimer) {
      clearTimeout(authorizationTimer);
    }

    if (!state.token || !state.user) {
      return;
    }

    const timeout = expiryTimeout(new Date(), state.user.exp);

    authorizationTimer = setTimeout(() => dispatch('refresh'), timeout < 0 ? 0 : timeout);
  },
  logout: ({ commit }) =>
    api.auth
      .logout()
      .then((response) => {
        if (response.ok || response.status === 401) {
          commit('logout');
        } else {
          return response.json().then((payload: any) => {
            if (response.ok || response.status === 401) {
              commit('logout');
            } else {
              return Promise.reject(new Error(payload.error));
            }
          });
        }
      })
      .catch((e) => {
        commit('setError', e);
      }),
};

export const module: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
