import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import index from '@/pages/institution/users/index/routes';
import view from '@/pages/institution/users/view/routes';

const route: UvRoute = {
  path: 'users',
  component: imp(() => import('@/pages/institution/users/Main.vue' /* webpackChunkName: 'institution.users.index' */)),
  meta: {
    permissions: ['read.users'],
    header: {
      route: 'institution.users.index',
      label: 'Utilizatori',
      menu: () => [
        {
          label: 'Adauga utilizator',
          icon: 'add',
          separated: true,
          route: {
            name: 'institution.users.create',
          },
        },
      ],
    },
    breadcrumb: {
      label: 'Utilizatori',
      route: 'institution.users.index',
    },
  },
  children: [index, view],
};

export default route;
