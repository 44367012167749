import api from '@/api';
import { isPromise } from '@/lib/tools';
import { PathDefinition } from '@/lib/vuex-serializer';
import Prando from 'prando';
import { Module } from 'vuex';
import { RootState } from '.';
import { AssetsModule } from '@/store/assets';

export type AssetsModuleListing = Omit<AssetsModule, 'tree' | 'nodes'>;

export interface ModulesState {
  promise: Promise<void> | null;
  loading: boolean;
  list: AssetsModuleListing[];
  error: Error | null;
}

const colors = [
  '#F44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196F3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4CAF50',
  '#8bc34a',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
];

export const initModule = ({ id, label }: Omit<AssetsModuleListing, 'color'>): AssetsModuleListing => ({
  id,
  label,
  color: colors[new Prando(id).nextInt(0, colors.length - 1)],
});

type PersistedModulesState = Pick<ModulesState, 'list'>;
type LiveModulesState = Pick<ModulesState, 'list' | 'promise'>;

const defModuleStorage = {
  list: [],
  promise: null,
};

export const serializer: PathDefinition<PersistedModulesState, LiveModulesState, PersistedModulesState> = {
  path: 'modules',
  sync: true,
  def: defModuleStorage,
  get: (value) => {
    if (value === undefined) {
      return undefined;
    }
    const { list } = value;
    if (!Array.isArray(list)) {
      return undefined;
    }

    return {
      list,
      promise: Promise.resolve(),
    };
  },
  set: (data: LiveModulesState): PersistedModulesState => {
    if (!Array.isArray(data.list)) {
      return defModuleStorage;
    }

    return {
      list: data.list,
    };
  },
};

export const module: Module<ModulesState, RootState> = {
  namespaced: true,
  state: {
    promise: null,
    loading: true,
    error: null,
    list: [],
  },
  mutations: {
    startLoading: (state, promise) => {
      state.loading = true;
      state.promise = promise;
      state.error = null;
    },
    setError: (state, error: Error) => {
      state.error = error;
    },
    setModules: (state, modules: any[]) => {
      state.list = modules.map((m) => initModule(m));
    },
    stopLoading: (state) => {
      state.loading = false;
    },
  },
  actions: {
    load: ({ commit, state, rootState }): Promise<void> => {
      if (!rootState.auth.user) {
        return Promise.resolve();
      }
      if (!rootState.auth.user.institutionId) {
        return Promise.resolve();
      }
      if (!rootState.auth.ability?.can('read', 'asset-modules')) {
        return Promise.resolve();
      }
      if (isPromise(state.promise)) {
        return state.promise;
      }

      const promise = api.assets.modules
        .index()
        .then((response) =>
          response
            .json()
            .then((payload) =>
              response.ok ? commit('setModules', payload.data) : Promise.reject(new Error(payload.error)),
            ),
        );

      commit('startLoading', promise);

      return promise
        .catch((e) => {
          commit('setError', e);

          return Promise.reject(e);
        })
        .finally(() => {
          commit('stopLoading');
        });
    },
  },
};
