import imp from '@/lib/imp';
import api from '@/api';
import header from '@/pages/admin/users/header';
import { UvRoute } from '@/lib/router';
import roles from '@/pages/admin/users/view/roles/routes';

const route: UvRoute = {
  path: 'users',
  component: imp(() => import('@/pages/admin/users/Main.vue' /* webpackChunkName: 'admin.users.index' */)),
  meta: {
    permissions: ['manage.users'],
    header: {
      route: 'admin.users.index',
      label: 'Utilizatori',
      menu: () => [
        {
          label: 'Adauga utilizator',
          separated: true,
          icon: 'add',
          route: {
            name: 'admin.users.create',
          },
        },
      ],
    },
    breadcrumb: {
      label: 'Utilizatori',
      route: 'admin.users.index',
    },
  },
  children: [
    {
      path: '',
      name: 'admin.users.index',
      component: imp(() => import('@/pages/admin/users/index/Main.vue' /* webpackChunkName: 'admin.users.index' */)),
      children: [
        {
          path: 'create',
          name: 'admin.users.create',
          meta: {
            permissions: ['manage.users'],
          },
          component: imp(
            () => import('@/pages/admin/users/index/actions/Create.vue' /* webpackChunkName: 'admin.users.index' */),
          ),
        },
      ],
    },
    {
      path: ':userId',
      component: imp(() => import('@/pages/admin/users/view/Main.vue' /* webpackChunkName: 'admin.users.show' */)),
      meta: {
        permissions: ['manage.users'],
      },
      props: (route) => ({
        userId: route.params.userId,
      }),
      children: [
        {
          path: '',
          name: 'admin.users.show',
          component: imp(() => import('@/pages/admin/users/view/Show.vue' /* webpackChunkName: 'admin.users.show' */)),
          meta: {
            header,
            breadcrumb: {
              label: ({ user }) => user || 'Utilizator',
            },
          },
          children: [
            {
              path: 'edit/name',
              name: 'admin.users.edit.name',
              props: {
                submitFn: api.admin.users.edit.name,
              },
              meta: {
                permissions: ['manage.users'],
              },
              component: imp(() => import('@/components/users/edit/Name.vue' /* webpackChunkName: 'users.edit' */)),
            },
            {
              path: 'edit/password',
              name: 'admin.users.edit.password',
              meta: {
                permissions: ['manage.users'],
              },
              props: {
                submitFn: api.admin.users.edit.password,
              },
              component: imp(() => import('@/components/users/edit/Password.vue' /* webpackChunkName: 'users.edit' */)),
            },
            {
              path: 'edit/email',
              name: 'admin.users.edit.email',
              meta: {
                permissions: ['manage.users'],
              },
              props: {
                submitFn: api.admin.users.edit.email,
              },
              component: imp(() => import('@/components/users/edit/Email.vue' /* webpackChunkName: 'users.edit' */)),
            },
            {
              path: 'institution',
              name: 'admin.users.institution',
              meta: {
                permissions: ['manage.users'],
              },
              component: imp(
                () =>
                  import(
                    '@/pages/admin/users/view/institution/Main.vue' /* webpackChunkName: 'admin.users.institution' */
                  ),
              ),
            },
          ],
        },
        roles,
      ],
    },
  ],
};

export default route;
