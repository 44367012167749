import { Permission } from '@/store/auth';
import { AssetsModuleListing } from '@/store/modules';
import { Application } from '@/types/ApiResponses';
import { Ability } from '@casl/ability';
import { identity, map } from 'rambda';
import {
  VDivider,
  VIcon,
  VList,
  VListItem,
  VListItemContent,
  VListItemTitle,
  VListItemAction,
  VListItemActionText,
} from 'vuetify/lib';
import { mapActions, mapState } from 'vuex';

type _this = Vue & {
  loadModules: () => Promise<void>;
  applications: Application[];
  permissions: Permission[];
  ability: Ability;
  assetsModules: AssetsModuleListing[];
};

export default {
  components: {
    VDivider,
    VIcon,
    VList,
    VListItem,
    VListItemContent,
    VListItemTitle,
    VListItemAction,
    VListItemActionText,
  },
  computed: mapState({
    applications: (s: any) => s.auth.applications,
    permissions: (s: any) => s.auth.permissions,
    ability: (s: any) => s.auth.ability,
    assetsModules: (s: any) => s.modules.list,
  }),
  methods: mapActions({
    loadModules: 'modules/load',
  }),
  created(this: _this) {
    this.loadModules();
  },
  render(this: _this, h: any) {
    interface NavigationItem {
      label: string;
      icon: string;
      iconColor?: string;
      route: { name: string };
      permissions: string[];
    }

    interface NavigationSection {
      label: string;
      collapsable?: boolean;
      children: NavigationItem[];
    }

    const administrator = {
      label: 'Administrator sistem',
      children: [
        {
          label: 'Utilizatori',
          icon: 'people',
          route: { name: 'admin.users.index' },
          permissions: ['manage.users'],
        },
        {
          label: 'Roluri',
          icon: 'perm_identity',
          route: { name: 'admin.roles.index' },
          permissions: ['manage.roles'],
        },
        {
          label: 'Institutii',
          icon: 'domain',
          route: { name: 'admin.institutions.index' },
          permissions: ['manage.institutions'],
        },
        {
          label: 'Module GIS',
          icon: 'account_tree',
          route: { name: 'admin.modules.index' },
          permissions: ['manage.asset-modules'],
        },
        {
          label: 'Grupuri si campuri',
          icon: 'android',
          route: { name: 'admin.field-groups.index' },
          permissions: ['manage.field-groups'],
        },
        {
          label: 'Inregistrari',
          icon: 'feed',
          route: { name: 'admin.logs.index' },
          permissions: ['manage.logs'],
        },
        {
          label: 'Setari',
          icon: 'settings',
          route: { name: 'admin.settings.index' },
          permissions: ['manage.settings'],
        },
      ],
    } as NavigationSection;

    // const registry = {
    //   label: 'Registratura',
    //   children: []
    // };
    //
    // if (this.applications.some(app => app.id === 'registry-reii')) {
    //   registry.children = [
    //     {
    //       label: 'Registratura',
    //       icon: 'local_library',
    //       route: { name: 'registry.index' },
    //       permissions: ['registry.crud.read']
    //     }
    //   ];
    // }

    const applications = {
      label: 'Aplicatii',
      children: [],
    } as NavigationSection;

    if (this.applications.some((app) => app.id === 'inventory')) {
      applications.children.push({
        label: 'Invent',
        icon: 'assignment',
        route: { name: 'inventory' },
        permissions: [
          'read.inventories',
          'read.scriptic-items',
          'read.receptions',
          'read.quashes',
          'read.inventories-history',
          'read.print-templates',
          'read.org-charts',
          'read.committees',
        ],
      });
    }

    if (this.applications.some((app) => app.id === 'incidents')) {
      applications.children.push({
        label: 'Incidente',
        icon: 'car_crash',
        route: { name: 'incidents' },
        permissions: ['read.incidents', 'read.incidents-citizen-reports'],
      });
    }

    if (this.applications.some((app) => app.id === 'urbanism')) {
      applications.children.push({
        label: 'Urbanism',
        icon: 'domain',
        route: { name: 'urbanism' },
        permissions: ['read.regulations', 'read.subdivision-types'],
      });
    }

    const institutionAdmin = {
      label: 'Administrare institutie',
      children: [
        {
          label: 'Utilizatori',
          icon: 'people',
          route: { name: 'institution.users.index' },
          permissions: ['read.users'],
        },
        {
          label: 'Furnizori',
          icon: 'business_center',
          route: { name: 'institution.suppliers.index' },
          permissions: ['read.suppliers'],
        },
        {
          label: 'Adrese',
          icon: 'house',
          route: { name: 'maps.streets.index' },
          permissions: ['read.maps'],
        },
      ],
    } as NavigationSection;

    const modules = {
      label: 'Module GIS',
      icon: 'work',
      route: { name: 'assets.modules.index' },
      collapsable: true,
      children: [],
    } as NavigationSection;

    if (this.applications.some((app) => app.id === 'gis')) {
      modules.children = [
        {
          label: 'Harta GIS',
          icon: 'explore',
          permissions: ['read.asset-modules'],
          route: {
            name: 'assets.map.show',
          },
        },
        ...this.assetsModules.map(({ id, label, color }) => ({
          label,
          icon: 'extension',
          iconColor: color,
          permissions: ['read.asset-modules'],
          route: {
            name: 'assets.modules.show',
            params: {
              moduleId: id,
            },
          },
        })),
      ];
    }

    const navigationItems = [
      administrator,
      // registry,
      applications,
      modules,
      institutionAdmin,
    ]
      .map((item) => ({
        label: item.label,
        children: item.children.filter((child) =>
          child.permissions.some((permission) => {
            const [action, subject] = permission.split('.');
            return this.ability.can(action, subject);
          }),
        ),
      }))
      .filter((item) => item.children.length > 0);

    const createSimpleItem = (item: NavigationItem) =>
      h('v-list-item', { props: { to: item.route } }, [
        h('v-list-item-action', [h('v-icon', item.icon)]),
        h('v-list-item-content', [h('v-list-item-title', item.label)]),
      ]);

    const createChild = (child: NavigationItem) =>
      h(
        'v-list-item',
        {
          props: { to: child.route },
        },
        [
          h('v-list-item-action', [h('v-icon', { props: { color: child.iconColor } }, child.icon)]),
          h('v-list-item-content', [h('v-list-item-title', { domProps: { class: 'text-wrap' } }, child.label)]),
        ],
      );

    const createNestedItem = (item: NavigationSection) =>
      item.collapsable
        ? [
            h(
              'v-list-group',
              {
                props: {
                  value: item.children.length < 5,
                },
              },
              [
                h(
                  'v-list-item-content',
                  {
                    slot: 'activator',
                    props: { disabled: true },
                  },
                  [h('v-list-item-title', item.label)],
                ),
                ...map(createChild, item.children),
              ],
            ),
          ]
        : [
            h(
              'v-list-item',
              {
                props: { disabled: true },
              },
              [h('v-list-item-content', [h('v-list-item-title', item.label)])],
            ),
            ...map(createChild, item.children),
          ];

    const createItem = (item: NavigationSection | NavigationItem, k: number) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      item.children ? [k > 0 ? h('v-divider') : undefined, ...createNestedItem(item)] : [createSimpleItem(item)];

    return h('v-list', navigationItems.flatMap(createItem).filter(identity));
  },
};
