<template>
  <v-breadcrumbs :items="bc">
    <template #divider>
      <v-icon>chevron_right</v-icon>
    </template>
    <template #item="{ item }">
      <text-item
        :meta="item.meta.breadcrumb"
        :params="item.params"
        :route-name="item.name"
      />
    </template>
  </v-breadcrumbs>
</template>
<script lang="ts">
import TextItem from '@/components/pages/breadcrumbs/TextItem.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BreadCrumbs',
  components: {
    TextItem,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    bc() {
      return this.items.map(({ name, params, meta }: any) => ({
        name,
        params,
        meta,
      }));
    },
  },
});
</script>
