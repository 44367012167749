import { pattern as iso8601Pattern } from 'iso8601-duration';

export const hasProperty = (obj: object, prop: string): boolean => {
  let currentObj: any = { ...obj };

  return prop.split('.').every((prop) => {
    if (currentObj.hasOwnProperty(prop) === false) {
      return false;
    }

    currentObj = currentObj[prop];
    return true;
  });
};

export const isNumber = (s: unknown): s is number => typeof s === 'number' || Number.isFinite(s);

export const isBoolean = (s: unknown): s is boolean => typeof s === 'boolean';

export const isString = (s: unknown): s is string => typeof s === 'string' || s instanceof String;

export const isObject = (o: unknown): o is object =>
  o !== undefined && o !== null && Object.prototype === Object.getPrototypeOf(o);

export const isPromise = <T>(p: unknown): p is Promise<T> => p !== null && Promise.resolve(p) === p;

// eslint-disable-next-line @typescript-eslint/ban-types
export const isFn = (fn: unknown): fn is Function => fn !== null && fn instanceof Function;

export const ucfirst = (s: string) => (s.length > 0 ? s.charAt(0).toUpperCase() + s.slice(1) : s);

export const ObjectId = () => {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => ((Math.random() * 16) | 0).toString(16)).toLowerCase();
};

const randomCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
export const randomChars = (length: number): string => {
  let result = '';

  for (let i = 0; i < length; i++) {
    result += randomCharacters.charAt(Math.floor(Math.random() * randomCharacters.length));
  }

  return result;
};

export const rkey = (success = true): { rkey?: string } => (success ? { rkey: new Date().getTime().toString() } : {});

export const using = <T, U>(val: T, cb: (val: T) => U): U => cb(val);

export const value = <T>(val: T, def: T | (() => T), compareFn?: (val: T) => boolean): T =>
  (compareFn instanceof Function ? compareFn : (val: T) => val !== undefined)(val)
    ? val
    : def instanceof Function
      ? def()
      : def;

export const bytesToMegaBytes = (bytes: number) => parseFloat((bytes / 1048576).toFixed(2));
export const bytesToGigaBytes = (bytes: number) => parseFloat((bytes / 1073741824).toFixed(2));

export const properISO8601Pattern = (() => {
  const [pattern, flags] = iso8601Pattern.toString().split('/').slice(1);

  return new RegExp(`^${pattern}$`, flags);
})();
