import imp from '@/lib/imp';
import view from '@/pages/urbanism/regulation/view/routes';
import { UvRoute } from '@/lib/router';

const routes: UvRoute = {
  path: 'regulations',
  props: true,
  component: imp(() => import('@/pages/urbanism/regulation/Main.vue' /* webpackChunkName: 'urbanism.regulations' */)),
  meta: {
    header: {
      label: 'Reglementari',
      route: 'urbanism.regulations.index',
      menu: () => [
        {
          label: 'Adauga reglementare',
          icon: 'add',
          separated: true,
          route: {
            name: 'urbanism.regulations.create',
          },
          permissions: ['create.regulations'],
        },
      ],
    },
    breadcrumb: {
      label: 'Reglementari',
      route: 'urbanism.regulations.index',
    },
  },
  children: [
    {
      path: '/',
      props: true,
      component: imp(
        () => import('@/pages/urbanism/regulation/List.vue' /* webpackChunkName: 'urbanism.regulations' */),
      ),
      name: 'urbanism.regulations.index',
    },
    {
      path: 'create',
      props: true,
      component: imp(
        () => import('@/pages/urbanism/regulation/actions/Create.vue' /* webpackChunkName: 'urbanism.regulations' */),
      ),
      name: 'urbanism.regulations.create',
      meta: {
        header: {
          label: 'Reglementare noua',
        },
        breadcrumb: {
          label: 'Reglementare noua',
          route: false,
        },
      },
    },
    view,
  ],
};

export default routes;
