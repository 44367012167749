import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import actions from '@/pages/assets/modules/products/view/inventory/items/actions/routes';
import view from '@/pages/assets/modules/products/view/inventory/items/view/routes';

const route: UvRoute = {
  path: 'inventory',
  props: true,
  name: 'assets.products.inventory.items.index',
  component: imp(
    () =>
      import(
        '@/pages/assets/modules/products/view/inventory/List.vue' /* webpackChunkName: 'assets.products.inventory' */
      ),
  ),
  meta: {
    header: {
      label: ({ product }) => (product ? `Inventar produs "${product.name}"` : 'Inventar produs'),
      route: 'assets.products.inventory.index',
      menu: () => [
        {
          label: 'Adauga',
          icon: 'add',
          route: {
            name: 'assets.products.inventory.items.create',
          },
          permissions: ['update.products'],
        },
      ],
    },
    breadcrumb: {
      label: 'Inventar',
      route: 'assets.products.inventory.items.index',
    },
    permissions: ['read.products'],
  },
  children: [...actions, view],
};
export default route;
