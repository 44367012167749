import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const meta = {
  breadcrumb: {
    label: 'Sabloane tiparire',
    route: 'inventory.active.print-templates',
  },
  header: {
    label: 'Sabloane tiparire',
    parentRoute: 'inventory.active',
    route: 'inventory.active.print-templates',
    menu: () => [
      {
        label: 'Adauga sablon',
        icon: 'add',
        route: {
          name: 'inventory.active.print-templates.create',
        },
      },
    ],
  },
};

const route: UvRoute = {
  path: 'print-templates',
  name: 'inventory.active.print-templates',
  meta,
  component: imp(
    () =>
      import(
        '@/pages/inventory/active/print-templates/Main.vue' /* webpackChunkName: 'inventory.active.print-templates' */
      ),
  ),
  props: true,
  children: [
    {
      path: 'create',
      name: 'inventory.active.print-templates.create',
      component: imp(
        () =>
          import(
            '@/pages/inventory/active/print-templates/actions/Create.vue' /* webpackChunkName: 'inventory.active.print-templates' */
          ),
      ),
      props: true,
    },
    {
      path: ':templateId',
      component: imp(
        () =>
          import(
            '@/pages/inventory/active/print-templates/actions/edit/Main.vue' /* webpackChunkName: 'inventory.active.print-templates' */
          ),
      ),
      props: true,
      children: [
        {
          path: 'edit',
          name: 'inventory.active.print-templates.edit',
          component: imp(
            () =>
              import(
                '@/pages/inventory/active/print-templates/actions/edit/Dialog.vue' /* webpackChunkName: 'inventory.active.print-templates' */
              ),
          ),
          props: true,
        },
      ],
    },
  ],
};

export default route;
