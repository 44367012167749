import { Module } from 'vuex';
import { RootState } from '.';

export interface PageDepsState {
  deps: Array<[string, any]>;
}

export const module: Module<PageDepsState, RootState> = {
  namespaced: true,
  state: {
    deps: [],
  },
  getters: {
    deps: (state) => Object.fromEntries(state.deps),
  },
  mutations: {
    set: (state, { dep, value }) => {
      const idx = state.deps.findIndex(([d]) => d === dep);

      if (idx > -1) {
        state.deps.splice(idx, 1, [dep, value]);
      } else {
        state.deps.push([dep, value]);
      }
    },
  },
};
