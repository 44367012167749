import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import api from '@/api';

const route: UvRoute = {
  path: 'quashes',
  name: 'inventory.history.quashes',
  component: imp(
    () => import('@/pages/inventory/history/view/quashes/Main.vue' /* webpackChunkName: 'inventory.history.quashes' */),
  ),
  props: true,
  meta: {
    header: {
      label: 'Lista casari',
      route: 'inventory.history.quashes',
    },
    breadcrumb: {
      label: 'Lista casari',
      route: 'inventory.history.quashes',
    },
  },
  children: [
    {
      path: ':itemId',
      name: 'inventory.history.quashes.show',
      component: imp(
        () =>
          import(
            '@/pages/inventory/history/view/common/ShowUpdate.vue' /* webpackChunkName: 'inventory.history.quashes' */
          ),
      ),
      props: (route) => ({
        ...route.params,
        parentRoute: 'inventory.history.quashes',
        loadApi: api.inventory.history.quashes.show,
      }),
    },
  ],
};

export default route;
