import imp from '@/lib/imp';
import { createInjectionComponent, UvRoute, UvRouteHeader } from '@/lib/router';
import actions from '@/pages/assets/modules/flows/actions/routes';
import view from '@/pages/assets/modules/flows/view/routes';

const header: UvRouteHeader = {
  label: 'Fluxuri',
  route: 'assets.flows.index',
  menu: () => [
    {
      label: 'Adauga flux',
      icon: 'add',
      separated: true,
      route: {
        name: 'assets.flows.create',
      },
      permissions: ['create.flows'],
    },
  ],
};

const route: UvRoute = {
  path: 'flows',
  props: true,
  component: createInjectionComponent(),
  meta: {
    breadcrumb: {
      label: 'Fluxuri',
      route: 'assets.flows.index',
    },
    header,
  },
  children: [
    {
      path: '',
      name: 'assets.flows.index',
      props: true,
      meta: {
        permissions: ['read.flows'],
      },
      component: imp(() => import('@/pages/assets/modules/flows/List.vue' /* webpackChunkName: 'assets.flows.main' */)),
      children: actions,
    },
    view,
  ],
};

export default route;
