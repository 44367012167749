import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: ':maintenanceId',
  props: true,
  component: imp(
    () =>
      import('@/pages/assets/modules/maintenances/view/Main.vue' /* webpackChunkName: 'assets.maintenances.main' */),
  ),
  meta: {
    header: {
      label: ({ maintenance }) =>
        maintenance ? `Vizualizare mentenanta "${maintenance.name}"` : 'Vizualizare mentenanta',
      route: 'assets.maintenances.show',
    },
    breadcrumb: {
      label: ({ maintenance }) => (maintenance ? maintenance.name : 'Mentenanta'),
      route: 'assets.maintenances.show',
    },
  },
  children: [
    {
      path: '',
      name: 'assets.maintenances.show',
      props: true,
      component: imp(
        () =>
          import(
            '@/pages/assets/modules/maintenances/view/Show.vue' /* webpackChunkName: 'assets.maintenances.main' */
          ),
      ),
      children: [
        {
          path: 'edit/name',
          name: 'assets.maintenances.edit.name',
          props: true,
          meta: {
            permissions: ['update.maintenances'],
          },
          component: imp(
            () =>
              import(
                '@/pages/assets/modules/maintenances/view/edit/Name.vue' /* webpackChunkName: 'assets.maintenances.edit' */
              ),
          ),
        },
        {
          path: 'archive',
          name: 'assets.maintenances.archive',
          props: true,
          meta: {
            permissions: ['update.maintenances'],
          },
          component: imp(
            () =>
              import(
                '@/pages/assets/modules/maintenances/view/Archive.vue' /* webpackChunkName: 'assets.maintenances.archive' */
              ),
          ),
        },
      ],
    },
  ],
};

export default route;
