interface Delayer {
  <V>(v: V): Promise<V>;
  (): Promise<void>;
}

export const delay = (ms: number): Delayer => {
  function _delay(): Promise<void>;
  function _delay<V>(v: V): Promise<V>;

  function _delay(v?: unknown) {
    return new Promise((resolve) => setTimeout(() => resolve(v), ms));
  }

  return _delay;
};

interface Promizorg<T> {
  (): Promise<T>;
}

export function normalize(ms: number): <T>(p: Promizorg<T>) => Promise<T>;
export function normalize<T>(ms: number, p: Promizorg<T>): Promise<T>;

export function normalize<T>(ms: number, fn?: Promizorg<T>) {
  if (fn === undefined) {
    return <T>(_fn: Promizorg<T>): Promise<T> => normalize(ms, _fn);
  } else {
    const start = Date.now();

    return fn().then((value) => {
      const elapsed = Date.now() - start;

      return elapsed > ms ? value : new Promise((resolve) => setTimeout(() => resolve(value), ms - elapsed));
    });
  }
}
