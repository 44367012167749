import imp from '@/lib/imp';
import { UvRoute, createInjectionComponent } from '@/lib/router';

const route: UvRoute = {
  path: 'quantities',
  props: true,
  component: createInjectionComponent(['item']),
  children: [
    {
      path: 'create',
      name: 'inventory.scriptic.quantities.create',
      component: imp(
        () =>
          import(
            '@/pages/inventory/active/scriptic/view/quantities/Create.vue' /* webpackChunkName: 'inventory.active.scriptic' */
          ),
      ),
    },
    {
      path: ':quantityId',
      component: imp(
        () =>
          import(
            '@/pages/inventory/active/scriptic/view/quantities/View.vue' /* webpackChunkName: 'inventory.active.scriptic' */
          ),
      ),
      props: true,
      children: [
        {
          path: 'edit',
          name: 'inventory.scriptic.quantities.edit',
          component: imp(
            () =>
              import(
                '@/pages/inventory/active/scriptic/view/quantities/Edit.vue' /* webpackChunkName: 'inventory.active.scriptic' */
              ),
          ),
        },
      ],
    },
  ],
};

export default route;
