import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import { CreateElement } from 'vue';

const route: UvRoute = {
  path: 'reports',
  meta: {
    breadcrumb: { label: 'Rapoarte' },
    header: { label: 'Rapoarte' },
  },
  component: {
    render: (h: CreateElement) => h('router-view'),
  },
  children: [
    {
      path: '',
      name: 'reports',
      meta: {
        header: {
          label: 'Rapoarte',
          parentRoute: 'inventory',
        },
      },
      component: imp(() => import('@/pages/reports/Main.vue' /* webpackChunkName: 'reports' */)),
    },
    {
      path: 'inventory/:reportType(mf|oi)',
      name: 'reports.inventory',
      props: true,
      meta: {
        header: {
          label: (deps) => `Inventar ${deps.reportTypeLabel || '-'}`,
          parentRoute: 'reports',
        },
        breadcrumb: {
          label: (deps) => `Inventar ${deps.reportTypeLabel || '-'}`,
        },
      },
      component: imp(
        () => import('@/pages/reports/inventory/MainReport.vue' /* webpackChunkName: 'reports.inventory' */),
      ),
    },
    {
      path: 'inventory/org-chart',
      name: 'reports.inventory.org-chart',
      props: true,
      meta: {
        header: {
          label: 'Inventar faptic pe noduri',
          parentRoute: 'reports',
        },
        breadcrumb: {
          label: 'Inventar faptic pe noduri',
        },
      },
      component: imp(
        () => import('@/pages/reports/inventory/OrgChartReport.vue' /* webpackChunkName: 'reports.inventory' */),
      ),
    },
    {
      path: 'institution/user-activity',
      name: 'reports.institution.user-activity',
      meta: {
        breadcrumb: { label: 'Raport activitate utilizator' },
        header: {
          label: 'Raport activitate utilizator',
          parentRoute: 'reports',
        },
      },
      component: imp(
        () => import('@/pages/reports/institution/UserActivity.vue' /* webpackChunkName: 'reports.institution' */),
      ),
    },
  ],
};

export default route;
