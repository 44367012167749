<template>
  <v-list-item
    v-on="item.on"
    v-bind="item.props"
    ripple
  >
    <v-list-item-avatar v-if="!!item.icon">
      <v-icon>{{ item.icon }}</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="text-center">
        <strong>{{ item.label }}</strong>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
});
</script>
