import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';

const route: UvRoute = {
  path: 'unverified',
  name: 'institution.suppliers.unverified',
  component: imp(
    () =>
      import('@/pages/institution/suppliers/unverified/Main.vue' /* webpackChunkName: 'institution.suppliers.index' */),
  ),
  meta: {
    header: {
      route: 'institution.suppliers.unverified',
      label: 'Furnizori neverificati',
    },
    breadcrumb: {
      route: 'institution.suppliers.unverified',
      label: 'Furnizori temporari',
    },
  },
};

export default route;
