import api from '@/api';
import { isPromise } from '@/lib/tools';
import { Polygon } from 'geojson';
import { ActionTree, Module, MutationTree } from 'vuex';
import { RootState } from '.';

interface InstitutionAssetsModule {
  id: ObjectId;
  label: string;
}

export interface Institution {
  _id: ObjectId;
  name: string;
  cui: number;
  assets: {
    modules: InstitutionAssetsModule[];
  };
  address: {
    street: string;
    number: string;
    countyId: ObjectId;
    localityId: ObjectId;
    bounds: Polygon;
  };
  limits: {
    general: {
      [k: string]: {
        current: number;
        maximum: number;
      };
    };
    applications: {
      [k: string]: {
        [k: string]: {
          current: number;
          maximum: number;
        };
      };
    };
  };
  applications: any[];
  tileProviders: {
    _id: string;
    active: boolean;
    authKey: string;
    title: string;
    workplace: string;
    layer: string;
    createdAt: null | Date;
    updatedAt: null | Date;
  }[];
  inventories: number;
}

export interface UserInstitutionState {
  promise: Promise<void> | null;
  institution: Institution | null;
  loading: boolean;
  error: Error | null;
}

const state: UserInstitutionState = {
  promise: null,
  institution: null,
  loading: false,
  error: null,
};

const mutations: MutationTree<UserInstitutionState> = {
  setPromise: (state, promise: Promise<void>) => {
    state.promise = promise;
  },
  startLoading: (state) => {
    state.loading = true;
    state.institution = null;
    state.error = null;
  },
  stopLoading: (state) => {
    state.loading = false;
  },
  setInstitution: (state, data) => {
    state.institution = data;
  },
  setError: (state, error) => {
    state.error = error;
  },
};

interface LoadOptions {
  force: boolean;
}

const actions: ActionTree<UserInstitutionState, RootState> = {
  load: ({ commit, state }, options?: LoadOptions) => {
    const { force } = {
      force: false,
      ...(options || {}),
    };

    if (!force && isPromise(state.promise)) {
      return state.promise;
    }

    commit('startLoading');
    const promise = api.institution
      .show()
      .then((response) =>
        response
          .json()
          .then((payload) =>
            response.ok ? commit('setInstitution', payload.data) : Promise.reject(new Error(payload.error)),
          ),
      )
      .catch((e) => {
        commit('setError', e);

        return Promise.reject(e);
      })
      .finally(() => commit('stopLoading'));

    commit('setPromise', promise);

    return promise;
  },
};

export const module: Module<UserInstitutionState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};
