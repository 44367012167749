import { UvRouteHeader } from '@/lib/router';

const header: UvRouteHeader = {
  label: 'Lista scriptic',
  route: 'inventory.history.scriptic',
  menu: (route) => [
    {
      label: 'Organigrama',
      icon: 'edit',
      route: {
        name: 'inventory.history.scriptic.org-chart',
        params: route.params,
      },
    },
    {
      label: 'Comisie',
      icon: 'people_alt',
      permissions: ['read.committee'],
      route: {
        name: 'inventory.history.scriptic.committee',
        params: route.params,
      },
    },
  ],
};

export default header;
