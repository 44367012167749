import { UvRoute } from '@/lib/router';
import imp from '@/lib/imp';

const routes: UvRoute = {
  path: ':fileId',
  props: true,
  component: imp(
    () => import('@/pages/urbanism/common/files/view/Main.vue' /* webpackChunkName: 'urbanism.files.view' */),
  ),
};

export default routes;
