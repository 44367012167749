import { PluginObject } from 'vue';

export const pageHeader: PluginObject<undefined> = {
  install(Vue) {
    Vue.prototype.$pageDeps = function (dep: string, value: unknown) {
      this.$store.commit('pageDeps/set', {
        dep,
        value,
      });
    };
  },
};
