import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import api from '@/api';

const route: UvRoute = {
  path: 'receptions',
  name: 'inventory.history.receptions',
  component: imp(
    () =>
      import(
        '@/pages/inventory/history/view/receptions/Main.vue' /* webpackChunkName: 'inventory.history.receptions' */
      ),
  ),
  props: true,
  meta: {
    header: {
      label: 'Lista receptii',
      route: 'inventory.history.receptions',
    },
    breadcrumb: {
      label: 'Lista receptii',
      route: 'inventory.history.receptions',
    },
  },
  children: [
    {
      path: ':itemId',
      name: 'inventory.history.receptions.show',
      component: imp(
        () =>
          import(
            '@/pages/inventory/history/view/common/ShowUpdate.vue' /* webpackChunkName: 'inventory.history.receptions' */
          ),
      ),
      props: (route) => ({
        ...route.params,
        parentRoute: 'inventory.history.receptions',
        loadApi: api.inventory.history.receptions.show,
      }),
    },
  ],
};

export default route;
