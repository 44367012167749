import { Tree } from '@/lib/trees';
import { Coordinate } from 'ol/coordinate';

export interface ScripticItemScanAllocation {
  quantityId: ObjectId;
  qty: number;
}

export enum ScripticItemScanCondition {
  GOOD = 'good',
  ACCEPTABLE = 'acceptable',
  FAULTY = 'faulty',
}

export const ScripticItemScanConditionLabel = {
  [ScripticItemScanCondition.GOOD]: 'Bun',
  [ScripticItemScanCondition.ACCEPTABLE]: 'Acceptabil',
  [ScripticItemScanCondition.FAULTY]: 'Nefunctional',
};

export interface ScripticItemScan {
  _id: ObjectId;
  scannedBy: ObjectId;
  scannedAt: Date;
  qty: number;
  allocations: ScripticItemScanAllocation[];
  nodeId: ObjectId;
  condition?: ScripticItemScanCondition;
  conditionDescription?: string;
  coordinates: Coordinate;
}

export interface ScripticItemQuantity {
  _id: ObjectId;
  name: string;
  qty: number;
  unitValue: number;
  location: string;
  description: string;
  attributes: {
    supplierName?: string;
    supplierCui?: string;
  };
}

export enum ScripticItemType {
  MF = 'mf',
  OI = 'oi',
}

export enum ScripticItemDomainType {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export const ScripticItemDomainTypeLabel = {
  [ScripticItemDomainType.PUBLIC]: 'Public',
  [ScripticItemDomainType.PRIVATE]: 'Privat',
};

export enum InventoryImportSource {
  INITIAL = 'initial',
  RECEPTION = 'reception',
  QUASH = 'quash',
}

export const ScripticItemTypeLabel: Record<ScripticItemType, string> = {
  [ScripticItemType.MF]: 'Mijloace fixe',
  [ScripticItemType.OI]: 'Obiecte de inventar',
};

export interface ScripticItem {
  _id: ObjectId;
  inventoryId: ObjectId;
  type: ScripticItemType;
  photos: any[];
  scan: ScripticItemScan[];
  itemCode: string;
  itemNo: string;
  suffix: number;
  qty: number;
  totalValue: number;
  name: string;
  description: string;
  measureUnit: string;
  quantities: ScripticItemQuantity[];
  address: string;
  account: string;
  classification: string;
  classificationName: string;
  importAsQty: boolean;
  createdAt: Date;
  factualQty: number;
  factualTotalValue: number;
  domainType: ScripticItemDomainType;
}

export interface ScripticItemUpdateQuantityDistribution {
  userId: ObjectId;
  scripticItemId: ObjectId;
  scripticItemQtyId: ObjectId;
  qty: number;
}

export interface ScripticItemUpdateQuantityImports {
  qty: number;
  date: Date;
}

export interface ScripticItemUpdateQuantity {
  name: string;
  qty: number;
  unitValue: number;
  importAsQty: boolean;
  location: string;
  attributes: Record<string, unknown>;
  src: Record<string, unknown>;
  imports: ScripticItemUpdateQuantityImports[];
  distributions: ScripticItemUpdateQuantityDistribution[];
}

export enum QuashDistributionStatus {
  UNDISTRIBUTED = 'undistributed',
  DISTRIBUTED = 'distributed',
  PARTIALLY = 'partially',
}

export interface ScripticItemUpdate {
  _id: ObjectId;
  inventoryId: ObjectId;
  userId: ObjectId;
  type: ScripticItemType;
  itemNo: string;
  suffix: string;
  measureUnit: string;
  source: InventoryImportSource;
  importAsQty: boolean;
  confirmed: boolean;
  applied: boolean;
  createdAt: Date;
  quantities: ScripticItemUpdateQuantity[];
  quashDistributionsStatus: QuashDistributionStatus;
  quashDistributions: ScripticItemUpdateQuantityDistribution[];
  itemCode: string;
  qty: number;
  totalValue: number;
}

export interface InventoryTypeConfig {
  enabled: boolean;
  import: {
    enabled: boolean;
    sources: {
      [source in InventoryImportSource]: {
        error?: string;
        repeatable: boolean;
        hasUnconfirmed: boolean;
        processing: boolean;
        finished: boolean;
        hasErrors: boolean;
      };
    };
  };
}

export interface InventoryConfig {
  types: {
    [type in ScripticItemType]: InventoryTypeConfig;
  };
}

export interface UvInventoryTransferErrors {
  transfer: {
    _id: string;
    message: string;
  }[];
}

export interface UvScripticItemsStats {
  scripticItems: {
    scanned: number;
    notScanned: number;
  };
}

export enum UvInventoryStatus {
  CREATED = 'created',
  READY = 'ready',
  TRANSFERRING = 'transferring',
  AUTO_CONFIRMING_UPDATES = 'auto_confirming_updates',
  CLOSED = 'closed',
  ERROR = 'error',
}

export interface Inventory {
  _id: ObjectId;
  institutionId: ObjectId;
  organizationalChartId: ObjectId;
  previousId?: ObjectId;
  name: string;
  year: number;
  status: UvInventoryStatus;
  startDate: Date;
  config: InventoryConfig;
  stoppedAt?: Date;
  createdAt: Date;
  institution: any;
  organizationalChart?: Tree;
  previous?: Inventory;
  errors: UvInventoryTransferErrors;
}
