import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import view from '@/pages/urbanism/regulation/view/subdivisions/view/routes';

const routes: UvRoute = {
  path: 'subdivisions',
  props: true,
  component: imp(
    () =>
      import(
        '@/pages/urbanism/regulation/view/subdivisions/Main.vue' /* webpackChunkName: 'urbanism.regulations.subdivisions' */
      ),
  ),
  meta: {
    header: {
      label: 'Subdiviziuni',
      parentRoute: 'urbanism.regulations.show',
      menu: () => [
        {
          label: 'Adauga subdiviziune',
          icon: 'add',
          separated: true,
          route: {
            name: 'urbanism.regulations.subdivisions.create',
          },
          permissions: ['update.regulations'],
        },
      ],
    },
    breadcrumb: {
      label: 'Subdiviziuni',
      route: 'urbanism.regulations.subdivisions.index',
    },
  },
  children: [
    {
      path: '',
      props: true,
      name: 'urbanism.regulations.subdivisions.index',
      component: imp(
        () =>
          import(
            '@/pages/urbanism/regulation/view/subdivisions/List.vue' /* webpackChunkName: 'urbanism.regulations.subdivisions' */
          ),
      ),
    },
    {
      path: 'create',
      props: true,
      name: 'urbanism.regulations.subdivisions.create',
      component: imp(
        () =>
          import(
            '@/pages/urbanism/regulation/view/subdivisions/actions/Create.vue' /* webpackChunkName: 'urbanism.regulations.subdivisions' */
          ),
      ),
      meta: {
        header: {
          label: 'Subdiviziune noua',
          parentRoute: 'urbanism.regulations.subdivisions.index',
        },
        breadcrumb: {
          label: 'Subdiviziune noua',
          route: false,
        },
      },
    },
    view,
  ],
};

export default routes;
