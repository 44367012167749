import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import en from 'vuetify/src/locale/en';
import ro from 'vuetify/src/locale/ro';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
  lang: {
    locales: {
      en,
      ro,
    },
    current: 'ro',
  },
});
