import imp from '@/lib/imp';
import { createInjectionComponent, UvRoute } from '@/lib/router';

const routes: UvRoute = {
  path: 'citizen-reports',
  props: true,
  component: createInjectionComponent(),
  meta: {
    header: {
      label: 'Sesizari',
      route: 'incidents.citizen-reports.index',
    },
    breadcrumb: {
      label: 'Sesizari',
      route: 'incidents.citizen-reports.index',
    },
  },
  children: [
    {
      path: '/',
      props: true,
      component: imp(
        () => import('@/pages/incidents/citizen-report/List.vue' /* webpackChunkName: 'incidents.citizen-reports' */),
      ),
      name: 'incidents.citizen-reports.index',
    },
  ],
};

export default routes;
