import { MutationPayload, Store } from 'vuex';
import { getProperty as dotGet, setProperty as dotSet, deleteProperty as dotDelete } from 'dot-prop';
import { identity } from 'rambda';
import { isObject, isString } from '@/lib/tools';
import { throttle } from '@martinstark/throttle-ts';

interface Path<T, U, V = null> {
  sync: boolean;
  def: V;
  get: (value: T | undefined) => U | V | undefined;
  set: (value: U) => T;
}

export interface SerializerOptions {
  prefetch?: boolean;
  storageKey?: string;
  throttle?: boolean;
}

export interface PathDefinition<T, U, V = null> extends Path<T, U, V> {
  path: string;
}

export type PathMapDefinition = (PathDefinition<any, any, any> | string)[];

const defaultStoreMap: Path<any, any> = {
  sync: false,
  def: null,
  get: (v: any) => (v === undefined ? null : v),
  set: identity,
};

export default (pathMapDef: PathMapDefinition, options: SerializerOptions = {}) => {
  const { prefetch = false, storageKey = 'vuex', throttle: throttleActive } = options;

  const paths = new Map<string, Path<any, any, any>>(
    pathMapDef.map((pathDef) => {
      if (isString(pathDef)) {
        return [pathDef, defaultStoreMap];
      } else {
        const { path, ...rest } = pathDef;
        return [path, rest];
      }
    }),
  );

  const mapDeserializedData = (data: Record<string, unknown>, sync: boolean) => {
    const state = {};

    for (const [path, { sync: _sync, get }] of paths) {
      if (sync && !_sync) {
        continue;
      }
      const value: any = data[path];
      if (value === undefined) {
        continue;
      }
      const pValue = get(value);
      if (pValue === undefined) {
        continue;
      }
      dotSet(state, path, pValue);
    }

    return state;
  };

  const merge = <State extends object, NewState extends object>(
    currentState: State,
    newState: NewState,
    unset = true,
  ): State => {
    // else take old state
    const merged: State = { ...currentState };
    // and replace only specified paths
    for (const [path] of paths.entries()) {
      const newValue = dotGet(newState, path);

      // remove value if it doesn't exist, overwrite otherwise
      if (newValue === undefined) {
        if (unset) {
          dotDelete(merged, path);
        }
      } else {
        dotSet(merged, path, newValue);
      }
    }

    return merged;
  };

  const parseState = (serialized: string, sync = false): Record<string, unknown> | void => {
    const data: Record<string, unknown> = JSON.parse(serialized);

    if (!isObject(data)) {
      return;
    }

    return mapDeserializedData(data, sync);
  };

  const getState = (): Record<string, unknown> | void => {
    const serialized = localStorage.getItem(storageKey);
    if (!isString(serialized)) {
      return;
    }

    try {
      return parseState(serialized);
    } catch (err) {
      // console.warn('failed to parse state.', err);
    }
  };

  const setState = (state: object) => {
    const carry: { [k: string]: any } = {};
    for (const [path, { def, set }] of paths) {
      const value = dotGet(state, path);
      if (def === value) {
        continue;
      }
      carry[path] = set(value);
    }

    localStorage.setItem(storageKey, JSON.stringify(carry));
  };

  const filter = (mutation: MutationPayload): boolean => {
    return ['submit', 'startLoading'].every((word) => !mutation.type.endsWith(word));
  };

  let initialState = prefetch ? getState() : undefined;

  return <State extends object>(store: Store<State>) => {
    initialState = !prefetch ? getState() : initialState;

    if (isObject(initialState)) {
      store.replaceState(merge(store.state, initialState, false));
    }

    const [_setState] = throttleActive ? throttle(setState, 100) : [setState, identity];

    window.addEventListener('storage', (event: StorageEvent) => {
      if (event.key !== storageKey || event.newValue === null) {
        return;
      }

      if (event.newValue !== event.oldValue) {
        try {
          const newState = parseState(event.newValue, true);
          if (!newState) {
            return;
          }

          store.replaceState(merge(store.state, newState));
        } catch (err) {
          console.warn('failed to parse state.', err);
        }
      }
    });

    store.subscribe((mutation: MutationPayload, state: State) => {
      if (filter(mutation)) {
        return _setState(state);
      }
    });
  };
};
