import routes from '@/api-routes';
import makeApi from '@/lib/api-builder';
import store from '@/store';

const api = makeApi(
  process.env.VUE_APP_INVENTORY_HOST || 'http://undefined',
  () => (store.state.auth.token === null ? {} : { Authorization: 'Bearer ' + store.state.auth.token }) as HeadersInit,
);

export default routes(api);
