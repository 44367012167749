export const map = {
  url: process.env.VUE_APP_TILES_URL,
  maxBounds: [
    [48.37, 19.183],
    [43.333, 30.156],
  ],
  center: [45.9432, 24.9668],
  minZoom: 6,
  tiles: {
    maxZoom: 25,
    maxNativeZoom: 18,
  },
  padding: [0.5, 0.5],
};

export const formats = {
  dateTime: 'dd.LL.yyyy, HH:mm',
  date: 'yyyy-LL-dd',
  dateAlt: 'dd.LL.yyyy',
  fullDateTime: 'dd.LL.yyyy, HH:mm:ss.SSSS',
};

export const bool = {
  yes: 'Da',
  no: 'Nu',
};
