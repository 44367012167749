import imp from '@/lib/imp';
import { UvRoute } from '@/lib/router';
import committee from '@/pages/inventory/active/committee/routes';
import _import from '@/pages/inventory/active/import/routes';
import orgChart from '@/pages/inventory/active/org-chart/routes';
import printTemplates from '@/pages/inventory/active/print-templates/routes';
import scan from '@/pages/inventory/active/scan/routes';
import header from '@/pages/inventory/active/scriptic/header';
import scriptic from '@/pages/inventory/active/scriptic/routes';

const edit = {
  path: 'edit',
  props: true,
  name: 'inventory.active.edit',
  permissions: ['update.inventories'],
  component: imp(() => import('@/pages/inventory/active/Edit.vue' /* webpackChunkName: 'inventory.active' */)),
  meta: {
    breadcrumb: {
      label: 'Modificare',
    },
  },
};

const route: UvRoute = {
  path: 'active',
  props: true,
  meta: {
    wide: true,
    breadcrumb: {
      route: 'inventory.active',
      label: 'Inventar activ',
    },
    permissions: ['read.inventories'],
  },
  component: imp(() => import('@/pages/inventory/active/Main.vue' /* webpackChunkName: 'inventory.active' */)),
  children: [
    {
      path: '',
      props: true,
      name: 'inventory.active',
      component: imp(
        () => import('@/pages/inventory/active/scriptic/list/Main.vue' /* webpackChunkName: 'inventory.active' */),
      ),
      meta: {
        header,
      },
      children: [scriptic, edit],
    },
    orgChart,
    ..._import,
    scan,
    committee,
    printTemplates,
  ],
};

export default route;
