import imp from '@/lib/imp';
import view from '@/pages/urbanism/subdivision-types/view/routes';
import { UvRoute } from '@/lib/router';

const routes: UvRoute = {
  path: 'subdivision-types',
  props: true,
  component: imp(
    () => import('@/pages/urbanism/subdivision-types/Main.vue' /* webpackChunkName: 'urbanism.subdivision-types' */),
  ),
  meta: {
    header: {
      label: 'Tipuri de subdiviziuni',
      route: 'urbanism.subdivision-types.index',
      menu: () => [
        {
          label: 'Adauga tip de subdiviziune',
          icon: 'add',
          separated: true,
          route: {
            name: 'urbanism.subdivision-types.create',
          },
          permissions: ['create.subdivision-types'],
        },
      ],
    },
    breadcrumb: {
      label: 'Tipuri de subdiviziuni',
      route: 'urbanism.subdivision-types.index',
    },
  },
  children: [
    {
      path: '/',
      props: true,
      component: imp(
        () =>
          import('@/pages/urbanism/subdivision-types/List.vue' /* webpackChunkName: 'urbanism.subdivision-types' */),
      ),
      name: 'urbanism.subdivision-types.index',
    },
    {
      path: 'create',
      props: true,
      component: imp(
        () =>
          import(
            '@/pages/urbanism/subdivision-types/actions/Create.vue' /* webpackChunkName: 'urbanism.subdivision-types' */
          ),
      ),
      name: 'urbanism.subdivision-types.create',
      meta: {
        header: {
          label: 'Tip de subdiviziune noua',
        },
        breadcrumb: {
          label: 'Tip de subdiviziune noua',
          route: false,
        },
      },
    },
    view,
  ],
};

export default routes;
